import { Button } from "@/components/_shadui/button";
import { ColorBadge } from "@/components/_shadui/color-badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/_shadui/tooltip";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  DocumentationCard,
  DocumentationCardButtonGroup,
  DocumentationCardContent,
  DocumentationCardContentBadge,
  DocumentationCardDescription,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemContent,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardMoreButton,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import {
  EditorItemDescription,
  HideEmptyListWhenNonEditor,
} from "@/components/module-api-editor";
import {
  type ActiveOperationItem,
  type SetActiveItem,
} from "@/components/module-api-editor/editor-preview-operation-active-item";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  AddLabelButton,
  BtnGroup,
  ComponentButton,
  DeleteButton,
  EditButton,
  LinkButton,
} from "@/components/module-visual-editor/shared-components";
import { TypeBadge } from "@/components/special-badges";
import { useComponents } from "@/hooks/use-components";
import { useResponses } from "@/hooks/use-responses";
import { HttpStatus, httpStatusMap } from "@/lib/helpers";
import {
  templateResponseCodes,
  TemplateResponseCodes,
} from "@/lib/oas-tools/https-verb-status-codes";
import { getDeepSchemaOrError } from "@/lib/oas-tools/oas-schema-utils";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASContentObject,
  OASResponse,
  OASSchema,
  SupportedHTTPVerbs,
} from "@/lib/types";
import {
  canEdit,
  DEFAULT_ICON_SIZE_SM,
  NormIcons,
  safeGet,
  typedIncludes,
} from "@/lib/utils";
import { inRange } from "lodash";
import { useMemo } from "react";

export function ResponsesCard({
  operationId,
  path,
  method,
  urlPath,
  onChange,
  value,
  setActiveItem,
  workspaceRole,
  activeItem,
}: {
  operationId: string;
  path: string;
  method: SupportedHTTPVerbs;
  urlPath: string;
  setActiveItem: SetActiveItem;
  workspaceRole: WorkspaceRole;
  activeItem: ActiveOperationItem | undefined;
} & EditorInputProps) {
  const { getResponses, removeResponse } = useResponses({ value, onChange });
  const { getComponentResponseOrError, addComponentResponseToOperation } =
    useComponents({ value, onChange });
  const { setActiveElement } = useAPIEditorTools({ value, onChange });

  const [, actionBarDispatch] = useActionBarContext();

  const responseList = useMemo(
    () => Object.entries(getResponses(path) || {}),
    [getResponses, path]
  );

  const onRemove = (responseCode: string) => {
    // IMPORTANT: Reset active item so path in `QuickEditor`
    // does not become invalid
    setActiveItem(undefined);
    removeResponse({ operationId, responseCode });
  };

  const handleEditClick = ({
    response,
    responseCode,
  }: {
    response: OASResponse;
    responseCode: string;
    responsePath: string;
  }) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "response-edit",
        onInteractOutside: (e) => {
          e.preventDefault();
        },
        context: {
          operationId,
          response,
          method,
          responseCode,
        },
      },
    });
  };

  const onAdd = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "response-add",
        context: { operationId, method },
      },
    });
  };

  const onSuggestionsClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "suggested-responses",
        context: {
          method,
          urlPath,
          operationId,
        },
      },
    });
  };

  const handleReferenceClick = (responseName: string) => {
    setActiveElement({ type: "response", responseName });
  };

  const handleMakeReference = (responseCode: HttpStatus) => {
    addComponentResponseToOperation({
      operationId,
      responseCode: responseCode as TemplateResponseCodes,
      skipCheckForExisting: true,
    });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <HideEmptyListWhenNonEditor
      workspaceRole={workspaceRole}
      list={responseList}
    >
      <DocumentationCard>
        <DocumentationCardHeader
          hoverLayout
          btnGroup={
            isEditor ? (
              <BtnGroup>
                <Button
                  role="button"
                  size="xs"
                  variant="secondary"
                  onClick={onSuggestionsClick}
                >
                  <NormIcons.Magic
                    className="mr-2"
                    size={DEFAULT_ICON_SIZE_SM}
                  />
                  Suggest
                </Button>
                <AddLabelButton onClick={onAdd} />
              </BtnGroup>
            ) : null
          }
        >
          <DocumentationCardTitle>Responses</DocumentationCardTitle>
        </DocumentationCardHeader>
        <DocumentationCardContent>
          {responseList.map((r, i) => {
            const [code, response] = r;

            const responseCode = code;
            const responsePath = `${path}.${code}`;

            let description: string;
            let schema: OASSchema;
            let contentObject: OASContentObject | undefined;
            const isRef = isReference(response);
            if (isRef) {
              const responseName = deref(response.$ref);
              const res = getComponentResponseOrError(responseName);

              schema = getDeepSchemaOrError(res.responseObject.content);
              description = res.responseObject.description;
              contentObject = res.responseObject.content;
            } else {
              schema = getDeepSchemaOrError(response.content);
              description = response.description;
              contentObject = response.content;
            }

            const onEdit = () =>
              handleEditClick({
                response,
                responsePath,
                responseCode,
              });

            const canReference = typedIncludes(
              templateResponseCodes,
              responseCode
            );

            const refName = isReference(response)
              ? deref(response.$ref)
              : undefined;

            const color = (() => {
              if (inRange(+code, 200, 299)) return "green";
              if (inRange(+code, 300, 399)) return "orange";
              if (inRange(+code, 400, 599)) return "red";
              return "blue";
            })();

            return (
              <DocumentationCardItem
                key={`response-${operationId}-${code}`}
                isSelected={
                  activeItem?.type === "response" &&
                  activeItem.responseCode === responseCode
                }
                onClick={() =>
                  setActiveItem({ type: "response", responseCode })
                }
                isLast={i === responseList.length - 1}
              >
                <DocumentationCardItemHeader>
                  <div className="flex gap-2">
                    <ColorBadge color={color}>{code}</ColorBadge>
                    <TypeBadge schema={schema} />
                    <DocumentationCardContentBadge
                      contentObject={contentObject}
                    />

                    {refName && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <LinkButton
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleReferenceClick(refName);
                            }}
                          />
                        </TooltipTrigger>
                        <TooltipContent>Go to &lt;{refName}&gt;</TooltipContent>
                      </Tooltip>
                    )}
                    <DocumentationCardItemTitle>
                      {safeGet(httpStatusMap, responseCode)?.message || ""}
                    </DocumentationCardItemTitle>
                  </div>
                  {isEditor && (
                    <BtnGroup>
                      {!refName && canReference && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <ComponentButton
                              onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                                handleMakeReference(responseCode);
                              }}
                            >
                              <span className="sr-only">
                                Turn into component
                              </span>
                            </ComponentButton>
                          </TooltipTrigger>
                          <TooltipContent>
                            Attach to existing component with the same name or
                            turn into new component.
                          </TooltipContent>
                        </Tooltip>
                      )}
                      <EditButton onClick={onEdit}>Edit response</EditButton>
                      <DeleteButton
                        onClick={(evt) => {
                          evt.stopPropagation();
                          onRemove(responseCode);
                        }}
                      >
                        Delete response
                      </DeleteButton>
                    </BtnGroup>
                  )}
                </DocumentationCardItemHeader>
                <DocumentationCardItemContent>
                  <DocumentationCardDescription>
                    <EditorItemDescription
                      item={{ description }}
                      workspaceRole={workspaceRole}
                      onAddDescriptionClick={onEdit}
                      isRef={isRef}
                    />
                  </DocumentationCardDescription>
                </DocumentationCardItemContent>
              </DocumentationCardItem>
            );
          })}
          {isEditor && responseList.length === 0 && (
            <DocumentationCardButtonGroup hoverLauout className="items-end">
              <DocumentationCardMoreButton
                className="mr-0"
                btnTitle="Add new"
                onClick={onAdd}
              />
            </DocumentationCardButtonGroup>
          )}
        </DocumentationCardContent>
      </DocumentationCard>
    </HideEmptyListWhenNonEditor>
  );
}
