import * as React from "react";

import { Button } from "@/components/_shadui/button";
import { ColorBadge } from "@/components/_shadui/color-badge";
import { H3 } from "@/components/headings";
import {
  convertToReadableContentFormat,
  getFirstConentTypeFromContentObject,
} from "@/lib/oas-tools/oas-schema-utils";
import { OASComponentsObject } from "@/lib/types";
import { cn } from "@/lib/utils";
import { ReactNode } from "@tanstack/react-router";

export const DocumentationCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-card-foreground shadow", className)}
    {...props}
  />
));
DocumentationCard.displayName = "Card";

export const DocumentationCardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    btnGroup?: ReactNode;
    hoverLayout?: boolean;
  }
>(({ className, children, hoverLayout, btnGroup, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "flex pb-2 justify-between items-end border-b",
        {
          "px-4": hoverLayout,
        },
        className
      )}
      {...props}
    >
      {children}
      {btnGroup}
    </div>
  );
});
DocumentationCardHeader.displayName = "CardHeader";

export const DocumentationCardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <H3
    ref={ref}
    className={cn("text-sm font-normal text-muted-foreground", className)}
    {...props}
  />
));
DocumentationCardTitle.displayName = "CardTitle";

export const DocumentationCardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      "text-sm text-muted-foreground max-w-screen-lg w-2/3] whitespace-pre-wrap",
      className
    )}
    {...props}
  />
));
DocumentationCardDescription.displayName = "CardDescription";

export const DocumentationCardContent = React.forwardRef<
  HTMLUListElement,
  React.HTMLAttributes<HTMLUListElement>
>(({ className, ...props }, ref) => (
  <ul ref={ref} className={cn("pt-0", className)} {...props} />
));
DocumentationCardContent.displayName = "CardContent";

export const DocumentationCardItem = React.forwardRef<
  HTMLLIElement,
  React.HTMLAttributes<HTMLLIElement> & {
    isLast?: boolean;
    isSelected?: boolean;
  }
>(({ children, className, isSelected, isLast = false, ...props }, ref) => {
  const canHover = props.onClick;
  return (
    <li
      ref={ref}
      data-islast={isLast}
      className={cn(
        "border-b data-[islast=true]:border-none py-0.5",
        className
      )}
      {...props}
    >
      <div
        aria-selected={isSelected}
        className={cn("py-4  rounded-md aria-selected:bg-accent mx-1", {
          "hover:bg-accent cursor-pointer transition-colors px-3": canHover,
        })}
      >
        {children}
      </div>
    </li>
  );
});

export const DocumentationCardItemHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("flex justify-between items-center", className)}
      {...props}
    />
  );
});

export const DocumentationCardItemTitle = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={cn("", className)} {...props}>
      <H3 className="text-primary">{children}</H3>
    </div>
  );
});

export const DocumentationCardMoreButton = React.forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement> & { btnTitle: string }
>(({ className, btnTitle, children: _childen, ...props }, ref) => {
  return (
    <li className="flex gap-2 pt-2">
      <Button
        role="button"
        size="xs"
        variant="secondary"
        ref={ref}
        className={cn("", className)}
        {...props}
      >
        {btnTitle}
      </Button>
    </li>
  );
});

export const DocumentationCardButtonGroup = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { hoverLauout?: boolean }
>(({ className, children, hoverLauout, ...rest }, ref) => {
  return (
    <div
      className={cn("flex gap-2 items-end", { "px-4": hoverLauout }, className)}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
});

export const DocumentationCardItemContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return <div ref={ref} className={cn("pt-1", className)} {...props} />;
});

export const DocumentationCardContentBadge = ({
  contentObject,
  ...rest
}: React.ComponentProps<typeof ColorBadge> & {
  contentObject: OASComponentsObject | undefined;
}) => {
  const contentType =
    contentObject && getFirstConentTypeFromContentObject(contentObject);
  return (
    <ColorBadge color="emerald" {...rest}>
      {contentType ? convertToReadableContentFormat(contentType) : "unknown"}
    </ColorBadge>
  );
};
