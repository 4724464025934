import { Button } from "@/components/_shadui/button";
import { ColorBadeColors, ColorBadge } from "@/components/_shadui/color-badge";
import { H1 } from "@/components/headings";
import { WorkspaceRole } from "@/components/module-api-editor/types";
import { OASSecurityScheme } from "@/lib/types";
import { canEdit, cn } from "@/lib/utils";
import { ComponentProps, PropsWithChildren, useMemo } from "react";

export function EditorItemDescription({
  item,
  workspaceRole,
  onAddDescriptionClick,
  isRef = false,
}: {
  item: {
    description?: string;
  };
  workspaceRole: WorkspaceRole;
  onAddDescriptionClick: () => unknown;
  isRef?: boolean;
}) {
  const isEditor = canEdit(workspaceRole);
  return (
    <>
      {item.description ? (
        item.description
      ) : isEditor && !isRef ? (
        <span>
          Add a description{" "}
          <Button variant="link" size="link" onClick={onAddDescriptionClick}>
            here
          </Button>
        </span>
      ) : (
        <span className="text-sm">No description yet.</span>
      )}
    </>
  );
}

export function HideEmptyListWhenNonEditor({
  children,
  workspaceRole,
  list,
}: PropsWithChildren<{ workspaceRole: WorkspaceRole; list: unknown[] }>) {
  const isEditor = canEdit(workspaceRole);
  if (isEditor || list.length > 0) return children;
  return null;
}

export function ColorFrame({
  children,
  color,
  className,
  ...rest
}: ComponentProps<"div"> & { color: ColorBadeColors }) {
  return (
    <div
      {...rest}
      className={cn(
        ` py-2 pl-1 pr-4 rounded-md cursor-pointer`,
        {
          "border-red-900 bg-red-900/40 hover:bg-red-900/60": color === "red",
          "border-pink-900 bg-pink-900/40 hover:bg-pink-900/60":
            color === "pink",
          "border-blue-900 bg-blue-900/40 hover:bg-blue-900/60":
            color === "blue",
          "border-green-900 bg-green-900/40 hover:bg-green-900/60":
            color === "green",
          "border-yellow-900 bg-yellow-900/40 hover:bg-yellow-900/60":
            color === "yellow",
          "border-cyan-900 bg-cyan-900/40 hover:bg-cyan-900/60":
            color === "cyan",
          "border-orange-900 bg-orange-900/40 hover:bg-orange-900/60":
            color === "orange",
          "border-purple-900 bg-purple-900/40 hover:bg-purple-900/60":
            color === "purple",
          "border-gray-900 bg-gray-900/40 hover:bg-gray-900/60":
            color === "gray",
          "border-violet-900 bg-violet-900/40 hover:bg-violet-900/60":
            color === "violet",
          "border-rose-900 bg-rose-900/40 hover:bg-rose-900/60":
            color === "rose",
          "border-teal-900 bg-teal-900/40 hover:bg-teal-900/60":
            color === "teal",
          "border-sky-900 bg-sky-900/40 hover:bg-sky-900/60": color === "sky",
          "border-indigo-900 bg-indigo-900/40 hover:bg-indigo-900/60":
            color === "indigo",
          "border-fuchsia-900 bg-fuchsia-900/40 hover:bg-fuchsia-900/60":
            color === "fuchsia",
          "border-amber-900 bg-amber-900/40 hover:bg-amber-900/60":
            color === "amber",
          "border-lime-900 bg-lime-900/40 hover:bg-lime-900/60":
            color === "lime",
        },
        className
      )}
    >
      {children}
    </div>
  );
}

export const SecuritySchemeBadge = ({
  schemeName,
  securityScheme,
  ...props
}: React.ComponentProps<typeof ColorBadge> & {
  securityScheme: OASSecurityScheme;
  schemeName: string;
}) => {
  const badgeValues = useMemo((): { name: string; color: ColorBadeColors } => {
    if (schemeName === "BearerJwt") {
      return { name: "JWT Auth", color: "violet" };
    }
    if (schemeName === "BearerCustom") {
      return { name: "Token auth", color: "emerald" };
    }
    if (schemeName === "BearerBasic") {
      return { name: "Basic auth", color: "green" };
    }
    if (securityScheme.type === "apiKey") {
      return { name: "API key auth", color: "amber" };
    }

    return { name: "Auth required", color: "lime" };
  }, [securityScheme, schemeName]);
  return (
    <ColorBadge color={badgeValues.color!} {...props}>
      {badgeValues.name}
    </ColorBadge>
  );
};

export function PreviewToolbarContainer({ children }: PropsWithChildren) {
  return (
    <div className="flex justify-between pb-1 grow-0 shrink-0 px-2 pt-1">
      {children}
    </div>
  );
}

export function PreviewContainer({ children }: PropsWithChildren) {
  return <div className="h-full w-full">{children}</div>;
}

export function PreviewHeading({
  children,
  preHeading,
}: PropsWithChildren<{ preHeading: string }>) {
  return (
    <div>
      <small className="text-muted-foreground">{preHeading}</small>
      <H1>{children}</H1>
    </div>
  );
}
