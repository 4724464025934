import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/_shadui/resizable";
import {
  ApiEditorErrorBoundary,
  ApiEditorErrorFallback,
} from "@/components/api-editor-error-boundary";
import {
  WorkspaceEditorContextState,
  WorkspaceEditorProvider,
} from "@/components/contexts/api-editor-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { PreviewProvider } from "@/components/contexts/operation-preview-context";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { NoOperationInWorkspace } from "@/components/module-preview-pane/operation-preview";
import { EditorTreeNavigation } from "@/components/module-visual-editor/editor-tree-navigation";
import { useOperation } from "@/hooks/use-operation";
import { ReactNode, useEffect, useMemo } from "react";
import { parse } from "yaml";
import { emptyWorkspaceExample } from "../../lib/oas-examples/empty-workspace";
import { ActionBarProvider } from "../contexts/action-bar-context";
import { ActionBar } from "../module-action-bar/action-bar";
import { Header } from "./header";
import { PreviewPanel } from "./preview-panel";
import { EditorTreeFooter } from "@/components/module-visual-editor/editor-tree-footer";
import { WorkspaceDocumentDraftDto } from "@/lib/main-rest-client/definitions";

// The app becomes unusable if state.editor.oas == null
// -> we reset to an empty workspace + update history
function useLastResort(props: EditorInputProps) {
  const { oas, setNewEditorState } = useAPIEditorTools(props);

  useEffect(() => {
    if (!oas) {
      setNewEditorState({
        data: parse(emptyWorkspaceExample),
        document_type: "oas_api_3_1",
      });
    }
  }, [oas, setNewEditorState]);
}

function APIEditorContent({
  value,
  onChange,
  organizationSlug,
  workspaceSlug,
  isAuthed,
  workspaceRole,
  workspaceAlert,
  draft,
  extraToolbarItems,
}: {
  organizationSlug?: string;
  workspaceSlug?: string;
  isAuthed: boolean;
  workspaceRole: WorkspaceRole;
  workspaceAlert?: ReactNode;
  draft?: WorkspaceDocumentDraftDto;
  extraToolbarItems?: ReactNode;
} & EditorInputProps) {
  const { getOperationsWithInfo } = useOperation({ value, onChange });
  useLastResort({ value, onChange });

  const allOperationsWithInfo = useMemo(
    () => getOperationsWithInfo(),
    [getOperationsWithInfo]
  );

  return (
    <div className="h-screen overflow-hidden">
      {!allOperationsWithInfo.length ? (
        <div className="h-full flex flex-col items-stretch">
          <Header
            value={value}
            onChange={onChange}
            workspaceSlug={workspaceSlug}
            hasOperations={!!allOperationsWithInfo.length}
            workspaceRole={workspaceRole}
          />
          <NoOperationInWorkspace
            value={value}
            onChange={onChange}
            workspaceRole={workspaceRole}
          />
        </div>
      ) : (
        <ResizablePanelGroup direction="horizontal" className="grow">
          <ResizablePanel defaultSize={20}>
            <div className="h-full flex flex-col items-stretch">
              <Header
                value={value}
                onChange={onChange}
                workspaceSlug={workspaceSlug}
                hasOperations={!!allOperationsWithInfo.length}
                workspaceRole={workspaceRole}
              />
              <EditorTreeNavigation
                value={value}
                onChange={onChange}
                workspaceRole={workspaceRole}
              />
              <EditorTreeFooter />
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel
            defaultSize={65}
            className="flex flex-col items-stretch"
          >
            {workspaceAlert}
            <PreviewPanel
              value={value}
              onChange={onChange}
              organizationSlug={organizationSlug}
              hasOperations={true}
              isAuthed={isAuthed}
              workspaceSlug={workspaceSlug}
              workspaceRole={workspaceRole}
              draft={draft}
              extraToolbarItems={extraToolbarItems}
            />
          </ResizablePanel>
        </ResizablePanelGroup>
      )}
      <ActionBar
        value={value}
        onChange={onChange}
        workspaceSlug={workspaceSlug}
        organizationSlug={organizationSlug}
      />
    </div>
  );
}

export function WorkspaceDocumentEditor({
  value,
  onChange,
  initialState,
  organizationSlug,
  workspaceSlug,
  isAuthed,
  workspaceRole,
  workspaceAlert,
  extraToolbarItems,
  draft,
}: {
  initialState?: Partial<WorkspaceEditorContextState>;
  organizationSlug?: string;
  workspaceSlug?: string;
  isAuthed: boolean;
  workspaceRole: WorkspaceRole;
  draft?: WorkspaceDocumentDraftDto;
  workspaceAlert?: ReactNode;
  extraToolbarItems?: ReactNode;
} & EditorInputProps) {
  return (
    <ActionBarProvider>
      <WorkspaceEditorProvider initialState={initialState}>
        <PreviewProvider>
          <ApiEditorErrorBoundary
            fallback={({ resolveError, error }) => (
              <ApiEditorErrorFallback
                resolveError={resolveError}
                error={error}
                value={value}
                onChange={onChange}
              />
            )}
          >
            <APIEditorContent
              value={value}
              onChange={onChange}
              organizationSlug={organizationSlug}
              workspaceSlug={workspaceSlug}
              isAuthed={isAuthed}
              workspaceRole={workspaceRole}
              workspaceAlert={workspaceAlert}
              draft={draft}
              extraToolbarItems={extraToolbarItems}
            />
          </ApiEditorErrorBoundary>
        </PreviewProvider>
      </WorkspaceEditorProvider>
    </ActionBarProvider>
  );
}
