import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { SchemaContentQuickEditor } from "@/components/schema-content-quick-editor";
import { SchemaContentQuickViewer } from "@/components/schema-content-quick-viewer";
import { SchemaEditor } from "@/components/schema-editor";
import {
  SchemaEditorPreviewContainer,
  SchemaQuickEditor,
} from "@/components/schema-quick-editor";
import { SchemaQuickViewer } from "@/components/schema-quick-viewer";
import { ParameterPositioinBadge } from "@/components/special-badges";
import { useOperation } from "@/hooks/use-operation";
import { useParameters } from "@/hooks/use-parameters";
import { useRequestBody } from "@/hooks/use-request-body";
import { useResponses } from "@/hooks/use-responses";
import { httpStatusMap, isSupportedHttpStatus } from "@/lib/helpers";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { OASParameterObject } from "@/lib/types";
import { useMemo } from "react";

export type ActiveOperationItem =
  | {
      type: "response";
      responseCode: string;
    }
  | {
      type: "parameter";
      idx: number;
    }
  | {
      type: "requestBody";
    }
  | { type: "auth-parameter"; authParameter: OASParameterObject };

export type SetActiveItem = React.Dispatch<
  React.SetStateAction<ActiveOperationItem | undefined>
>;

export function EditorPreviewOperationActiveItem({
  value,
  onChange,
  activeItem,
  operationId,
  workspaceRole,
  setActiveItem,
}: {
  activeItem: ActiveOperationItem;
  operationId: string;
  workspaceRole: WorkspaceRole;
  setActiveItem: SetActiveItem;
} & EditorInputProps) {
  const { getOperationWithInfo } = useOperation({ value, onChange });
  const { getResponseOrError } = useResponses({ value, onChange });
  const { getRequestBodyOrError } = useRequestBody({ value, onChange });
  const { getParameterByIndex } = useParameters({
    value,
    onChange,
  });

  const operationWithInfo = useMemo(
    () => getOperationWithInfo(operationId),
    [operationId, getOperationWithInfo]
  );

  const handleClose = () => {
    setActiveItem(undefined);
  };

  if (activeItem.type === "parameter") {
    const parameter = getParameterByIndex(operationId, activeItem.idx);
    if (!parameter) return null;

    if (isReference(parameter)) {
      const refName = deref(parameter.$ref);

      return (
        <SchemaQuickViewer
          path={`components.parameters.${refName}.schema`}
          value={value}
          onChange={onChange}
          title={`${refName}`}
          onClose={handleClose}
        />
      );
    }
    return (
      <SchemaQuickEditor
        title={`${parameter.name}`}
        path={`paths.${operationWithInfo.urlPath}.${operationWithInfo.method}.parameters[${activeItem.idx}].schema`}
        workspaceRole={workspaceRole}
        value={value}
        onChange={onChange}
        allowTopLevelReferences={true}
        onClose={handleClose}
      />
    );
  }

  if (activeItem.type === "requestBody") {
    const requestBody = getRequestBodyOrError({ operationId });
    if (isReference(requestBody)) {
      return (
        <SchemaContentQuickViewer
          title={`Request body schema`}
          path={`paths.${operationWithInfo.urlPath}.${operationWithInfo.method}.requestBody.content`}
          value={value}
          onChange={onChange}
          onClose={handleClose}
        />
      );
    }
    return (
      <SchemaContentQuickEditor
        title={`Request body schema`}
        path={`paths.${operationWithInfo.urlPath}.${operationWithInfo.method}.requestBody.content`}
        workspaceRole={workspaceRole}
        value={value}
        onChange={onChange}
        allowTopLevelReferences={true}
        onClose={handleClose}
      />
    );
  }

  if (activeItem.type === "response") {
    const response = getResponseOrError({
      operationId,
      responseCode: activeItem.responseCode,
    });

    if (isReference(response.response)) {
      const refName = deref(response.response.$ref);
      return (
        <SchemaContentQuickViewer
          path={`components.responses.${refName}.content`}
          value={value}
          onChange={onChange}
          title={`(Response) ${refName}`}
          onClose={handleClose}
        />
      );
    }
    return (
      <SchemaContentQuickEditor
        title={`${activeItem.responseCode} ${isSupportedHttpStatus(activeItem.responseCode) ? httpStatusMap[activeItem.responseCode].message : ""} - Schema`}
        path={`paths.${operationWithInfo.urlPath}.${operationWithInfo.method}.responses[${activeItem.responseCode}].content`}
        workspaceRole={workspaceRole}
        value={value}
        onChange={onChange}
        allowTopLevelReferences={true}
        onClose={handleClose}
      />
    );
  }

  if (activeItem.type === "auth-parameter") {
    const parameter = activeItem.authParameter;
    if (!parameter.schema || isReference(parameter.schema)) return null;
    return (
      <SchemaEditorPreviewContainer
        isLocked={true}
        onLockChange={undefined}
        title={
          <BtnGroup>
            <ParameterPositioinBadge parameterPosition={parameter.in} />
            <span>{parameter.name}</span>
          </BtnGroup>
        }
        onClose={handleClose}
      >
        <SchemaEditor
          value={parameter.schema}
          onChange={() => undefined}
          onRemoveRootSchema={() => undefined}
          componentsObject={{}}
          allowTopLevelReferences={false}
          isReadOnly={true}
        />
      </SchemaEditorPreviewContainer>
    );
  }

  const _type: never = activeItem;
  throw new Error(`Unknown active item type: ${_type}`);
}
