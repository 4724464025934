import { cn } from "@/lib/utils";
import { forwardRef, HTMLAttributes } from "react";

export const HeaderGrid = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { hoverLayout?: boolean }
>(({ children, className, hoverLayout, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "grid grid-cols-[min-content_1fr] gap-x-4 items-center auto-rows-fr",
        { "px-4": hoverLayout },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
});

export const HeaderGridTitle = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, className, ...rest }, ref) => {
  return (
    <div
      className={cn(
        "flex gap-0 items-center text-sm text-muted-foreground",
        className
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
});

export const HeaderGridValue = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...rest }, ref) => {
  return (
    <div ref={ref} className={cn("", className)} {...rest}>
      {children}
    </div>
  );
});
