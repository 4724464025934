import { HttpStatus } from "@/lib/helpers";
import { SupportedHTTPVerbs } from "@/lib/types";

export const templateResponseCodes = [
  "202",
  "204",
  "400",
  "401",
  "403",
  "404",
  "409",
  "422",
  "500",
] as const;
export type TemplateResponseCodes = (typeof templateResponseCodes)[number];

export type StatusCodeDescription<Codes extends string> = {
  code: Codes;
  description: string;
};

type VerbStatusCodes = Record<
  SupportedHTTPVerbs,
  {
    tamplates: StatusCodeDescription<HttpStatus>[];
    components: StatusCodeDescription<TemplateResponseCodes>[];
  }
>;

export const httpVerbStatusCodes: VerbStatusCodes = {
  get: {
    tamplates: [
      {
        code: "200",
        description: "OK - Successful request with response body",
      },
    ],
    components: [
      {
        code: "204",
        description: "No Content - Successful request with no response body",
      },
      { code: "400", description: "Bad Request - Invalid query parameters" },
      { code: "401", description: "Unauthorized - Authentication required" },
      {
        code: "403",
        description: "Forbidden - Authenticated but not authorized",
      },
      { code: "404", description: "Not Found - Resource doesn't exist" },
      {
        code: "500",
        description: "Internal Server Error - Unexpected server error",
      },
    ],
  },
  post: {
    tamplates: [
      { code: "201", description: "Created - Resource successfully created" },
    ],
    components: [
      {
        code: "202",
        description: "Accepted - Request accepted for processing",
      },
      { code: "400", description: "Bad Request - Invalid request body" },
      { code: "401", description: "Unauthorized - Authentication required" },
      {
        code: "403",
        description: "Forbidden - Authenticated but not authorized",
      },
      { code: "409", description: "Conflict - Resource already exists" },
      {
        code: "500",
        description: "Internal Server Error - Unexpected server error",
      },
    ],
  },
  put: {
    tamplates: [
      { code: "200", description: "OK - Resource successfully updated" },
    ],
    components: [
      {
        code: "204",
        description: "No Content - Successful update with no response body",
      },
      { code: "400", description: "Bad Request - Invalid request body" },
      { code: "401", description: "Unauthorized - Authentication required" },
      {
        code: "403",
        description: "Forbidden - Authenticated but not authorized",
      },
      { code: "404", description: "Not Found - Resource doesn't exist" },
      { code: "409", description: "Conflict - Version conflict" },
      { code: "422", description: "Unprocessable Entity - Validation errors" },
      {
        code: "500",
        description: "Internal Server Error - Unexpected server error",
      },
    ],
  },
  patch: {
    tamplates: [
      {
        code: "200",
        description: "OK - Resource successfully partially updated",
      },
    ],
    components: [
      {
        code: "204",
        description:
          "No Content - Successful partial update with no response body",
      },
      { code: "400", description: "Bad Request - Invalid request body" },
      { code: "401", description: "Unauthorized - Authentication required" },
      {
        code: "403",
        description: "Forbidden - Authenticated but not authorized",
      },
      { code: "404", description: "Not Found - Resource doesn't exist" },
      { code: "409", description: "Conflict - Version conflict" },
      { code: "422", description: "Unprocessable Entity - Validation errors" },
      {
        code: "500",
        description: "Internal Server Error - Unexpected server error",
      },
    ],
  },
  delete: {
    tamplates: [],
    components: [
      {
        code: "204",
        description: "No Content - Resource successfully deleted",
      },
      { code: "400", description: "Bad Request - Invalid request" },
      { code: "401", description: "Unauthorized - Authentication required" },
      {
        code: "403",
        description: "Forbidden - Authenticated but not authorized",
      },
      { code: "404", description: "Not Found - Resource doesn't exist" },
      { code: "409", description: "Conflict - Resource cannot be deleted" },
      {
        code: "500",
        description: "Internal Server Error - Unexpected server error",
      },
    ],
  },
  options: {
    tamplates: [
      {
        code: "200",
        description: "OK - Successful request with allowed methods",
      },
    ],
    components: [
      {
        code: "403",
        description: "Forbidden - CORS preflight request rejected",
      },
      {
        code: "500",
        description: "Internal Server Error - Unexpected server error",
      },
    ],
  },
  head: {
    tamplates: [{ code: "200", description: "OK - Successful request" }],
    components: [
      { code: "404", description: "Not Found - Resource doesn't exist" },
      {
        code: "500",
        description: "Internal Server Error - Unexpected server error",
      },
    ],
  },
  trace: {
    tamplates: [{ code: "200", description: "OK - Successful trace request" }],
    components: [
      { code: "400", description: "Bad Request - Invalid trace request" },
      { code: "401", description: "Unauthorized - Authentication required" },
      { code: "403", description: "Forbidden - TRACE method not allowed" },
      {
        code: "500",
        description: "Internal Server Error - Unexpected server error",
      },
    ],
  },
};
