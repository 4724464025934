import { Button } from "@/components/_shadui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/_shadui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_shadui/dropdown-menu";
import { EditorState } from "@/components/module-api-editor/types";
import { WorkspaceDocumentEditor } from "@/components/module-api-editor/workspace-document-editor";
import { SubmitButton } from "@/components/module-visual-editor/shared-components";
import { SetDraftNameDialog } from "@/components/set-draft-name-dialog";
import { SubmitDraftDialog } from "@/components/submit-draft-modal";
import { DraftWorkspaceAlert } from "@/components/workspace-alert";
import { useDisclosure } from "@/hooks/use-disclosure";
import { apiClient } from "@/lib/http-utils";
import { validateEditorStateBeforeUse } from "@/lib/oas-tools/oas-schema-utils";
import {
  canEdit,
  DEFAULT_ICON_SIZE,
  getIsAuthed,
  NormIcons,
} from "@/lib/utils";
import { useMe } from "@/queries/users";
import {
  documentDraftPullRequestOptions,
  documentDraftQueryOptions,
  useDeleteWorkspaceDocumentDraft,
  useDocumentDraftPullRequests,
  useUpdateWorkspaceDocumentDraft,
  useUserWorkspace,
  useWorkspaceDocumentDraft,
} from "@/queries/workspaces";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { zodSearchValidator } from "@tanstack/router-zod-adapter";
import { useCallback, useMemo, useState } from "react";
import { z } from "zod";

const draftEditorSearchSchema = z.object({
  set_name: z.boolean().optional(),
});

export const Route = createFileRoute(
  "/editor/$organizationSlug/$workspaceSlug/drafts/$draftId"
)({
  validateSearch: zodSearchValidator(draftEditorSearchSchema),
  loader: async ({
    params: { workspaceSlug, organizationSlug, draftId },
    context: { queryClient },
  }) => {
    const workspace = (
      await apiClient.findFullWorkspace({
        organizationSlug,
        workspaceSlug,
      })
    ).data;

    await queryClient.ensureQueryData(
      documentDraftPullRequestOptions({ workspaceDocumentDraftId: draftId })
    );

    await queryClient.ensureQueryData(
      documentDraftQueryOptions({ workspaceDocumentDraftId: draftId })
    );

    return { workspace };
  },
  component: WorkspaceEditor,
});

// Editing a document that is persisted in the database
// Editing this object will update the database record
export function WorkspaceEditor() {
  const { organizationSlug, workspaceSlug, draftId } = Route.useParams();
  const { set_name } = Route.useSearch();
  const { workspace: publicWorkspace } = Route.useLoaderData();
  const navigate = useNavigate();

  const draftQuery = useWorkspaceDocumentDraft({
    workspaceDocumentDraftId: draftId,
    refetchOnWindowFocus: true,
  });

  if (!draftQuery.data) throw new Error("Unable to get draft document");
  const draft = draftQuery.data;

  // TODO: If the user is from a different organization, this query should not
  // be executed since it will assume the organizaiton of the user who queries.
  const pullRequestQuery = useDocumentDraftPullRequests({
    workspaceDocumentDraftId: draftId,
  });
  if (!pullRequestQuery.data) throw new Error("Unable to get pull requests");
  const pullRequests = pullRequestQuery.data;

  const { mutate } = useUpdateWorkspaceDocumentDraft();

  const deleteWorkspaceDocumentDraftQuery = useDeleteWorkspaceDocumentDraft();

  const isAuthed = useMemo(() => getIsAuthed(), []);
  const userQuery = useMe({ enabled: isAuthed });

  const defaultPr = useMemo(
    () => pullRequests.find((e) => e.is_default),
    [pullRequests]
  );

  const userWorkspace = useUserWorkspace(
    { workspaceSlugOrId: workspaceSlug },
    { enabled: isAuthed }
  );

  const workspaceDocument =
    publicWorkspace.workspace_items[0].workspace_documents[0];

  const [value, setValue] = useState<EditorState>(() => {
    const editorState = validateEditorStateBeforeUse({
      document_type: workspaceDocument.document_type,
      data: draft.data,
    });
    return editorState;
  });

  const role = useMemo(() => {
    const role = userWorkspace.data?.role;
    if (role === "admin") return role;
    if (publicWorkspace.access_level === "public_write") return "editor";
    if (userWorkspace.data?.role) return userWorkspace.data.role;
    return "reader";
  }, [publicWorkspace.access_level, userWorkspace.data?.role]);

  const onChange = useCallback(
    (editorState: EditorState) => {
      setValue(editorState);
      if (!canEdit(role)) return;

      mutate({
        workspaceDocumentDraftId: draft.id,
        data: editorState.data,
      });
    },
    [draft.id, mutate, role]
  );

  const isOrgMember = useMemo(() => {
    if (
      userQuery.data &&
      publicWorkspace.organization_id === userQuery.data.organization_id
    )
      return true;
    return false;
  }, [userQuery.data, publicWorkspace.organization_id]);

  const handleDelete = () => {
    deleteWorkspaceDocumentDraftQuery.mutate(
      {
        workspaceDocumentDraftId: draft.id,
      },
      {
        onSuccess: () => {
          navigate({
            to: "/editor/$organizationSlug/$workspaceSlug",
            params: {
              workspaceSlug,
              organizationSlug,
            },
          });
        },
      }
    );
  };

  const submitDisclosure = useDisclosure();
  const changeNameDisclosure = useDisclosure({ defaultIsOpen: set_name });
  const deleteDisclosure = useDisclosure();

  return (
    <>
      <WorkspaceDocumentEditor
        value={value}
        onChange={onChange}
        organizationSlug={organizationSlug}
        workspaceSlug={workspaceSlug}
        draft={draft}
        isAuthed={isAuthed}
        workspaceRole={draft.status === "locked" ? "reader" : role}
        extraToolbarItems={
          isOrgMember && draft.status === "open" ? (
            <>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button size="sm" variant="ghost">
                    Manage draft
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <Link
                    to="/editor/$organizationSlug/$workspaceSlug"
                    params={{ workspaceSlug, organizationSlug }}
                  >
                    <DropdownMenuItem>
                      <NormIcons.Link
                        size={DEFAULT_ICON_SIZE}
                        className="mr-2 text-muted-foreground"
                      />
                      Visit main file
                    </DropdownMenuItem>
                  </Link>
                  {isOrgMember && (
                    <>
                      <DropdownMenuItem onClick={changeNameDisclosure.onOpen}>
                        <NormIcons.Edit
                          size={DEFAULT_ICON_SIZE}
                          className="mr-2 text-muted-foreground"
                        />
                        Rename
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={deleteDisclosure.onOpen}>
                        <NormIcons.Delete
                          size={DEFAULT_ICON_SIZE}
                          className="mr-2 text-destructive"
                        />
                        Delete
                      </DropdownMenuItem>
                    </>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              {isOrgMember && (
                <SubmitButton onClick={submitDisclosure.onOpen}>
                  Submit draft
                </SubmitButton>
              )}
              <SubmitDraftDialog
                draftId={draft.id}
                onOpenChange={submitDisclosure.onOpenChange}
                open={submitDisclosure.isOpen}
              />
            </>
          ) : undefined
        }
        workspaceAlert={
          isAuthed && (
            <DraftWorkspaceAlert
              workspaceDocument={workspaceDocument}
              defaultPr={defaultPr}
              draft={draft}
              organizationSlug={organizationSlug}
              workspaceSlug={workspaceSlug}
              isOrgMember={isOrgMember}
              isAuthed={isAuthed}
            />
          )
        }
      />

      <SetDraftNameDialog
        onOpenChange={changeNameDisclosure.onOpenChange}
        isOpen={changeNameDisclosure.isOpen}
        workspaceDocumentDraft={draft}
        workspaceSlug={workspaceSlug}
        organizationSlug={organizationSlug}
      />
      <Dialog
        open={deleteDisclosure.isOpen}
        onOpenChange={deleteDisclosure.onOpenChange}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently delete this
              draft and remove your data from our servers.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="secondary" onClick={deleteDisclosure.onClose}>
              Cancel
            </Button>
            <SubmitButton
              onClick={handleDelete}
              disabled={deleteWorkspaceDocumentDraftQuery.isPending}
            >
              Delete
            </SubmitButton>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
