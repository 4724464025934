import { ColorBadge } from "@/components/_shadui/color-badge";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  DocumentationCard,
  DocumentationCardContent,
  DocumentationCardDescription,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemContent,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import { HideEmptyListWhenNonEditor } from "@/components/module-api-editor";
import {
  ActiveOperationItem,
  SetActiveItem,
} from "@/components/module-api-editor/editor-preview-operation-active-item";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { ParameterPositioinBadge } from "@/components/special-badges";
import { useParameters } from "@/hooks/use-parameters";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import { OASParameterObject, ParameterPosition } from "@/lib/types";
import { canEdit } from "@/lib/utils";
import { useMemo } from "react";

function AuthParameterItem({
  parameter,
  parameterList,
  currentIndex,
  workspaceRole,
  value,
  onChange,
  setActiveItem,
}: {
  parameter: OASParameterObject;
  currentIndex: number;
  parameterList: OASParameterObject[];
  workspaceRole: WorkspaceRole;
  setActiveItem: SetActiveItem;
} & EditorInputProps) {
  const { setActiveElement } = useAPIEditorTools({ value, onChange });
  const handleEditClick = () => {
    setActiveElement({ type: "general_information" });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <DocumentationCardItem
      key={parameter.name}
      isLast={currentIndex === parameterList.length - 1}
      onClick={() =>
        setActiveItem({ type: "auth-parameter", authParameter: parameter })
      }
    >
      <DocumentationCardItemHeader>
        <div className="flex gap-2 items-center">
          <ColorBadge color="gray">Option {currentIndex + 1}</ColorBadge>
          <ColorBadge color="yellow">Authentication</ColorBadge>
          <ParameterPositioinBadge
            parameterPosition={parameter.in as ParameterPosition}
          />
          <DocumentationCardItemTitle>
            {parameter.name}
          </DocumentationCardItemTitle>
        </div>
        {isEditor && (
          <BtnGroup>
            <EditButton
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                handleEditClick();
              }}
            >
              Edit response
            </EditButton>
          </BtnGroup>
        )}
      </DocumentationCardItemHeader>
      <DocumentationCardItemContent>
        <DocumentationCardDescription>
          {parameter.description || ""}
        </DocumentationCardDescription>
      </DocumentationCardItemContent>
    </DocumentationCardItem>
  );
}

export function AuthParameterCard({
  value,
  onChange,
  operationWithInfo,
  workspaceRole,
  setActiveItem,
}: {
  operationWithInfo: OperationWithInfo;
  workspaceRole: WorkspaceRole;
  setActiveItem: SetActiveItem;
  activeItem: ActiveOperationItem | undefined;
} & EditorInputProps) {
  const operation = operationWithInfo.operation;
  const operationId = operation.operationId || "";

  const { findAuthenticationRequestParametersForOperation } = useParameters({
    value,
    onChange,
  });

  const authParameters = useMemo(() => {
    return findAuthenticationRequestParametersForOperation({ operationId });
  }, [findAuthenticationRequestParametersForOperation, operationId]);

  if (authParameters.length === 0) return null;

  return (
    <HideEmptyListWhenNonEditor
      list={authParameters}
      workspaceRole={workspaceRole}
    >
      <DocumentationCard>
        <DocumentationCardHeader hoverLayout>
          <DocumentationCardTitle>
            Authentication parameters
          </DocumentationCardTitle>
        </DocumentationCardHeader>
        <DocumentationCardContent>
          {authParameters.map((authParameter, i) => {
            return (
              <AuthParameterItem
                parameter={authParameter}
                currentIndex={i}
                parameterList={authParameters}
                value={value}
                onChange={onChange}
                workspaceRole={workspaceRole}
                setActiveItem={setActiveItem}
              />
            );
          })}
        </DocumentationCardContent>
      </DocumentationCard>
    </HideEmptyListWhenNonEditor>
  );
}
