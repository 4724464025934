import { EditorInputProps } from "@/components/module-api-editor/types";
import { SchemaContentQuickEditor } from "@/components/schema-content-quick-editor";

export function SchemaContentQuickViewer({
  path,
  title,
  value,
  onChange: _onChange,
  onClose,
}: {
  path: string;
  title: string;
  onClose?: () => unknown;
} & EditorInputProps) {
  return (
    <SchemaContentQuickEditor
      path={path}
      title={title}
      allowTopLevelReferences={false}
      value={value}
      onChange={() => undefined}
      workspaceRole="reader"
      onClose={onClose}
    />
  );
}
