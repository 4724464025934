import { ApiError } from "@/components";
import { Button } from "@/components/_shadui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/_shadui/card";
import { Skeleton } from "@/components/_shadui/skeleton";
import {
  RepositoryAdapterDto,
  WorksapceItemDto,
  WorkspaceDocumentDto,
  WorkspaceDto,
} from "@/lib/main-rest-client/definitions";
import { DEFAULT_ICON_SIZE, NormIcons, toastError } from "@/lib/utils";
import { useWorkspaceItems } from "@/queries/workspace-items";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/_shadui/alert-dialog";
import { apiClient } from "@/lib/http-utils";
import { useDisconnectWorkspaceFromAdapters } from "@/queries/repository-adapters";
import { appLimits } from "@/lib/const";
import {
  useRemoteResetWrorkspaceDocument,
  useWorkspaceDocuments,
} from "@/queries/workspaces";
import { toast } from "sonner";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";

function RemoteResetButton({ workspaceItemId }: { workspaceItemId: string }) {
  const documentsQuery = useWorkspaceDocuments({ workspaceItemId });

  const remoteResetQuery = useRemoteResetWrorkspaceDocument();

  const handleRemoteReset = (workspaceDocumentList: WorkspaceDocumentDto[]) => {
    if (workspaceDocumentList.length === 0) return;
    const document = workspaceDocumentList[0];

    remoteResetQuery.mutate(
      { workspaceDocumentId: document.id },
      {
        onSuccess: () => {
          toast.success("The document was reset");
        },
        onError: (error) => {
          toastError(error);
        },
      }
    );
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          variant="secondary"
          disabled={documentsQuery.isPending || remoteResetQuery.isPending}
        >
          <NormIcons.Reset size={DEFAULT_ICON_SIZE} className="mr-2" />
          Reset document state
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Reset document state</AlertDialogTitle>
          <AlertDialogDescription>
            This will retrieve the current document state from Github and
            overwrite this document with the most recent file state on Github.
            This can be useful if your document is in a bad state and you used
            code to fix it. This cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          {documentsQuery.data && (
            <AlertDialogAction
              onClick={() => handleRemoteReset(documentsQuery.data)}
              disabled={remoteResetQuery.isPending}
            >
              Reset & overwrite
            </AlertDialogAction>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function WorkspaceSettingsRepositoryAdapterCard({
  workspace,
  organizationRepositoryAdapters,
}: {
  workspace: WorkspaceDto;
  organizationRepositoryAdapters: RepositoryAdapterDto[];
}) {
  const {
    data: workspaceItems,
    status,
    error,
  } = useWorkspaceItems({ workspaceId: workspace.id });

  const disconnectQuery = useDisconnectWorkspaceFromAdapters();

  const handleRedirect = async (workspaceItemId: string) => {
    try {
      const dto = (await apiClient.githubAppAuthRedirect({ workspaceItemId }))
        .data;
      window.location.href = dto.redirect_url;
    } catch (err) {
      toastError(err);
    }
  };

  const hasReachedLimit =
    organizationRepositoryAdapters.length >=
    appLimits.maxNumberOfRepositoryAdapters;

  const handleDisconnect = (workspaceItem: WorksapceItemDto) => {
    disconnectQuery.mutate({ workspaceItemId: workspaceItem.id });
  };

  const renderContent = () => {
    switch (status) {
      case "error":
        return <ApiError error={error} />;
      case "pending":
        return (
          <div>
            <Skeleton className="h-4 w-full mb-2" />
            <Skeleton className="h-4 w-3/4" />
          </div>
        );
      case "success":
        const item = workspaceItems[0];
        return workspaceItems.length === 0 ? (
          <p>Unable to find workspace items</p>
        ) : (
          <>
            {item.is_connected_to_remote_repo ? (
              <BtnGroup>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button
                      variant="destructive"
                      disabled={disconnectQuery.isPending}
                    >
                      <NormIcons.Github
                        size={DEFAULT_ICON_SIZE}
                        className="mr-2"
                      />
                      Disconnect from Github
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                      <AlertDialogDescription>
                        This will disconnect from your repository and
                        permanently delete all drafts.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={() => handleDisconnect(item)}>
                        Disconnect
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
                <RemoteResetButton workspaceItemId={item.id} />
              </BtnGroup>
            ) : (
              <div>
                <div>
                  <Button
                    onClick={() => handleRedirect(item.id)}
                    disabled={hasReachedLimit}
                  >
                    <NormIcons.Github
                      size={DEFAULT_ICON_SIZE}
                      className="mr-2"
                    />
                    Connect to Github
                  </Button>
                </div>
                {hasReachedLimit && (
                  <small className="text-muted-foreground">
                    Upgrade your plan to connect more repositories
                  </small>
                )}
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Connect to GitHub</CardTitle>
        <CardDescription>
          Sync your project with a repository on Github.
        </CardDescription>
      </CardHeader>
      <CardContent>{renderContent()}</CardContent>
    </Card>
  );
}
