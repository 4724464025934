import { getComponentParameterByLabelFromDefinition } from "@/lib/editor-mutations/oas-component-parameters";
import { findOperationByIdOrErrorFromDefinition } from "@/lib/editor-mutations/oas-operations";
import {
  findOperationSecuritySchemeNamesInDefinition,
  getSecuitySchemeFromDefinition,
} from "@/lib/editor-mutations/oas-security-schemes";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { OASDefinition, OASParameter, OASParameterObject } from "@/lib/types";
import { getParametersFromUrlPath } from "@/lib/url-path";

export type Where = "path" | "query";

export function getParameterFromDefinitionOrError({
  operationId,
  parameterIdx,
  definition,
}: {
  operationId: string;
  parameterIdx: number;
  definition: OASDefinition;
}) {
  const operation = findOperationByIdOrErrorFromDefinition(
    definition,
    operationId
  );
  const found = operation.parameters?.[parameterIdx];
  if (!found) throw new Error("Parameter not found");
  return found;
}

export const updateParameterInDefinition = ({
  definition,
  parameterIdx,
  newParameter,
  operationId,
}: {
  definition: OASDefinition;
  parameterIdx: number;
  newParameter: OASParameterObject;
  operationId: string;
}): OASDefinition => {
  const cp = structuredClone(definition);
  const operation = findOperationByIdOrErrorFromDefinition(cp, operationId);

  const oldParameter = operation.parameters?.[parameterIdx];
  if (!oldParameter) throw new Error("Old parameter not found");

  operation.parameters![parameterIdx] = newParameter;

  return cp;
};

export const getParameterByIndexFromDefinition = ({
  definition,
  operationId,
  idx,
}: {
  definition: OASDefinition;
  operationId: string;
  idx: number;
}) => {
  const parameters =
    findOperationByIdOrErrorFromDefinition(definition, operationId)
      .parameters || [];

  const found = parameters[idx];
  if (!found) throw new Error("Parameter not found");

  return found;
};

export const removeParameterFromDefinition = ({
  definition,
  operationId,
  parameterIdx,
}: {
  definition: OASDefinition;
  operationId: string;
  parameterIdx: number;
}): OASDefinition => {
  const cp = structuredClone(definition);

  const operation = findOperationByIdOrErrorFromDefinition(cp, operationId);

  const existing = operation.parameters?.[parameterIdx];
  if (!existing) throw new Error("Parameter not found.");

  operation.parameters!.splice(parameterIdx, 1);

  // clean up empty array
  if (operation.parameters?.length === 0) {
    delete operation.parameters;
  }

  return cp;
};

export function resolveParameterFromDefinition({
  definition,
  parameter,
}: {
  definition: OASDefinition;
  parameter: OASParameter;
}) {
  if (!isReference(parameter)) return parameter;

  const found = definition.components?.parameters?.[deref(parameter.$ref)];
  if (!found) throw new Error("Reference not found");
  if (isReference(found))
    throw new Error("Component parameters cannot be references");

  return found;
}

export function addParameterToDefinition({
  definition,
  parameter,
  operationId,
  urlPath,
}: {
  definition: OASDefinition;
  parameter: OASParameter;
  operationId: string;
  urlPath: string;
}) {
  const cp = structuredClone(definition);

  const resolved = resolveParameterFromDefinition({ definition, parameter });

  const operation = findOperationByIdOrErrorFromDefinition(cp, operationId);

  if (!operation.parameters) operation.parameters = [];

  if (resolved.in === "path") {
    const parameters = getParametersFromUrlPath(urlPath);
    if (!parameters.some((p) => p.name === resolved.name)) {
      throw new Error(
        "Trying to add a path parameter that is not present in the urlPath."
      );
    }
  }

  if (
    operation.parameters.some((p) => {
      const resolvedParameter = resolveParameterFromDefinition({
        definition,
        parameter: p,
      });
      return (
        resolvedParameter.name === resolved.name &&
        resolvedParameter.in === resolved.in
      );
    })
  ) {
    throw new Error(
      "Conflict: Parameter with this name already exists for operation."
    );
  }

  operation.parameters.push(parameter);

  return cp;
}

export function dereferenceParameterInDefinition({
  definition,
  operationId,
  idx,
}: {
  definition: OASDefinition;
  operationId: string;
  idx: number;
}) {
  const cp = structuredClone(definition);
  const operation = findOperationByIdOrErrorFromDefinition(cp, operationId);
  const parameter = operation.parameters?.[idx] as OASParameter | undefined;
  if (!parameter) throw new Error("Parameter not found");
  if (!isReference(parameter)) throw new Error("Parameter is not a reference");

  const label = deref(parameter.$ref);
  const componentParameter = getComponentParameterByLabelFromDefinition({
    definition: cp,
    label,
  });

  if (!componentParameter) throw new Error("Unable to find reference");

  const componentParameterCp = structuredClone(componentParameter);
  operation.parameters![idx] = componentParameterCp;
  return cp;
}

export function findAuthenticationRequestParametersForOperationFromDefinition({
  definition,
  operationId,
}: {
  definition: OASDefinition;
  operationId: string;
}): OASParameterObject[] {
  const securitySchemeNames = findOperationSecuritySchemeNamesInDefinition({
    operationId,
    definition,
  });

  const res: OASParameterObject[] = [];

  securitySchemeNames.forEach((schemeName) => {
    const securityScheme = getSecuitySchemeFromDefinition({
      definition,
      schemeName,
    });
    if (securityScheme.type === "apiKey") {
      switch (securityScheme.in) {
        case "cookie":
          res.push({
            name: securityScheme.name,
            description: securityScheme.description,
            in: "cookie",
            required: true,
            style: "form",
            explode: true,
            allowReserved: false,
            schema: { type: "string" },
          });
          break;
        case "header":
          res.push({
            name: securityScheme.name,
            description: securityScheme.description,
            in: "header",
            required: true,
            style: "simple",
            explode: false,
            allowReserved: false,
            schema: { type: "string" },
          });
          break;
        case "query":
          res.push({
            name: securityScheme.name,
            description: securityScheme.description,
            in: "query",
            required: true,
            style: "form",
            explode: true,
            allowReserved: false,
            schema: { type: "string" },
          });
          break;
        default:
          const _invalid: never = securityScheme.in;
          throw new Error(
            `Invalid at 'securityScheme.in' of type: ${_invalid}`
          );
      }
    } else if (securityScheme.type === "http") {
      if (
        securityScheme.scheme.toLowerCase() === "bearer" &&
        securityScheme.bearerFormat?.toLowerCase()?.includes("jwt")
      ) {
        res.push({
          name: "Authorization",
          description:
            securityScheme.description ||
            "Jwt Auth: Authenticated requests contain a valid Json Web Token (JWT) as part of the `Authorization: Bearer <JWT>` header.",
          in: "header",
          required: true,
          style: "simple",
          explode: false,
          allowReserved: false,
          schema: { type: "string" },
        });
      } else if (securityScheme.scheme === "bearer") {
        res.push({
          name: "Authorization",
          description:
            securityScheme.description ||
            "Bearer Auth: Authenticated requests contain a valid `Authorization: Bearer <TOKEN>` header.",
          in: "header",
          required: true,
          style: "simple",
          explode: false,
          allowReserved: false,
          schema: { type: "string" },
        });
      } else if (securityScheme.scheme.toLowerCase() === "basic") {
        res.push({
          name: "Authorization",
          description:
            securityScheme.description ||
            "Basic Auth: Authenticated requests contain a valid `Authorization: Basic base64(username:password)` header",
          in: "header",
          required: true,
          style: "simple",
          explode: false,
          allowReserved: false,
          schema: { type: "string" },
        });
      } else {
        res.push({
          name: "Authorization",
          description:
            securityScheme.description ||
            "Authorization: Authenticated requests contain a valid `Authorization` header.",
          in: "header",
          required: true,
          style: "simple",
          explode: false,
          allowReserved: false,
          schema: { type: "string" },
        });
      }
    } else {
      res.push({
        name: "Custom Authorization",
        description:
          securityScheme.description ||
          "Authorization: Authenticated requests contain a valid 'Authorization's header.",
        in: "header",
        required: true,
        style: "simple",
        explode: false,
        allowReserved: false,
        schema: { type: "string" },
      });
    }
  });

  return res;
}
