import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/_shadui/accordion";
import { Button } from "@/components/_shadui/button";
import { ColorBadge } from "@/components/_shadui/color-badge";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { ActiveElement } from "@/components/contexts/api-editor-context";
import {
  DocumentationCard,
  DocumentationCardButtonGroup,
  DocumentationCardContent,
  DocumentationCardDescription,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemContent,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardMoreButton,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import {
  HeaderGrid,
  HeaderGridTitle,
  HeaderGridValue,
} from "@/components/header-grid";
import { H1 } from "@/components/headings";
import {
  EditorItemDescription,
  HideEmptyListWhenNonEditor,
  PreviewContainer,
  PreviewToolbarContainer,
} from "@/components/module-api-editor";
import { Toolbar } from "@/components/module-api-editor/toolbar";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaQuickEditor } from "@/components/schema-quick-editor";
import { ComponentSchemaWithInfo, useComponents } from "@/hooks/use-components";
import { DtoSchemaWithInfo, useSchemaDtos } from "@/hooks/use-dtos";
import { getSchemaTypeName } from "@/lib/oas-tools/oas-schema-utils";
import { typeColorMap } from "@/lib/oas-tools/style-helpers";
import { canEdit } from "@/lib/utils";
import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from "react";

export function EditorPreviewModel({
  activeElement,
  onChange,
  value,
  isAuthed,
  hasOperations,
  workspaceSlug,
  organizationSlug,
  workspaceRole,
  extraToolbarItems,
}: {
  activeElement: ActiveElement;
  isAuthed: boolean;
  hasOperations: boolean;
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode | undefined;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { getComponentSchemaOrError } = useComponents({ value, onChange });

  const [activeDto, setActiveDto] = useState("");

  const handleEditClick = (
    componentSchemaWithInfo: ComponentSchemaWithInfo
  ) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-schema",
        context: {
          schemaName: componentSchemaWithInfo.name,
          componentSchemaObject: componentSchemaWithInfo.schema,
        },
      },
    });
  };

  const handleAdd = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "dto-add",
        context: {
          baseSchemaName: componetSchema.name,
        },
      },
    });
  };

  if (activeElement.type !== "model")
    throw new Error("Active element needs to be model");

  const componetSchema = useMemo(() => {
    return getComponentSchemaOrError(activeElement.modelName);
  }, [activeElement.modelName, getComponentSchemaOrError]);

  const dtoSchemaList = componetSchema.dtoSchemas || [];

  const typeName = getSchemaTypeName(componetSchema.schema);
  const color = typeColorMap[typeName];
  if (!color) throw new Error("Can't find color for type");

  const isEditor = canEdit(workspaceRole);
  return (
    <PreviewContainer>
      <div className="h-full flex flex-col items-stretch">
        <PreviewToolbarContainer>
          <span />
          <Toolbar
            className=""
            hasOperations={hasOperations}
            isAuthed={isAuthed}
            onChange={onChange}
            value={value}
            workspaceSlug={workspaceSlug}
            organizationSlug={organizationSlug}
            workspaceRole={workspaceRole}
            extraToolbarItems={extraToolbarItems}
          />
        </PreviewToolbarContainer>

        {/* Scroll area */}
        <div className="grow overflow-y-auto ">
          <div className="flex pb-10">
            <div className="flex flex-col gap-10 grow">
              <div className="grid grid-cols-[max-content,1fr] justify-items-end items-center gap-x-2 px-4">
                <div>
                  <small className="text-muted-foreground">Model</small>
                  <H1 className="">{componetSchema.name}</H1>
                </div>
                {isEditor && (
                  <BtnGroup>
                    <EditButton
                      onClick={() => handleEditClick(componetSchema)}
                    />
                    <DeleteButton />
                  </BtnGroup>
                )}
              </div>
              <HeaderGrid hoverLayout className="items-start auto-rows-auto">
                <HeaderGridTitle className="pb-4">Type</HeaderGridTitle>
                <HeaderGridValue>
                  <ColorBadge color={color}>{typeName}</ColorBadge>{" "}
                </HeaderGridValue>
                <HeaderGridTitle>Schema</HeaderGridTitle>
                <HeaderGridValue>
                  <Accordion
                    type="single"
                    collapsible
                    className="max-w-screen-sm"
                  >
                    <AccordionItem value="item-1" className="border-0">
                      <AccordionTrigger className="text-sm pt-0 max-w-32">
                        <Button variant="secondary" size="xs" asChild>
                          <span>Toggle schema</span>
                        </Button>
                      </AccordionTrigger>
                      <AccordionContent>
                        <SchemaQuickEditor
                          value={value}
                          onChange={onChange}
                          title={`${componetSchema.name} schema`}
                          className="min-h-[200px] mb-norm"
                          path={`components.schemas.${componetSchema.name}`}
                          allowTopLevelReferences={false}
                          workspaceRole={workspaceRole}
                        />
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </HeaderGridValue>
              </HeaderGrid>

              <div>
                <HideEmptyListWhenNonEditor
                  workspaceRole={workspaceRole}
                  list={dtoSchemaList}
                >
                  <div className="grow flex flex-col gap-4 items-stretch">
                    <DocumentationCard className="">
                      <DocumentationCardHeader
                        btnGroup={
                          isEditor && (
                            <BtnGroup>
                              <DocumentationCardMoreButton
                                btnTitle="Add new"
                                onClick={handleAdd}
                              />
                            </BtnGroup>
                          )
                        }
                        hoverLayout
                      >
                        <DocumentationCardTitle>
                          Data transfer objects
                        </DocumentationCardTitle>
                      </DocumentationCardHeader>
                      <DocumentationCardContent>
                        {dtoSchemaList.map((dtoSchemaWithInfo) => {
                          return (
                            <DtoSchemaItem
                              value={value}
                              onChange={onChange}
                              key={dtoSchemaWithInfo.name}
                              dtoSchemaWithInfo={dtoSchemaWithInfo}
                              activeDto={activeDto}
                              setActiveDto={setActiveDto}
                              workspaceRole={workspaceRole}
                            />
                          );
                        })}
                        <DocumentationCardButtonGroup hoverLauout>
                          {isEditor && (
                            <DocumentationCardMoreButton
                              onClick={handleAdd}
                              btnTitle="Add new"
                            />
                          )}
                        </DocumentationCardButtonGroup>
                      </DocumentationCardContent>
                    </DocumentationCard>
                  </div>
                </HideEmptyListWhenNonEditor>
              </div>
            </div>
            {activeDto && (
              <div className="px-2">
                <DocumentationCard className="shrink min-w-[350px]">
                  <DocumentationCardContent className="flex flex-col items-stretch gap-norm">
                    <SchemaQuickEditor
                      value={value}
                      onChange={onChange}
                      workspaceRole={workspaceRole}
                      title={`${activeDto} schema`}
                      className="grow  rounded-md"
                      path={`components.schemas.${activeDto}`}
                      allowTopLevelReferences={false}
                      onClose={() => setActiveDto("")}
                    />
                  </DocumentationCardContent>
                </DocumentationCard>
              </div>
            )}
          </div>
        </div>
      </div>
    </PreviewContainer>
  );
}

function DtoSchemaItem({
  dtoSchemaWithInfo,
  value,
  onChange,
  activeDto,
  setActiveDto,
  workspaceRole,
}: {
  dtoSchemaWithInfo: DtoSchemaWithInfo;
  activeDto: string;
  setActiveDto: Dispatch<SetStateAction<string>>;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const { remove } = useSchemaDtos({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();

  const handleDeleteClick = (requestBodyName: string) => {
    setActiveDto("");
    remove(requestBodyName);
  };
  const handleSetActive = () => {
    setActiveDto(dtoSchemaWithInfo.name);
  };

  const handleEditClick = () => {
    // IMPORTANT If we do not reset activeDto before the modeal opens
    // the editor can crash. If name of dto is updated it gets out-of-sync
    // with activeDto state.
    setActiveDto("");
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "dto-edit",
        context: {
          dtoName: dtoSchemaWithInfo.name,
        },
      },
    });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <DocumentationCardItem
      isSelected={activeDto === dtoSchemaWithInfo.name}
      key={dtoSchemaWithInfo.name}
      onClick={handleSetActive}
    >
      <DocumentationCardItemHeader>
        <DocumentationCardItemTitle>
          {dtoSchemaWithInfo.name}
        </DocumentationCardItemTitle>
        {isEditor && (
          <BtnGroup>
            <EditButton
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick();
              }}
            />
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(dtoSchemaWithInfo.name);
              }}
            />
          </BtnGroup>
        )}
      </DocumentationCardItemHeader>
      <DocumentationCardItemContent>
        <DocumentationCardDescription>
          <EditorItemDescription
            item={dtoSchemaWithInfo.dtoSchema}
            onAddDescriptionClick={handleEditClick}
            workspaceRole={workspaceRole}
          />
        </DocumentationCardDescription>
      </DocumentationCardItemContent>
    </DocumentationCardItem>
  );
}
