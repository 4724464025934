import { useActionBarContext } from "@/components/contexts/action-bar-context";
import {
  DocumentationCard,
  DocumentationCardContent,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardMoreButton,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import {
  HideEmptyListWhenNonEditor,
  SecuritySchemeBadge,
} from "@/components/module-api-editor";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { useSecuritySchemes } from "@/hooks/use-security-schemes";
import { OASSecurityScheme } from "@/lib/types";
import { canEdit } from "@/lib/utils";
import { useMemo } from "react";

export function GeneralInformationSecuritySchemes({
  value,
  onChange,
  workspaceRole,
}: { workspaceRole: WorkspaceRole } & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { getSecuritySchemes, removeSecuritySchema } = useSecuritySchemes({
    value,
    onChange,
  });

  const securitySchemeList = useMemo(
    () => Object.entries(getSecuritySchemes()),
    [getSecuritySchemes]
  );

  const handleAdd = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-security-scheme",
      },
    });
  };

  const handleEdit = (
    schemeName: string,
    securityScheme: OASSecurityScheme
  ) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-security-scheme",
        context: {
          schemeName,
          securityScheme,
        },
      },
    });
  };

  const handleRemove = (schemeName: string) => {
    removeSecuritySchema({ schemeName });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <HideEmptyListWhenNonEditor
      workspaceRole={workspaceRole}
      list={securitySchemeList}
    >
      <DocumentationCard>
        <DocumentationCardHeader
          btnGroup={
            isEditor && (
              <BtnGroup>
                <DocumentationCardMoreButton
                  btnTitle="Add new"
                  onClick={handleAdd}
                />
              </BtnGroup>
            )
          }
        >
          <DocumentationCardTitle className="text-sm text-muted-foreground font-normal">
            Authentication schemes
          </DocumentationCardTitle>
        </DocumentationCardHeader>
        <DocumentationCardContent>
          {securitySchemeList.map(([name, securityScheme]) => {
            return (
              <DocumentationCardItem
                key={`security-scheme-${name}`}
                className="px-0"
              >
                <DocumentationCardItemHeader>
                  <div className="flex gap-2">
                    <SecuritySchemeBadge
                      schemeName={name}
                      securityScheme={securityScheme}
                    />
                    <DocumentationCardItemTitle>
                      {name}
                    </DocumentationCardItemTitle>
                  </div>
                  {isEditor && (
                    <BtnGroup>
                      <EditButton
                        onClick={(evt) => {
                          evt.stopPropagation();
                          handleEdit(name, securityScheme);
                        }}
                      >
                        Edit response
                      </EditButton>
                      <DeleteButton
                        onClick={(evt) => {
                          evt.stopPropagation();
                          handleRemove(name);
                        }}
                      >
                        Delete response
                      </DeleteButton>
                    </BtnGroup>
                  )}
                </DocumentationCardItemHeader>
              </DocumentationCardItem>
            );
          })}
          {isEditor && (
            <BtnGroup className="items-end">
              <DocumentationCardMoreButton
                className="mr-0"
                btnTitle="Add new"
                onClick={handleAdd}
              />
            </BtnGroup>
          )}
        </DocumentationCardContent>
      </DocumentationCard>
    </HideEmptyListWhenNonEditor>
  );
}
