import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import ImportOpenAPIFile from "@/components/import-open-api";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { validateEditorStateBeforeUse } from "@/lib/oas-tools/oas-schema-utils";
import { toastError } from "@/lib/utils";

export function ImportOpenAPIContent({ value, onChange }: EditorInputProps) {
  const { setNewEditorState } = useAPIEditorTools({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();
  const handleSuccess = (editorData: any) => {
    try {
      const editorState = validateEditorStateBeforeUse({
        data: editorData,
        document_type: "oas_api_3_1",
      });
      setNewEditorState(editorState);
      actionBarDispatch({ type: "CLOSE" });
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className="pt-4">
      <ImportOpenAPIFile onSuccessfulUpload={handleSuccess} />
    </div>
  );
}
