"use client";

import React, { useState, KeyboardEvent, forwardRef } from "react";
import { X } from "lucide-react";
import { useController, Control } from "react-hook-form";
import { Badge } from "@/components/_shadui/badge";
import { Button } from "@/components/_shadui/button";
import { Input } from "@/components/_shadui/input";

interface EnumInputProps {
  name: string;
  control: Control<any>;
  placeholder?: string;
}

export const EnumInput = forwardRef<HTMLInputElement, EnumInputProps>(
  (
    { name, control, placeholder = "Type and press space or enter..." },
    _ref
  ) => {
    const {
      field: { onChange, value },
    } = useController({
      name,
      control,
      defaultValue: [],
    });

    const [inputValue, setInputValue] = useState("");

    const isValidEnumValue = (value: string) =>
      /^[\w\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/.test(value);

    const addEnumValue = (newValue: string) => {
      if (newValue && isValidEnumValue(newValue) && !value.includes(newValue)) {
        onChange([...value, newValue]);
        setInputValue("");
      }
    };

    const removeEnumValue = (indexToRemove: number) => {
      onChange(
        value.filter((_: string, index: number) => index !== indexToRemove)
      );
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" || e.key === " ") {
        e.preventDefault();
        addEnumValue(inputValue.trim());
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    };

    const handleInputBlur = () => {
      if (inputValue.trim()) {
        addEnumValue(inputValue.trim());
      }
    };

    return (
      <div
        className="flex flex-wrap items-center gap-2 p-2 border border-input rounded-md focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2"
        ref={_ref}
      >
        {value.map((item: string, index: number) => (
          <Badge key={index} variant="secondary" className="h-7 px-2 text-sm">
            {item}
            <Button
              type="button"
              variant="ghost"
              size="sm"
              className="h-4 w-4 p-0 ml-2"
              onClick={() => removeEnumValue(index)}
            >
              <X className="h-3 w-3" />
            </Button>
          </Badge>
        ))}
        <Input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleInputBlur}
          className="flex-grow border-none shadow-none focus-visible:ring-0 focus-visible:ring-offset-0 h-7"
          placeholder={placeholder}
        />
      </div>
    );
  }
);
