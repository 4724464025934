import { EditorState } from "@/components/module-api-editor/types";
import { DtoSchemaWithInfo } from "@/hooks/use-dtos";
import {
  generateOperationIdForDefinition,
  generateResponseTitleForDefinition,
  getOperationsWithInfoFromDefinition,
} from "@/lib/editor-mutations/oas-operations";
import { addTagToDefinition } from "@/lib/editor-mutations/oas-tags";
import {
  deref,
  findComposedKey,
  isReference,
} from "@/lib/oas-tools/oas-tag-helpers";
import { OpenAPIV3_1 } from "@/lib/oas-tools/openapi-types";
import {
  OASArraySchemaObject,
  OASComponentsObject,
  OASContentObject,
  OASDefinition,
  OASNonArraySchemaObject,
  OASParameterObject,
  OASReferenceObject,
  OASResponse,
  OASSchema,
  OASSecurityRequirementDocument,
  OASSecurityScheme,
  SecuritySchemeEasierType,
  supportedContentFormats,
  SupportedContentFormats,
} from "@/lib/types";
import { generatePlaceholderId, isNanoid, typedIncludes } from "@/lib/utils";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import unset from "lodash/unset";
import { z } from "zod";

export const simplePropertyTypes = [
  "integer",
  "number",
  "string",
  "boolean",
  "null",
] as const;
export type SimplePropertyTypes = (typeof simplePropertyTypes)[number];

export const propertyTypes = [
  ...simplePropertyTypes,
  "array",
  "object",
] as const;
export type PropertyTypes = (typeof propertyTypes)[number];

export const composedPropertyTypes = ["allOf", "anyOf", "oneOf"] as const;
export type ComposedPropertyTypes = (typeof composedPropertyTypes)[number];

export const allPropertyTypes = [
  ...propertyTypes,
  ...composedPropertyTypes,
] as const;
export type AllPropertyTypes = (typeof allPropertyTypes)[number];

// in the preview window, this propertyTypes can be clicked on
// in will expand into a larger type property
export const expandablePropertyTypes = [
  "allOf",
  "anyOf",
  "oneOf",
  "array",
  "object",
  "mixed",
];

export function getComponentTypeFromString(componentString: string) {
  const match = componentString.match(/#\/components\/([a-z]+)\//);
  if (!match) throw new Error("Unable to resolve component string");

  if (!match[1])
    throw new Error("Unable to resolve capturing group in component string");

  switch (match[1]) {
    case "responses":
      return "response";
    case "schemas":
      return "schema";
    case "parameters":
      return "parameter";
    default:
      throw new Error("Component string is of unknown type");
  }
}

export type ComponentId = {
  type: "schema" | "dto";
  id: string;
};

export type ComponentIdWithPath = ComponentId & { path: string };

/**
 * Moves up one level in a Lodash-like object path.
 * Args:
 * path: The object path string (e.g., "a[0].b.c").
 *
 * Returns:
 * The path moved up one level (e.g., "a[0].b").
 */
export function moveUpLevel(path: string) {
  const match = path.match(/^(.*)(\.[\w\d_%&-]+|\[\d+\])$/);
  return match ? match[1] : "";
}

export function splitObjectPath(path: string) {
  return path.split(/(\.|)/);
}

/**
 * NOTE: This will NOT remove path from required field of partent object
 */
export function removePathFromSchema(schema: OASSchema, path: string) {
  const cp = cloneDeep(schema);
  const parentPath = moveUpLevel(path);
  const parentProperty = get(cp, parentPath);
  if (Array.isArray(parentProperty)) {
    const match = path.match(/.*\[(\d+)\]$/);

    if (match == null || !match[1])
      throw new Error("Could not find index to splice");
    parentProperty.splice(Number(match[1]), 1);
    set(cp, parentPath, parentProperty);
  } else {
    unset(cp, path);
  }
  return cp;
}

/**
 * Get property for given path
 */
export function getPathProperty(
  schema: OASSchema,
  path: string
): OASSchema | OASReferenceObject {
  if (path == "") return schema;
  const currentProperty = get(schema, path);
  if (!currentProperty) throw new Error("No current property");
  return currentProperty;
}

const metaProperties = ["properties", "items"];

function getLastPathSegment(path: string) {
  const split = path.split(".");
  return split[split.length - 1] || "";
}

function findNearestObject(
  schema: OASSchema,
  path: string
): OASNonArraySchemaObject | undefined {
  let currentPath = path;
  while (currentPath) {
    currentPath = moveUpLevel(currentPath);
    if (metaProperties.includes(getLastPathSegment(path))) {
      continue;
    }
    const currentProperty = getPathProperty(schema, currentPath);
    if ("properties" in currentProperty && currentProperty.type === "object") {
      return currentProperty;
    }
  }
}

function doesPathEndOnPropertyName(path: string) {
  // only properties that have a 'propertyName' at the end can be included
  // in the required array.
  // Examples:
  // a.b[0] ❌
  // a.b ✅
  return /^.*\.\w+$/.test(path);
}

/**
 * Traverse schema tree up until I find an object with a required proeprty
 */
export function removeRequiredForProperty(schema: OASSchema, path: string) {
  const cp = cloneDeep(schema);
  if (doesPathEndOnPropertyName(path) === false) return cp;
  const nearestObject = findNearestObject(cp, path);
  if (!nearestObject) return cp;
  if (!Array.isArray(nearestObject.required)) return cp;
  const split = path.split(".");
  nearestObject.required = nearestObject.required.filter(
    (propertyName: string) => propertyName !== split[split.length - 1]
  );
  return cp;
}

export function addRequiredToProperty(rootSchema: OASSchema, path: string) {
  const cp = structuredClone(rootSchema);
  if (doesPathEndOnPropertyName(path) === false) return cp;
  const nearestObject = findNearestObject(cp, path);
  if (!nearestObject) return cp;
  if (!Array.isArray(nearestObject.required)) nearestObject.required = [];
  const split = path.split(".");
  nearestObject.required = [...nearestObject.required, split[split.length - 1]];
  return cp;
}

export function changePropertyMetaData({
  rootSchema,
  schema,
  path,
  propertyMetaData,
}: {
  rootSchema: OASSchema;
  schema: OASSchema;
  path: string;
  propertyMetaData: {
    // all values will be replaces
    description: string | undefined;
    default: string | undefined;
    enum: string[] | undefined;
  };
}) {
  const cp = structuredClone(schema);
  cp.description = propertyMetaData.description;
  cp.default = propertyMetaData.default;

  if (
    propertyMetaData.default != null &&
    !propertyMetaData.enum?.includes(propertyMetaData.default)
  ) {
    throw new Error("Default must be included in enum values");
  }
  cp.enum = propertyMetaData.enum;

  const newRootSchema = insertAtPath(
    rootSchema,
    path,
    JSON.parse(JSON.stringify(cp)) // remove undefined properties from object
  );

  return newRootSchema;
}

export function insertAtPath<T extends object = OASSchema>(
  schema: T,
  path: string,
  newSchema: OASSchema | OASReferenceObject
): T {
  if (path === "") return cloneDeep(newSchema as T);
  const cp = cloneDeep(schema);
  set(cp, path, newSchema);
  return cp;
}

export function getPristineContentObject(): OASContentObject {
  return {
    "application/json": {
      schema: getPristineType("object"),
    },
  };
}

export function getPristineType(
  type: OASSchema["type"] | ComposedPropertyTypes
): OASSchema {
  switch (type) {
    case "boolean":
    case "integer":
    case "number":
    case "string":
      return { type: type };
    case "array":
      return {
        type: "array",
        items: {
          type: "string",
        },
      };
    case "object":
      return {
        type: "object",
      };
    case "null":
      return {
        type: "null",
      };
    case "oneOf":
      return {
        oneOf: [{ type: "object", properties: {} }],
      };
    case "anyOf":
      return {
        anyOf: [{ type: "object", properties: {} }],
      };
    case "allOf":
      return {
        allOf: [{ type: "object", properties: {} }],
      };
    default:
      throw new Error("Trying to change to unknown type");
  }
}

function getComposedPristineSchema(
  type: string | undefined,
  composedType: ComposedPropertyTypes
): OASSchema {
  switch (type) {
    case "array":
      return {
        type: "array",
        items: {
          [composedType]: [{ type: "object", properties: {} }],
        },
      };
    /**
     * INFO: Not all composed schemas have types. Example:
     * type: object
     * properties:
     *  name:
     *    oneOf:
     *      - type: string
     *      - type: object
     *
     * Return default placeholder in these cases
     */
    default:
      return {
        [composedType]: [{ type: "object", properties: {} }],
      };
  }
}

function isReferenceObject(
  schema: OASSchema | OASReferenceObject
): schema is OASReferenceObject {
  if ("$ref" in schema) return true;
  return false;
}

function changeComposedType(
  schema: OASSchema,
  path: string,
  newType: ComposedPropertyTypes
) {
  const property = getPathProperty(schema, path);
  if (isReferenceObject(property) || Array.isArray(property.type)) {
    throw new Error("Trying to convert invalid type to composed type");
  }
  const placeholderSchema = getComposedPristineSchema(property.type, newType);
  if (!placeholderSchema)
    throw new Error("No placeholder schema for composed type found");
  return insertAtPath(schema, path, placeholderSchema);
}

export function changeType(
  schema: OASSchema,
  path: string,
  newType: AllPropertyTypes
) {
  const placeholderSchema = getPristineType(newType);
  if (path === "") return placeholderSchema;
  if (typedIncludes(composedPropertyTypes, newType)) {
    return changeComposedType(schema, path, newType as ComposedPropertyTypes);
  }
  return insertAtPath(schema, path, placeholderSchema);
}

export function changeTypeToModel(
  schema: OASSchema,
  path: string,
  componentId: ComponentId
) {
  return insertAtPath(schema, path, {
    $ref: `#/components/schemas/${componentId.id}`,
  });
}

export function getFirstSchemaFromContentObject(obj: OASContentObject) {
  const keys = Object.keys(obj);
  if (!keys.length) return undefined;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (typedIncludes(supportedContentFormats, key) && obj[key].schema)
      return obj[key].schema;
  }
}

export function getFirstConentTypeFromContentObject(
  obj: OASContentObject
): SupportedContentFormats | undefined {
  const keys = Object.keys(obj);
  if (!keys.length) return undefined;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (typedIncludes(supportedContentFormats, key)) return key;
  }
}

export function getDeepSchemaOrError(obj: OASContentObject | undefined) {
  if (!obj) return getPristineType("null");
  const schema = getFirstSchemaFromContentObject(obj);
  if (!schema) return getPristineType("null");
  return schema;
}

function isDirectAncestorOfComposedProperty(
  rootSchema: OASSchema,
  path: string
) {
  const parentPath = moveUpLevel(path);
  const parentProperty = get(rootSchema, parentPath);
  return Array.isArray(parentProperty);
}

function getAllDtosFromComponentsObject(
  componentsObject: OASComponentsObject
): DtoSchemaWithInfo[] {
  return Object.entries(componentsObject.schemas || {})
    .filter(([, schema]) => {
      return schema["x-fiddle-dto-info"];
    })
    .map(([name, schema]) => ({ dtoSchema: schema, name }));
}

type SchemaWithInfo = {
  schema: OASSchema;
  name: string;
};

function getAllSchemasFromComponentsObject(
  componentsObject: OASComponentsObject
): SchemaWithInfo[] {
  return Object.entries(componentsObject.schemas || {})
    .filter(([, schema]) => {
      return !schema["x-fiddle-dto-info"];
    })
    .map(([name, schema]) => ({ schema: schema, name }));
}

type BaseTypeOptions = {
  propertyTypes: readonly AllPropertyTypes[];
  schemas: readonly SchemaWithInfo[];
  dtos: readonly DtoSchemaWithInfo[];
};

export type TypeOptions = ReturnType<typeof getTypeOptions>;
/**
 * Rules for available types.
 *
 * TOP LEVEL REFERENCES
 * dtos + schemas: Schemas and DTOs cannot have top-level references
 *
 * DIRECT ANCESTOR OF COMPOSED TYPE
 * schemaTypes: cannot be composes types as well: allof.allof is forbidden
 */
export function getTypeOptions({
  componentsObject,
  schema,
  rootSchema,
  path,
  allowTopLevelReferences,
}: {
  rootSchema: OASSchema;
  schema: OASSchema;
  componentsObject: OASComponentsObject;
  path: string;
  allowTopLevelReferences: boolean;
}): BaseTypeOptions & { innerArrayTypeOptions: BaseTypeOptions | undefined } {
  const allDtos = getAllDtosFromComponentsObject(componentsObject);
  const allSchemas = getAllSchemasFromComponentsObject(componentsObject);

  let dtos: Array<DtoSchemaWithInfo> = [...allDtos];
  let schemas: Array<SchemaWithInfo> = [...allSchemas];
  let propertyTypes: Array<AllPropertyTypes> = [...allPropertyTypes];

  // flags for modification
  const isComposed = isDirectAncestorOfComposedProperty(rootSchema, path);
  const isArraySchema = schema.type === "array";

  // filter
  if (isComposed) {
    propertyTypes = [...propertyTypes];
  }
  if (!allowTopLevelReferences && path === "") {
    dtos = [];
    schemas = [];
  }

  const innerArrayTypeOptions = isArraySchema
    ? {
        dtos: allDtos,
        schemas: allSchemas,
        propertyTypes: [...allPropertyTypes],
      }
    : undefined;
  return {
    propertyTypes,
    dtos,
    schemas,
    innerArrayTypeOptions,
  };
}

export function isNonArraySchemaObject(
  schema: OASSchema
): schema is OASNonArraySchemaObject {
  if (
    schema.type === "array" ||
    Array.isArray(schema.type) ||
    isReferenceObject(schema)
  )
    return false;
  return true;
}

export function addPlaceholderToPath(
  rootSchema: OASSchema,
  path: string
): [schema: OASSchema, placeholderId: string | undefined] {
  const property = getPathProperty(rootSchema, path);
  const cp = cloneDeep(property);

  if (Array.isArray(cp)) throw new Error("Can't add placeholder to array");
  if ("$ref" in cp) throw new Error("Can't add placeholder to ref schema");

  if ((cp.oneOf || cp.allOf || cp.anyOf) && isNonArraySchemaObject(cp)) {
    const composedKey = findComposedKey(cp);
    cp[composedKey]?.push({
      type: "object",
      properties: {},
    });
    const newSchema = insertAtPath(rootSchema, path, cp);
    return [newSchema, undefined];
  }
  if ("type" in cp && cp.type === "object") {
    const placeholderId = generatePlaceholderId();
    if (!cp.properties) cp.properties = {};
    const newSchema = insertAtPath(
      rootSchema,
      `${path}${path === "" ? "properties" : ".properties"}`,
      {
        ...cp.properties,
        [placeholderId]: { type: "string" },
      }
    );
    return [newSchema, placeholderId];
  }
  throw new Error("Can't add placeholder to invalid type");
}

export function changePropertyName(
  schema: OASSchema,
  path: string,
  oldPropertyName: string,
  newPropertyName: string
) {
  const parentPath = moveUpLevel(path);
  const parentProperty = getPathProperty(schema, parentPath);
  // Here, we ensure that we can only change the property names of an object based schema
  // TODO: Alternatively we can go up one level and check if the grandParentPath ends with "properties"
  if (
    Array.isArray(parentProperty) ||
    ("type" in parentProperty && typeof parentProperty["type"] === "string")
  ) {
    throw new Error("Invalid type to change property name");
  }
  const cp = cloneDeep(
    parentProperty
  ) as OpenAPIV3_1.BaseSchemaObject["properties"];
  if (typeof cp !== "object")
    throw new Error("Parent property is not an object");
  if (!cp[oldPropertyName]) throw new Error("oldProperty name not found");
  cp[newPropertyName] = cp[oldPropertyName];
  if (newPropertyName !== oldPropertyName) {
    delete cp[oldPropertyName];
  }
  return insertAtPath(schema, parentPath, cp);
}

/**
 * This is a precaution. A lot of the editor depends on operationIds being present.
 * So far, no state was introduced where the operationId was missing but this can
 * prevent future errors.
 */
function healMissingOperationId(editorState: EditorState): EditorState {
  const operationsWithInfo = getOperationsWithInfoFromDefinition(
    editorState.data
  );
  operationsWithInfo.forEach((operationWithInfo) => {
    const { operation } = operationWithInfo;
    if (!operation.operationId)
      operation.operationId = generateOperationIdForDefinition(
        operationWithInfo,
        editorState.data
      );
  });
  return editorState;
}

/**
 * 1 Heal objects that have both .items and .properties
 * 1.1. If type "array" remove .properties
 * 1.2 If type "object" remove .items
 * 2 Heal arrays that dont have array.items defined
 */
export function healArraysWithProperties(editorState: EditorState) {
  const oas = editorState.data;

  function traverse(oas: any) {
    if (typeof oas !== "object" || oas === null) {
      return;
    }
    if (Array.isArray(oas)) {
      oas.forEach((e) => {
        traverse(e);
      });
      return;
    }
    if (
      typeof oas === "object" &&
      oas !== null &&
      "type" in oas &&
      oas.type === "array"
    ) {
      if ("properties" in oas) {
        delete oas.properties;
      }
      if (!("items" in oas)) {
        (oas as any).items = {
          type: "object",
        };
      }
    } else if (
      typeof oas === "object" &&
      oas !== null &&
      "type" in oas &&
      oas.type === "object" &&
      "items" in oas
    ) {
      delete oas.items;
    }
    for (const [, value] of Object.entries(oas)) {
      traverse(value);
    }
  }
  traverse(oas);
  return editorState;
}

export function healNonSensicalOperationIds(editorState: EditorState) {
  const definition = editorState.data;
  const operations = getOperationsWithInfoFromDefinition(definition);
  operations.forEach((op) => {
    if (!op.operation.operationId) {
      op.operation.operationId = generateOperationIdForDefinition(
        op,
        definition
      );
    } else {
      const operationId = op.operation.operationId;
      if (isNanoid(operationId) || /[0-9]/.test(operationId[0])) {
        op.operation.operationId = generateOperationIdForDefinition(
          op,
          definition
        );
      }
    }
  });
  return editorState;
}

/**
 * It's possible that operations use tags that are not present in the top-level
 * tag array. This function synchonizes that top-level tag array with the tags
 * used in operations.
 */
export function healMissingTags(editorState: EditorState): EditorState {
  let definition = editorState.data;
  const operations = getOperationsWithInfoFromDefinition(definition);

  const usedTags: string[] = [];

  operations.forEach((op) => {
    if (op.operation.tags?.length) {
      op.operation.tags.forEach((tag) => usedTags.push(tag));
    }
  });

  function doesTagExistInDefinition(
    tagName: string,
    definition: OASDefinition
  ) {
    return definition.tags?.some((e) => e.name === tagName);
  }

  usedTags.forEach((tagName) => {
    if (!doesTagExistInDefinition(tagName, definition)) {
      definition = addTagToDefinition({
        definition,
        tagObject: { name: tagName },
      });
    }
  });

  return { document_type: "oas_api_3_1", data: definition };
}

/**
 * I'm unsure why we need the response.titles
 */
export function healMissingResponseTitles(editorState: EditorState) {
  const definition = editorState.data;
  const operations = getOperationsWithInfoFromDefinition(definition);

  operations.forEach((op) => {
    for (const [responseCodeStr, responseWrapper] of Object.entries(
      op.operation.responses || {}
    )) {
      if ("$ref" in responseWrapper) return;
      for (const [_, response] of Object.entries(
        responseWrapper.content || {}
      )) {
        if (!response.schema || "$ref" in response.schema) return;
        if (!response.schema?.title) {
          set(
            response,
            "schema.title",
            generateResponseTitleForDefinition(
              responseCodeStr,
              op.operation.operationId || "NoOperationId"
            )
          );
        }
      }
    }
  });

  return editorState;
}

export function healCommonEditorErrors(editorState: EditorState) {
  return healArraysWithProperties(editorState);
}

export function validateEditorStateBeforeUse(editorState: EditorState) {
  return healMissingResponseTitles(
    healMissingTags(
      healNonSensicalOperationIds(healMissingOperationId(editorState))
    )
  );
}

export function getSchemaTypeName(
  schema: OASSchema
): AllPropertyTypes | "mixed" {
  if (schema.allOf) return "allOf";
  if (schema.anyOf) return "anyOf";
  if (schema.oneOf) return "oneOf";

  if (Array.isArray(schema.type)) return "mixed";
  if (schema.type === "null") return "null";
  if (!schema.type) throw new Error("Schema has no type");
  return schema.type;
}

export function getResponseTypeName(response: OASResponse) {
  if (isReference(response)) return deref(response.$ref);
  if (!response.content) return "Unknown";
  const schema = getFirstSchemaFromContentObject(response.content);
  return schema ? getSchemaTypeName(schema) : "Unknown";
}

export function getComponentParameterTypeName(parameter: OASParameterObject) {
  const schema = parameter.schema;
  if (!schema) return "Unknown";
  if (isReference(schema)) return deref(schema.$ref);
  return getSchemaTypeName(schema);
}

export function getComponentSchemaFromComponentsObject(
  componentsObject: OASComponentsObject | undefined,
  componentId: ComponentId
): OASSchema | undefined {
  if (!componentsObject)
    throw new Error(`This workspace has no components object`);
  return componentsObject["schemas"]?.[componentId.id];
}

export function getComponentSchemaFromComponentsObjectOrError(
  componentsObject: OASComponentsObject | undefined,
  componentId: ComponentId,
  format: SupportedContentFormats = "application/json"
): OASSchema {
  if (!componentsObject)
    throw new Error(`This workspace has no components object`);
  if (componentId.type === "schema") {
    const value = componentsObject["schemas"]?.[componentId.id];
    if (!value)
      throw new Error(`Unable fo find component with name: ${componentId.id}`);
    return value;
  }
  if (componentId.type === "dto") {
    const schema = componentsObject["schemas"]?.[componentId.id];
    if (!schema)
      throw new Error(`Unable to find dto with id: ${componentId.id}`);
    if (!schema)
      throw new Error(
        `Dto with id ${componentId.id} does not have a shema in the format of: ${format}`
      );
    return schema;
  }

  const _never: never = componentId.type;
  throw new Error(`Unknown componentId type: ${_never}`);
}

export function getComponentNameFromOASRefObject(
  referenceObject: OASReferenceObject
) {
  return referenceObject.$ref.replace(/^#\/components\/schemas\//, "");
}

export const zodSchemaSchema = z
  .record(z.string(), z.any())
  .transform((schema) => cleanSchema(schema)) as z.ZodType<Record<string, any>>;

export const zodContentObject = z.record(
  z.enum(supportedContentFormats),
  z.object({
    schema: zodSchemaSchema,
    examples: z.any().optional(),
  })
);

export const supportedQueryParameterFormats = [
  "form",
  "spaceDelimited",
  "pipeDelimited",
  "deepObject",
] as const;
export const supportedPathParameterFormats = [
  "simple",
  "label",
  "matrix",
] as const;
export const supportedCookieParameterFormats = ["form"] as const;
export const supportedHeaderParameterFormats = ["simple"] as const;

export function wrapSchemaInArray(schema: OASNonArraySchemaObject): OASSchema {
  return {
    type: "array",
    items: schema,
  };
}

export function unwrapSchemaFromArray(schema: OASArraySchemaObject) {
  return schema.items;
}

export function cleanSchema(schema: OASSchema): OASSchema {
  function traverse<T>(obj: T): T {
    if (obj === null || obj === undefined) return obj;

    if (typeof obj !== "object") return obj;

    if (Array.isArray(obj)) {
      return obj.map(traverse) as unknown as T;
    }

    if (obj instanceof Date) return obj;

    const result = {} as T;
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (!key.startsWith("%%%")) {
          result[key] = traverse(obj[key]);
        }
      }
    }

    return result;
  }

  return traverse(schema);
}

export function convertToReadableContentFormat(e: SupportedContentFormats) {
  const found = e.replace(/.*\/([\w.+-]+)$/, "$1");
  return found.length ? found : e;
}

export const getSecurityRequirementObjectValues = (
  secObj: OASSecurityRequirementDocument
) => {
  const entries = Object.entries(secObj);
  if (!entries.length)
    throw new Error("Not Allowed: Cannot handle empty security object here.");
  if (entries.length > 1)
    throw new Error(
      "Security object with more than one authorization object are not supported."
    );

  const [schemeName, scopes] = entries[0];
  if (!schemeName)
    throw new Error("SecurityRequirementObject: SchemeName is not defined");
  if (!scopes)
    throw new Error("SecurityRequirementObject: Scopes are not defined");

  return { schemeName, scopes };
};

export const securitySchemeTemplateMap: Record<
  SecuritySchemeEasierType,
  OASSecurityScheme
> = {
  ApiKey: {
    type: "apiKey",
    in: "header",
    name: "API_KEY",
  },
  BearerBasic: {
    type: "http",
    scheme: "Basic",
    bearerFormat: "Basic Access Authentication",
    // description:
    //   "Send username and password encoded in base64 within the HTTP Authorization header.",
  },
  BearerJwt: {
    type: "http",
    scheme: "Bearer",
    bearerFormat: "Json Web Token (JWT)",
    // description:
    //   "Include a cryptographically signed token in the Authorization header of the HTTP request.",
  },
  BearerCustom: {
    type: "http",
    scheme: "Bearer",
    bearerFormat: "Custom token",
    // description:
    //   "Include a custom token in the Authorization header of the HTTP request.",
  },
};
