import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { FormLabelRequired } from "@/components/form-label-required";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { SchemaEditor } from "@/components/schema-editor";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaEditorPreviewContainer } from "@/components/schema-quick-editor";
import { useComponents } from "@/hooks/use-components";
import {
  getPristineType,
  zodSchemaSchema,
} from "@/lib/oas-tools/oas-schema-utils";
import { OASComponentsObject, OASSchema } from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  name: z.string().min(1, "Required"),
  schema: zodSchemaSchema,
});
type FormValues = z.infer<typeof formSchema>;

function RawForm({
  title,
  btnTitle,
  defaultValues,
  onSubmit,
  componentsObject,
}: {
  defaultValues?: Partial<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
  componentsObject: OASComponentsObject;
  btnTitle: string;
  title: string;
}) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      schema: getPristineType("object"),
      ...defaultValues,
    },
  });

  return (
    <Form {...form}>
      <ActionBarForm onSubmit={form.handleSubmit(onSubmit)}>
        <ActionBarFormTitle>{title}</ActionBarFormTitle>
        <ActionBarFieldGroup>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Model name
                </FormLabel>
                <FormControl>
                  <Input placeholder="ex. User, Book, Account" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <SchemaEditorPreviewContainer
            title="Schema"
            className="min-h-[150px]"
          >
            <Controller
              name="schema"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <SchemaEditor
                  allowTopLevelReferences={false}
                  componentsObject={componentsObject}
                  value={value}
                  onChange={(schema) => {
                    onChange(schema);
                  }}
                  onRemoveRootSchema={() => {
                    form.setValue("schema", getPristineType("object"));
                  }}
                />
              )}
            />
          </SchemaEditorPreviewContainer>
        </ActionBarFieldGroup>
        <BtnGroup className="justify-end">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </ActionBarForm>
    </Form>
  );
}

export function FormComponentSchemaEdit({
  schemaName,
  componentSchemaObject,
  value,
  onChange,
}: {
  schemaName: string;
  componentSchemaObject: OASSchema;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { editComponentSchema } = useComponents({ value, onChange });
  const onSubmit: SubmitHandler<FormValues> = (values) => {
    editComponentSchema({
      oldName: schemaName,
      newName: values.name,
      schema: values.schema,
    });
    actionBarDispatch({ type: "CLOSE" });
  };
  return (
    <RawForm
      title="Edit model"
      btnTitle="Edit"
      onSubmit={onSubmit}
      defaultValues={{ name: schemaName, schema: componentSchemaObject }}
      componentsObject={componentsObject}
    />
  );
}

export function FormComponentSchemaAdd({ value, onChange }: EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { componentsObject, setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });
  const { addComponentSchema } = useComponents({ value, onChange });
  const onSubmit: SubmitHandler<FormValues> = (values) => {
    addComponentSchema({ schema: values.schema, name: values.name });
    setActiveElement({ type: "model", modelName: values.name });
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-component-suggest-dtos",
        context: {
          schemaName: values.name,
        },
      },
    });
  };
  return (
    <RawForm
      title="Create model"
      btnTitle="Create"
      onSubmit={onSubmit}
      componentsObject={componentsObject}
    />
  );
}
