import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { SchemaEditor } from "@/components/schema-editor";
import { getPristineType } from "@/lib/oas-tools/oas-schema-utils";
import { OASSchema } from "@/lib/types";
import { canEdit } from "@/lib/utils";
import get from "lodash/get";
import set from "lodash/set";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

import { Button } from "@/components/_shadui/button";
import {
  BtnGroup,
  MinimizeButton,
} from "@/components/module-visual-editor/shared-components";
import { cn, DEFAULT_ICON_SIZE_SM, NormIcons } from "@/lib/utils";
import { ComponentProps, PropsWithChildren } from "react";

export function SchemaEditorPreviewContainer({
  title,
  children,
  className,
  isLocked,
  // If no `onLockChange` is passed the editor is
  // always read-only
  onLockChange,
  onClose,
  isDisabled = false,
  ...rest
}: PropsWithChildren<
  Omit<ComponentProps<"div">, "title"> & {
    title: ReactNode;
    isLocked?: boolean;
    onLockChange?: (value: boolean) => unknown;
    onClose?: () => unknown;
    isDisabled?: boolean;
  }
>) {
  return (
    <div
      className={cn(
        "bg-primary-foreground border border-input h-full overflow-auto rounded-md",
        { "opacity-50": isDisabled },
        className
      )}
      {...rest}
    >
      <div
        className={cn(
          "flex items-center justify-between border-input border-b px-2 py-2 gap-3",
          {
            "bg-accent": !onLockChange,
          }
        )}
      >
        <p className="text-sm block">{title}</p>
        <BtnGroup>
          {onLockChange && (
            <Button
              size="icon-sm"
              variant="primary"
              onClick={() => onLockChange(!isLocked)}
            >
              {isLocked ? (
                <NormIcons.Lock size={DEFAULT_ICON_SIZE_SM} />
              ) : (
                <NormIcons.LockOpen size={DEFAULT_ICON_SIZE_SM} />
              )}
            </Button>
          )}
          {onClose && <MinimizeButton onClick={onClose} />}
        </BtnGroup>
      </div>
      <div className="py-2 px-4">{children}</div>
    </div>
  );
}

export const SchemaQuickEditor = forwardRef<
  HTMLDivElement,
  Omit<ComponentPropsWithoutRef<"div">, "onChange"> &
    EditorInputProps & {
      onClose?: () => unknown;
      workspaceRole: WorkspaceRole;
      path: string;
      title: string;
      allowTopLevelReferences: boolean;
    }
>(
  (
    {
      path,
      title,
      className,
      allowTopLevelReferences,
      workspaceRole,
      onClose,
      children: _children,
      value: editorValue,
      onChange: editorOnChange,
      ...rest
    },
    ref
  ) => {
    const { setNewEditorState, componentsObject } = useAPIEditorTools({
      value: editorValue,
      onChange: editorOnChange,
    });

    const value = useMemo(() => {
      return get(editorValue.data, path);
    }, [editorValue.data, path]);

    const handleDefinitionUpdate = useCallback(
      (schema: OASSchema) => {
        const cp = structuredClone(editorValue.data);
        set(cp, path, schema);
        setNewEditorState({
          data: cp,
          document_type: editorValue.document_type,
        });
      },
      [path, editorValue.data, setNewEditorState, editorValue.document_type]
    );

    const handleChange = (schema: OASSchema) => {
      handleDefinitionUpdate(schema);
    };

    const [isLocked, setIsLocked] = useState(true);

    const isEditor = canEdit(workspaceRole);
    return (
      <SchemaEditorPreviewContainer
        isLocked={isLocked}
        onLockChange={isEditor ? () => setIsLocked(!isLocked) : undefined}
        title={title}
        onClose={onClose}
        className={className}
        {...rest}
        ref={ref}
      >
        <SchemaEditor
          value={value}
          onChange={handleChange}
          onRemoveRootSchema={() => handleChange(getPristineType("object"))}
          componentsObject={componentsObject}
          allowTopLevelReferences={allowTopLevelReferences}
          isReadOnly={isLocked}
        />
      </SchemaEditorPreviewContainer>
    );
  }
);
