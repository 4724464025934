import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/_shadui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import { Separator } from "@/components/_shadui/separator";
import { Textarea } from "@/components/_shadui/textarea";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { OnSchemaChange } from "@/components/schema-editor";
import { EnumInput } from "@/components/schema-editor-enum-input";
import { FieldVStack } from "@/forms";
import { useDisclosure } from "@/hooks/use-disclosure";
import { OASSchemaRow } from "@/lib/oas-tools/generate-schema-rows";
import {
  changePropertyMetaData,
  isNonArraySchemaObject,
} from "@/lib/oas-tools/oas-schema-utils";
import { isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { toastError } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { PropsWithChildren } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const stringFormSchema = z
  .object({
    description: z.string(),
    enumValues: z
      .array(z.string())
      .transform((val) => (val.length === 0 ? undefined : val)),
    default: z.string().transform((val) => (val === "" ? undefined : val)),
  })
  .refine((data) => !data.default || data.enumValues?.includes(data.default), {
    message: "'default' must be one of the 'enumValues'",
    path: ["default"], // This is optional, you can specify which field the error pertains to
  });
type StringFormValues = z.infer<typeof stringFormSchema>;

function EditStringForm({
  onSubmit,
  defaultValues,
}: {
  defaultValues: StringFormValues;
  onSubmit: SubmitHandler<StringFormValues>;
}) {
  const form = useForm({
    resolver: zodResolver(stringFormSchema),
    defaultValues,
  });

  const handleSubmit: SubmitHandler<StringFormValues> = (values) => {
    onSubmit(values);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FieldVStack>
          <FormField
            control={form.control}
            name="enumValues"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Enum Values</FormLabel>
                <FormControl>
                  <EnumInput control={form.control} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="default"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Default enum value</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Separator />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </FieldVStack>
        <BtnGroup className="justify-end pt-2">
          <SubmitButton>Submit</SubmitButton>
        </BtnGroup>
      </form>
    </Form>
  );
}

export function SchemaEditorAdvancedTypeDialog({
  children,
  schemaRow,
  onChange,
}: PropsWithChildren<{ schemaRow: OASSchemaRow; onChange: OnSchemaChange }>) {
  const dialogDisclosure = useDisclosure();
  const { isReadOnly, schema } = schemaRow;

  if (
    isReadOnly ||
    isReference(schema) ||
    isNonArraySchemaObject(schema) === false ||
    schema.type !== "string"
  )
    return null;

  const handleSubmit: SubmitHandler<StringFormValues> = (values) => {
    try {
      const newRootSchema = changePropertyMetaData({
        rootSchema: schemaRow.rootSchema,
        schema: schemaRow.schema,
        path: schemaRow.path,
        propertyMetaData: {
          description: values.description,
          enum: values.enumValues,
          default: values.default,
        },
      });

      onChange(newRootSchema);
      dialogDisclosure.onClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Dialog
      open={dialogDisclosure.isOpen}
      onOpenChange={dialogDisclosure.onOpenChange}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Advanced options</DialogTitle>
        </DialogHeader>
        <div>
          <EditStringForm
            onSubmit={handleSubmit}
            defaultValues={{
              description: schema.description || "",
              default: schema.default || "",
              enumValues: schema.enum || [],
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
