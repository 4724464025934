import { apiClient } from "@/lib/http-utils";
import {
  RepositoryAdapterCreateDto,
  RepositoryAdapterDto,
} from "@/lib/main-rest-client/definitions";
import { workspaceItemKeys } from "@/queries/workspace-items";
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

export const repositoryAdapterKeys = {
  all: ["repository_adapter"] as const,
  lists: () => [...repositoryAdapterKeys.all, "list"] as const,
  list: () =>
    // for the user organization adapters
    // add filters if expaned
    [...repositoryAdapterKeys.lists()] as const,
  details: () => [...repositoryAdapterKeys.all, "detail"] as const,
  detail: (id: string) => [...repositoryAdapterKeys.details(), id] as const,
};

export function useCreateRespositoryAdapter() {
  const queryClient = useQueryClient();
  return useMutation<
    RepositoryAdapterDto,
    AxiosError,
    RepositoryAdapterCreateDto
  >({
    mutationFn: async (values) => {
      return (await apiClient.createRepositoryAdapter(values)).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: repositoryAdapterKeys.all,
      });
    },
  });
}

export function useDisconnectWorkspaceFromAdapters() {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, { workspaceItemId: string }>({
    mutationFn: async ({ workspaceItemId }) => {
      return (await apiClient.disconnectFromadAdapters({ workspaceItemId }))
        .data;
    },
    onSuccess: (_, { workspaceItemId }) => {
      queryClient.invalidateQueries({ queryKey: workspaceItemKeys.lists() });
      queryClient.invalidateQueries({
        queryKey: workspaceItemKeys.detail(workspaceItemId),
      });
      queryClient.invalidateQueries({
        queryKey: repositoryAdapterKeys.all,
      });
    },
  });
}

export const organizationRepositoryAdaptersQueryOptions = () =>
  queryOptions({
    queryKey: repositoryAdapterKeys.list(),
    queryFn: async () => {
      return (await apiClient.findOrganizationRepositoryAdapters()).data;
    },
  });

export function useOrganizationRepositoryAdapters() {
  return useQuery(organizationRepositoryAdaptersQueryOptions());
}
