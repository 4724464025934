import { Button } from "@/components/_shadui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/_shadui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/_shadui/popover";
import { State } from "@/components/contexts/schema-editor-context";
import {
  BtnGroup,
  MinimizeButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaEditor } from "@/components/schema-editor";
import { useDisclosure } from "@/hooks/use-disclosure";
import { changeFormatInContentObject } from "@/lib/editor-mutations/oas-content-formats";
import {
  convertToReadableContentFormat,
  getPristineType,
} from "@/lib/oas-tools/oas-schema-utils";
import {
  OASComponentsObject,
  OASContentObject,
  OASSchema,
  supportedContentFormats,
  SupportedContentFormats,
} from "@/lib/types";
import { cn, DEFAULT_ICON_SIZE_SM, NormIcons } from "@/lib/utils";
import { Check } from "lucide-react";
import { ComponentProps, PropsWithChildren, useMemo } from "react";

export function SchemaContentEditorPreviewContainer({
  title,
  children,
  className,
  isLocked,
  // If no `onLockChange` is passed the editor is
  // always read-only
  onLockChange,
  onClose,
  isDisabled = false,
  ...rest
}: PropsWithChildren<
  ComponentProps<"div"> & {
    title: string;
    isLocked?: boolean;
    onLockChange?: (value: boolean) => unknown;
    onClose?: () => unknown;
    isDisabled?: boolean;
  }
>) {
  return (
    <div
      className={cn(
        "bg-primary-foreground border border-input h-full overflow-auto rounded-md",
        { "opacity-50": isDisabled },
        className
      )}
      {...rest}
    >
      <div
        className={cn(
          "flex items-center justify-between border-input border-b px-2 py-2 gap-3",
          {
            "bg-accent": !onLockChange,
          }
        )}
      >
        <p className="text-sm block">{title}</p>
        <BtnGroup>
          {onLockChange && (
            <Button
              size="icon-sm"
              variant="primary"
              onClick={() => onLockChange(!isLocked)}
            >
              {isLocked ? (
                <NormIcons.Lock size={DEFAULT_ICON_SIZE_SM} />
              ) : (
                <NormIcons.LockOpen size={DEFAULT_ICON_SIZE_SM} />
              )}
            </Button>
          )}
          {onClose && <MinimizeButton onClick={onClose} />}
        </BtnGroup>
      </div>
      <div className="py-2 px-2">{children}</div>
    </div>
  );
}

function FormatButton({
  activeFormat,
  onChange,
  disabled,
}: {
  onChange: (format: SupportedContentFormats) => unknown;
  activeFormat: SupportedContentFormats;
  disabled?: boolean;
}) {
  const popoverDisclosure = useDisclosure();
  return (
    <Popover
      open={popoverDisclosure.isOpen}
      onOpenChange={popoverDisclosure.onOpenChange}
      modal={true}
    >
      <PopoverTrigger asChild>
        <Button
          onClick={popoverDisclosure.onToggle}
          key={activeFormat}
          variant="link"
          size="link"
          className={cn("flex pt-0 pb-0 text-xs cursor-pointer h-[1.5rem]", {
            underline: !disabled,
          })}
          disabled={disabled}
        >
          {convertToReadableContentFormat(activeFormat)}

          {!disabled && (
            <NormIcons.Expand
              size={DEFAULT_ICON_SIZE_SM}
              className="shrink-0 grow-0 ml-1"
            />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search framework..." />
          <CommandList>
            <CommandEmpty>No framework found.</CommandEmpty>
            <CommandGroup>
              {supportedContentFormats.map((format) => (
                <CommandItem
                  key={format}
                  value={format}
                  onSelect={(currentValue) => {
                    onChange(currentValue as SupportedContentFormats);
                    popoverDisclosure.onClose();
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      activeFormat === format ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {convertToReadableContentFormat(format)}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

type OnChange = (s: OASContentObject) => unknown;

interface ContentObjectEditorProps {
  value: OASContentObject;
  onChange: OnChange;
  isDisabled?: boolean; // editor should be grayed out and signal that this shema cannot be edited
  isReadOnly?: boolean; // editor should be locked but can be unlocked
  isModelsDisabled?: boolean;
  title?: string;
  description?: string;
  initialState?: Partial<State>;
  componentsObject: OASComponentsObject;
  disableRootSchemaRemove?: boolean;
  allowTopLevelReferences: boolean;
}

export const SchemaContentEditor = ({
  title,
  description,
  allowTopLevelReferences,
  value,
  onChange,
  disableRootSchemaRemove,
  isModelsDisabled,
  isDisabled,
  isReadOnly,
  initialState,
  componentsObject,
}: ContentObjectEditorProps) => {
  const existingFormats = useMemo(() => {
    const formats = Object.keys(value);
    return (
      formats.length ? formats : ["application/json"]
    ) as SupportedContentFormats[];
  }, [value]);

  const format = useMemo(
    () => (existingFormats.length ? existingFormats[0] : "application/json"),
    [existingFormats]
  );

  const handleChange = (schema: OASSchema) => {
    onChange({
      ...value,
      [format]: {
        ...value[format],
        schema,
      },
    });
  };

  const handleChangeExistingFormat = ({
    oldFormat,
    newFormat,
  }: {
    oldFormat: SupportedContentFormats;
    newFormat: SupportedContentFormats;
  }) => {
    const newContentObject = changeFormatInContentObject({
      contentObject: value,
      oldFormat,
      newFormat,
    });
    onChange(newContentObject);
  };

  return (
    <div>
      <div className="flex justify-end items-center gap-2 pb-2">
        <span className="opacity-50 text-xs">Format:</span>
        {existingFormats.map((e) => (
          <FormatButton
            key={e}
            activeFormat={e}
            disabled={isReadOnly || isDisabled}
            onChange={(newFormat: SupportedContentFormats) =>
              handleChangeExistingFormat({ newFormat, oldFormat: e })
            }
          />
        ))}
      </div>
      <SchemaEditor
        value={value?.[format].schema || getPristineType("object")}
        onChange={handleChange}
        onRemoveRootSchema={() => handleChange(getPristineType("object"))}
        componentsObject={componentsObject}
        allowTopLevelReferences={allowTopLevelReferences}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        initialState={initialState}
        title={title}
        description={description}
        isModelsDisabled={isModelsDisabled}
        disableRootSchemaRemove={disableRootSchemaRemove}
      />
    </div>
  );
};
