import { Button } from "@/components/_shadui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/_shadui/dialog";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { useSubmitWorkspaceDocumentDraft } from "@/queries/workspaces";
import { ComponentProps } from "react";

export function SubmitDraftDialog({
  draftId,
  onOpenChange,
  ...rest
}: { draftId: string } & ComponentProps<typeof Dialog>) {
  const [, actionBarDispatch] = useActionBarContext();
  const submitQuery = useSubmitWorkspaceDocumentDraft();

  const handleCancel = () => {
    onOpenChange?.(false);
  };

  const handleSubmit = () => {
    submitQuery.mutate(
      { workspaceDocumentDraftId: draftId },
      {
        onSuccess: () => {
          onOpenChange?.(false);
          actionBarDispatch({ type: "CLOSE" });
        },
      }
    );
  };

  return (
    <Dialog {...rest} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Submit draft?</DialogTitle>
        </DialogHeader>
        <p className="text-muted-foreground">
          Submitting a draft creates pull requests (PRs) connected repositories.
          Merge the PRs and return to this draft. The draft will automatically
          update the main document.
        </p>
        <DialogFooter>
          <BtnGroup>
            <SubmitButton
              disabled={submitQuery.isPending}
              isLoading={submitQuery.isPending}
              onClick={handleSubmit}
            >
              Lock & create pull requests
            </SubmitButton>
            <Button onClick={handleCancel} size="sm" variant="secondary">
              Cancel
            </Button>
          </BtnGroup>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
