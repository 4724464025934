import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/_shadui/tooltip";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  DocumentationCard,
  DocumentationCardContent,
  DocumentationCardDescription,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemContent,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import { HideEmptyListWhenNonEditor } from "@/components/module-api-editor";
import {
  type ActiveOperationItem,
  type SetActiveItem,
} from "@/components/module-api-editor/editor-preview-operation-active-item";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  ComponentButton,
  EditButton,
  LinkButton,
} from "@/components/module-visual-editor/shared-components";
import { TypeBadge } from "@/components/special-badges";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import { ParameterWithInfo, useParameters } from "@/hooks/use-parameters";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { canEdit } from "@/lib/utils";
import { useMemo } from "react";

export function PathParametersCard({
  value,
  onChange,
  operationId,
  workspaceRole,
  setActiveItem,
  activeItem,
}: {
  operationId: string;
  workspaceRole: WorkspaceRole;
  setActiveItem: SetActiveItem;
  activeItem: ActiveOperationItem | undefined;
} & EditorInputProps) {
  const { resolveParameterObject } = useParameters({ value, onChange });
  const { addOrAttatchExistingParameterAsComponent } = useComponentParameters({
    value,
    onChange,
  });
  const [, actionBarDispatch] = useActionBarContext();
  const { getParametersByPosition: getParameters } = useParameters({
    value,
    onChange,
  });
  const { setActiveElement } = useAPIEditorTools({ value, onChange });

  const pathParameters = useMemo(
    () => getParameters(operationId, "path"),
    [getParameters, operationId]
  );

  if (!pathParameters.length) return null;

  const handleEditClick = (parameterWithInfo: ParameterWithInfo) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-path-parameter",
        onInteractOutside: (e) => {
          e.preventDefault();
        },
        context: {
          operationId,
          parameterWithInfo,
        },
      },
    });
  };

  const handleReferenceClick = (refName: string) => {
    setActiveElement({ type: "component-parameter", label: refName });
  };

  const handleAddOrAttachToComponentClick = (parameterIdx: number) => {
    addOrAttatchExistingParameterAsComponent({ operationId, parameterIdx });
  };

  const isEditor = canEdit(workspaceRole);

  return (
    <HideEmptyListWhenNonEditor
      workspaceRole={workspaceRole}
      list={pathParameters}
    >
      <DocumentationCard>
        <DocumentationCardHeader hoverLayout>
          <DocumentationCardTitle>Path parameters</DocumentationCardTitle>
        </DocumentationCardHeader>
        <DocumentationCardContent>
          {pathParameters.map((pathParameter) => {
            const resolved = resolveParameterObject(pathParameter.parameter);
            const refName = isReference(pathParameter.parameter)
              ? deref(pathParameter.parameter.$ref)
              : undefined;
            return (
              <DocumentationCardItem
                key={resolved.name + "-" + "path"}
                isSelected={
                  activeItem?.type === "parameter" &&
                  activeItem.idx === pathParameter.idx
                }
                onClick={() =>
                  setActiveItem({
                    type: "parameter",
                    idx: pathParameter.idx,
                  })
                }
              >
                <DocumentationCardItemHeader>
                  <div className="flex gap-2">
                    <TypeBadge schema={resolved.schema} />
                    {refName && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <LinkButton
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleReferenceClick(refName);
                            }}
                          />
                        </TooltipTrigger>
                        <TooltipContent>Go to &lt;{refName}&gt;</TooltipContent>
                      </Tooltip>
                    )}
                    <DocumentationCardItemTitle>
                      {resolved.name}
                    </DocumentationCardItemTitle>
                  </div>
                  {isEditor && (
                    <BtnGroup>
                      {!refName && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <ComponentButton
                              onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                                handleAddOrAttachToComponentClick(
                                  pathParameter.idx
                                );
                              }}
                            >
                              <span className="sr-only">
                                Turn into component
                              </span>
                            </ComponentButton>
                          </TooltipTrigger>
                          <TooltipContent>
                            Attach to existing component with the same name or
                            turn into new component.
                          </TooltipContent>
                        </Tooltip>
                      )}
                      <EditButton
                        onClick={(evt) => {
                          evt.stopPropagation();
                          evt.preventDefault();
                          handleEditClick(pathParameter);
                        }}
                      >
                        Edit path parameter
                      </EditButton>
                    </BtnGroup>
                  )}
                </DocumentationCardItemHeader>
                <DocumentationCardItemContent>
                  <DocumentationCardDescription>
                    {resolved.description || "No description"}
                  </DocumentationCardDescription>
                </DocumentationCardItemContent>
              </DocumentationCardItem>
            );
          })}
        </DocumentationCardContent>
      </DocumentationCard>
    </HideEmptyListWhenNonEditor>
  );
}
