import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Textarea } from "@/components/_shadui/textarea";
import { SchemaEditor } from "@/components/schema-editor";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { getPristineType } from "@/lib/oas-tools/oas-schema-utils";
import {
  OASComponentsObject,
  OASReferenceObject,
  OASSchema,
} from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import merge from "lodash/merge";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { ActionBarContent } from "./action-bar-content";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "./elements";
import { SchemaEditorPreviewContainer } from "@/components/schema-quick-editor";

const formValues = z.object({
  description: z.string().trim(),
  schema: z.record(z.string(), z.any()) as z.ZodType<
    OASSchema | OASReferenceObject
  >,
});
export type PathParameterFormValues = z.infer<typeof formValues>;

export function PathParameterForm({
  defaultValues,
  onSubmit,
  title,
  componentsObject,
  btnTitle,
}: {
  defaultValues: Partial<PathParameterFormValues>;
  onSubmit: (values: PathParameterFormValues) => unknown;
  title: string;
  componentsObject: OASComponentsObject;
  btnTitle: "Create" | "Edit";
}) {
  const form = useForm<PathParameterFormValues>({
    resolver: zodResolver(formValues),
    defaultValues: merge(
      {
        description: "",
        schema: getPristineType("string"),
      },
      defaultValues
    ),
  });

  return (
    <ActionBarContent>
      <Form {...form}>
        <ActionBarForm
          onSubmit={(e) => {
            form.handleSubmit(onSubmit)(e);
          }}
        >
          <ActionBarFormTitle>{title}</ActionBarFormTitle>
          <ActionBarFieldGroup>
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex gap-2 items-center">
                    Description
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </ActionBarFieldGroup>
          <Controller
            name="schema"
            control={form.control}
            render={({ field: { onChange, value } }) => {
              return (
                <SchemaEditorPreviewContainer title="Schema">
                  <SchemaEditor
                    value={value}
                    onChange={onChange}
                    onRemoveRootSchema={() =>
                      form.setValue("schema", getPristineType("string"))
                    }
                    componentsObject={componentsObject}
                    allowTopLevelReferences={true}
                  />
                </SchemaEditorPreviewContainer>
              );
            }}
          />
          <BtnGroup className="justify-end">
            <SubmitButton>{btnTitle}</SubmitButton>
          </BtnGroup>
        </ActionBarForm>
      </Form>
    </ActionBarContent>
  );
}
