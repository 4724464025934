import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/_shadui/select";
import { FormLabelRequired } from "@/components/form-label-required";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { FieldVStack, FormTitle } from "@/forms";
import { MutationCallbacks } from "@/hooks";
import { GithubRepositoryDto } from "@/lib/main-rest-client/definitions";
import { toastError } from "@/lib/utils";
import { useCreateRespositoryAdapter } from "@/queries/repository-adapters";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  provider: z.enum(["github"]),
  openapi_3_1_repository_path: z
    .string()
    .regex(
      /^[^/].*\/?[^/]+\.yaml$/,
      "Path must end with .yaml and contain a valid filename"
    ),
  github_repository_id: z.string().min(1, "Required"),
  branch_name: z.string().min(1, "Required"),
});

type FormValues = z.infer<typeof formSchema>;

function RawForm({
  defaultValues,
  onSubmit,
  btnTitle,
  formTitle,
  repositoryList,
  isLoading,
}: {
  defaultValues: FormValues;
  onSubmit: SubmitHandler<FormValues>;
  btnTitle: string;
  formTitle?: string;
  repositoryList: GithubRepositoryDto[];
  isLoading: boolean;
}) {
  const form = useForm({
    resolver: zodResolver(formSchema),
    disabled: isLoading,
    defaultValues,
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {formTitle && <FormTitle>{formTitle}</FormTitle>}
        <FieldVStack>
          <FormField
            control={form.control}
            name="github_repository_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Repository name
                </FormLabel>
                <Select
                  value={field.value}
                  onValueChange={field.onChange}
                  disabled={field.disabled || repositoryList.length <= 1}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {repositoryList.map((e) => (
                      <SelectItem key={e.id} value={String(e.id)}>
                        {e.full_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="branch_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Branch name
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="openapi_3_1_repository_path"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Path
                </FormLabel>
                <FormControl>
                  <Input placeholder="api/openapi.yaml" {...field} />
                </FormControl>
                <FormDescription>
                  The path to your file from the root of the repository
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </FieldVStack>
        <BtnGroup className="justify-end">
          <SubmitButton isLoading={isLoading} disabled={isLoading}>
            {btnTitle}
          </SubmitButton>
        </BtnGroup>
      </form>
    </Form>
  );
}

export function RepositoryAdapterCreate({
  repositoryList,
  githubInstallationId,
  organizationId,
  workspaceItemId,
  defaultBranch,
  defaultRepositoryId,
  onSettled,
  onError,
  onSuccess,
}: {
  repositoryList: GithubRepositoryDto[];
  githubInstallationId: string;
  organizationId: string;
  workspaceItemId: string;
  defaultBranch: string;
  defaultRepositoryId: string;
} & MutationCallbacks<{ repositoryAdapterId: string }>) {
  const createRepositoryAdapterQuery = useCreateRespositoryAdapter();

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const repository = repositoryList.find(
      (e) => e.id === +values.github_repository_id
    );
    if (!repository) throw new Error("Unable to find repository");

    const ownerName = repository.full_name.match(/(.*)\//)?.[1];
    if (!ownerName) throw new Error("Unable to fine repository owner name");

    createRepositoryAdapterQuery.mutate(
      {
        branch_name: values.branch_name,
        github_installation_id: githubInstallationId,
        github_repository_id: +values.github_repository_id,
        openapi_3_1_repository_path: values.openapi_3_1_repository_path,
        organization_id: organizationId,
        provider: "github",
        workspace_item_id: workspaceItemId,
        repository_name: repository.name,
        repository_owner_name: ownerName,
      },
      {
        onError: (err) => {
          onError?.();
          toastError(err);
        },
        onSuccess: (data) => {
          onSuccess?.({ repositoryAdapterId: data.id });
        },
        onSettled: () => {
          onSettled?.();
        },
      }
    );
  };

  return (
    <RawForm
      btnTitle="Connect repo"
      repositoryList={repositoryList}
      isLoading={createRepositoryAdapterQuery.isPending}
      onSubmit={onSubmit}
      defaultValues={{
        branch_name: defaultBranch,
        github_repository_id: defaultRepositoryId,
        openapi_3_1_repository_path: "",
        provider: "github",
      }}
    />
  );
}
