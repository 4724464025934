import { Button } from "@/components/_shadui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_shadui/dropdown-menu";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { SelectDraftModal } from "@/components/select-draft-modal";
import { useDisclosure } from "@/hooks/use-disclosure";
import {
  DocumentPullRequestDto,
  WorkspaceDocumentDraftDto,
  WorkspaceDocumentDto,
} from "@/lib/main-rest-client/definitions";
import { cn, DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import {
  useCheckWorkspaceDocumentDraftStatusManually,
  useUnlockWorkspaceDocumentDraft,
} from "@/queries/workspaces";
import { Link } from "@tanstack/react-router";
import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";
import { toast } from "sonner";

const workspaceAlertVariants = cva("px-3 py-2", {
  variants: {
    variant: {
      info: "bg-blue-600 text-white",
      warning: "bg-orange-300 text-orange-900",
      error: "bg-destructive text-destructive-foreground",
    },
    size: {},
  },
  defaultVariants: {
    variant: "info",
  },
});

export function WorkspaceAlert({
  children,
  variant,
}: {
  children: ReactNode;
} & VariantProps<typeof workspaceAlertVariants>) {
  return (
    <div className={cn(workspaceAlertVariants({ variant }))}>
      <div>{children}</div>
    </div>
  );
}

export function DraftWorkspaceAlert({
  defaultPr,
  draft,
  workspaceSlug,
  organizationSlug,
  workspaceDocument,
  isOrgMember,
  isAuthed,
}: {
  defaultPr: DocumentPullRequestDto | undefined;
  draft: WorkspaceDocumentDraftDto;
  workspaceSlug: string;
  organizationSlug: string;
  workspaceDocument: WorkspaceDocumentDto;
  isOrgMember: boolean;
  isAuthed: boolean;
}) {
  const checkDraftStatusManuallyQuery =
    useCheckWorkspaceDocumentDraftStatusManually();

  const unlockQuery = useUnlockWorkspaceDocumentDraft();

  const handleUnlock = () => {
    unlockQuery.mutate({ workspaceDocumentDraftId: draft.id });
  };

  const draftDisclosure = useDisclosure();

  const handleCheckManually = () => {
    checkDraftStatusManuallyQuery.mutate(
      { workspaceDocumentDraftId: draft.id },
      {
        onSuccess: (data) => {
          toast.success(`Status updated to: ${data.status}`);
        },
      }
    );
  };

  return (
    <>
      {draft.status === "locked" ? (
        <WorkspaceAlert variant="warning">
          <div className="flex justify-between items-center">
            <div>
              <span className="inline-block pr-2">🔑</span>
              <span className="font-bold">
                {defaultPr ? (
                  <>
                    This draft is locked.{" "}
                    <a
                      className="underline"
                      href={defaultPr.remote_url}
                      target="_blank"
                    >
                      Merge this PR
                    </a>{" "}
                    to update the main document.
                  </>
                ) : (
                  <>
                    This draft is locked. It will merge automatically after its
                    Pull Requests are closed.
                  </>
                )}
              </span>
            </div>
            <BtnGroup>
              {defaultPr && isOrgMember && (
                <a
                  className="underline"
                  href={defaultPr.remote_url}
                  target="_blank"
                >
                  <Button size="xs">
                    <NormIcons.Link size={DEFAULT_ICON_SIZE} className="mr-2" />
                    Visit PR
                  </Button>
                </a>
              )}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    size="xs"
                    disabled={
                      checkDraftStatusManuallyQuery.isPending ||
                      unlockQuery.isPending
                    }
                  >
                    ...
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {isOrgMember && (
                    <>
                      <DropdownMenuItem
                        onClick={handleCheckManually}
                        disabled={checkDraftStatusManuallyQuery.isPending}
                      >
                        <NormIcons.Check
                          size={DEFAULT_ICON_SIZE}
                          className="text-muted-foreground mr-2"
                        />
                        Check status manually
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={handleUnlock}>
                        <NormIcons.Edit
                          size={DEFAULT_ICON_SIZE}
                          className="text-muted-foreground mr-2"
                        />
                        Close PRs, continue editing
                      </DropdownMenuItem>
                    </>
                  )}
                  <Link
                    to="/editor/$organizationSlug/$workspaceSlug"
                    params={{ workspaceSlug, organizationSlug }}
                  >
                    <DropdownMenuItem>
                      <NormIcons.Link
                        size={DEFAULT_ICON_SIZE}
                        className="text-muted-foreground mr-2"
                      />
                      Go to main file
                    </DropdownMenuItem>
                  </Link>
                  <DropdownMenuItem onClick={draftDisclosure.onOpen}>
                    <NormIcons.Add
                      size={DEFAULT_ICON_SIZE}
                      className="text-muted-foreground mr-2"
                    />
                    Add or switch draft
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </BtnGroup>
          </div>
        </WorkspaceAlert>
      ) : draft.status === "merged" ? (
        <WorkspaceAlert variant="info">
          <div className="flex justify-between items-center">
            <div>
              <span className="inline-block pr-2">🎉</span>
              <span className="font-bold">
                <>
                  This draft was merged. Click{" "}
                  <Link
                    className="underline"
                    to="/editor/$organizationSlug/$workspaceSlug"
                    params={{
                      workspaceSlug,
                      organizationSlug,
                    }}
                  >
                    here to return to the main file.
                  </Link>{" "}
                </>
              </span>
            </div>
          </div>
        </WorkspaceAlert>
      ) : undefined}

      {isAuthed && (
        <SelectDraftModal
          isOpen={draftDisclosure.isOpen}
          onOpenChange={draftDisclosure.onOpenChange}
          workspaceDocument={workspaceDocument}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          isOrgMember={isOrgMember}
        />
      )}
    </>
  );
}
