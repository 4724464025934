import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import { Switch } from "@/components/_shadui/switch";
import { Textarea } from "@/components/_shadui/textarea";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { FormLabelRequired } from "@/components/form-label-required";
import { ActionBarReferenceMessage } from "@/components/module-action-bar";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaEditor } from "@/components/schema-editor";
import { SchemaEditorPreviewContainer } from "@/components/schema-quick-editor";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import { ParameterWithInfo, useParameters } from "@/hooks/use-parameters";
import {
  getPristineType,
  supportedCookieParameterFormats,
  supportedHeaderParameterFormats,
  supportedPathParameterFormats,
  supportedQueryParameterFormats,
  zodSchemaSchema,
} from "@/lib/oas-tools/oas-schema-utils";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { appRegex } from "@/lib/regex";
import {
  OASComponentsObject,
  OASParameterObject,
  ParameterPosition,
} from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import merge from "lodash/merge";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const parameterPositionDefaultStyles = {
  cookie: "form",
  header: "simple",
  path: "simple",
  query: "form",
} as const;

const parameterPositionDefaultValueMap: Record<ParameterPosition, FormValues> =
  {
    cookie: {
      name: "",
      description: "",
      style: parameterPositionDefaultStyles["cookie"],
      allowReserved: false,
      explode: true,
      in: "cookie",
      schema: getPristineType("string"),
      required: false,
    },
    header: {
      name: "",
      description: "",
      style: parameterPositionDefaultStyles["header"],
      allowReserved: false,
      explode: false,
      in: "header",
      schema: getPristineType("string"),
      required: false,
    },
    query: {
      name: "",
      description: "",
      style: parameterPositionDefaultStyles["query"],
      allowReserved: false,
      explode: true,
      in: "query",
      schema: getPristineType("string"),
      required: false,
    },
    path: {
      name: "",
      description: "",
      style: parameterPositionDefaultStyles["path"],
      allowReserved: false,
      explode: false,
      in: "path",
      schema: getPristineType("string"),
      required: true,
    },
  };

const styles = {
  cookie: supportedCookieParameterFormats,
  header: supportedHeaderParameterFormats,
  query: supportedQueryParameterFormats,
  path: supportedPathParameterFormats,
} satisfies Record<ParameterPosition, readonly string[]>;

const parameterNameSchema = z
  .string()
  .regex(appRegex.parameterName.expression, appRegex.parameterName.message)
  .refine((value) => value.toLowerCase() !== "authorization", {
    message: "Authorization headers must be set through securitySchemes.",
  });

const pathParameterFormValues = z.object({
  in: z.literal("path"),
  name: parameterNameSchema,
  required: z.boolean(),
  style: z.enum(styles["path"]).default(parameterPositionDefaultStyles["path"]),
  description: z.string().trim(),
  explode: z.boolean().default(false),
  schema: zodSchemaSchema,
  allowReserved: z.boolean().default(false),
});

const queryParameterFormValues = z.object({
  in: z.literal("query"),
  name: parameterNameSchema,
  required: z.boolean(),
  style: z
    .enum(styles["query"])
    .default(parameterPositionDefaultStyles["query"]),
  description: z.string().trim(),
  explode: z.boolean().default(true),
  schema: zodSchemaSchema,
  allowReserved: z.boolean().default(false),
});

const headerFormValues = z.object({
  in: z.literal("header"),
  name: z
    .string()
    .regex(appRegex.parameterName.expression, appRegex.parameterName.message),
  required: z.boolean(),
  style: z
    .enum(styles["header"])
    .default(parameterPositionDefaultStyles["header"]),
  description: z.string().trim(),
  explode: z.boolean().default(false),
  schema: zodSchemaSchema,
  allowReserved: z.boolean().default(false),
});

const cookieFormValues = z.object({
  in: z.literal("cookie"),
  name: z
    .string()
    .regex(appRegex.parameterName.expression, appRegex.parameterName.message),
  required: z.boolean(),
  style: z
    .enum(styles["query"])
    .default(parameterPositionDefaultStyles["query"]),
  description: z.string().trim(),
  explode: z.boolean().default(true),
  schema: zodSchemaSchema,
  allowReserved: z.boolean().default(false),
});

const formValues = z.union([
  pathParameterFormValues,
  queryParameterFormValues,
  headerFormValues,
  cookieFormValues,
]);

type FormValues = z.infer<typeof formValues>;

function RawForm({
  defaultValues,
  onSubmit,
  title,
  componentsObject,
  btnTitle,
}: {
  defaultValues: FormValues;
  onSubmit: (values: FormValues) => unknown;
  title: string;
  componentsObject: OASComponentsObject;
  btnTitle: string;
}) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formValues),
    defaultValues: defaultValues,
  });
  const schema = form.watch("schema");
  const inValue = form.watch("in");

  return (
    <Form {...form}>
      <ActionBarForm
        onSubmit={(e) => {
          form.handleSubmit(onSubmit)(e);
        }}
      >
        <ActionBarFormTitle>{title}</ActionBarFormTitle>

        <ActionBarFieldGroup>
          <FormField
            control={form.control}
            name="name"
            disabled={inValue === "path"}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  {inValue === "cookie"
                    ? "Cookie"
                    : inValue === "header"
                      ? "Request header"
                      : inValue === "path"
                        ? "Path parameter"
                        : inValue === "query"
                          ? "Query parameter"
                          : "Parameter"}{" "}
                  name
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex gap-2 items-center">
                  Description
                </FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="required"
            render={({ field }) => {
              return (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Required</FormLabel>
                    <FormDescription>Parameter must be present</FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              );
            }}
          />
          <SchemaEditorPreviewContainer
            title="Schema"
            className="min-h-[250px] max-h-[250px]"
          >
            <SchemaEditor
              value={schema}
              onChange={(schema) => form.setValue("schema", schema)}
              allowTopLevelReferences={true}
              onRemoveRootSchema={() =>
                form.setValue("schema", getPristineType("string"))
              }
              componentsObject={componentsObject}
            />
          </SchemaEditorPreviewContainer>
        </ActionBarFieldGroup>
        <BtnGroup className="justify-end">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </ActionBarForm>
    </Form>
  );
}

const addformTextMap: Record<
  ParameterPosition,
  { title: string; btnTitle: string }
> = {
  cookie: {
    title: "Add cookie",
    btnTitle: "Add",
  },
  header: {
    title: "Add request header",
    btnTitle: "Add",
  },
  path: {
    title: "Add path parameters",
    btnTitle: "Add",
  },
  query: {
    title: "Add query parameter",
    btnTitle: "Add",
  },
};

const editformTextMap: Record<
  ParameterPosition,
  { title: string; btnTitle: string }
> = {
  cookie: {
    title: "Edit cookie",
    btnTitle: "Edit",
  },
  header: {
    title: "Edit request header",
    btnTitle: "Edit",
  },
  path: {
    title: "Edit path parameters",
    btnTitle: "Edit",
  },
  query: {
    title: "Edit query parameter",
    btnTitle: "Edit",
  },
};

export function RequestParameterAdd({
  operationId,
  urlPath,
  value,
  onChange,
  parameterPosition,
}: {
  operationId: string;
  urlPath: string;
  parameterPosition: ParameterPosition;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { addParameter } = useParameters({ value, onChange });

  const defaultValues = parameterPositionDefaultValueMap[parameterPosition];

  const onSubmit = (values: FormValues) => {
    try {
      addParameter({
        urlPath,
        parameter: {
          ...values,
          schema: values.schema as OASParameterObject["schema"],
        },
        operationId,
      });
      actionBarDispatch({
        type: "CLOSE",
      });
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  return (
    <RawForm
      btnTitle={addformTextMap[parameterPosition].btnTitle}
      title={addformTextMap[parameterPosition].title}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      componentsObject={componentsObject}
    />
  );
}

export function RequestParameterEdit({
  operationId,
  parameterWithInfo,
  onChange,
  value,
}: {
  operationId: string;
  parameterWithInfo: ParameterWithInfo;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { componentsObject, setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });
  const { update, dereferenceParameter } = useParameters({ value, onChange });

  const onSubmit = (
    values: FormValues,
    parameterWithInfo: ParameterWithInfo
  ) => {
    if (isReference(parameterWithInfo.parameter))
      throw new Error("Parameter cannot be reference");
    try {
      update({
        parameterIdx: parameterWithInfo.idx,
        operationId,
        oldParameter: parameterWithInfo.parameter,
        newParameter: {
          ...parameterWithInfo.parameter,
          ...values,
          schema: values.schema as OASParameterObject["schema"],
        },
      });
      actionBarDispatch({
        type: "CLOSE",
      });
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  const handleVisitResponse = (label: string) => {
    setActiveElement({ type: "component-parameter", label });
    actionBarDispatch({
      type: "CLOSE",
    });
  };

  const handleDereference = () => {
    dereferenceParameter({ parameterIdx: parameterWithInfo.idx, operationId });
    actionBarDispatch({
      type: "CLOSE",
    });
  };

  if (isReference(parameterWithInfo.parameter)) {
    const label = deref(parameterWithInfo.parameter.$ref);
    return (
      <ActionBarReferenceMessage
        title="Reference"
        description={
          <>
            This parameter points to{" "}
            <span
              role="button"
              onClick={() => handleVisitResponse(label)}
              className="text-brand underline cursor-pointer"
            >
              {label}
            </span>
            . Edit the component or dereference this response.
          </>
        }
        onVisitReference={() => handleVisitResponse(label)}
        onDereference={handleDereference}
      />
    );
  }

  const parameterPosition = parameterWithInfo.parameter.in as ParameterPosition;

  const defaultValues = merge(
    parameterPositionDefaultValueMap[parameterPosition],
    parameterWithInfo.parameter
  );

  return (
    <RawForm
      btnTitle={editformTextMap[parameterPosition].btnTitle}
      title={editformTextMap[parameterPosition].title}
      defaultValues={defaultValues}
      onSubmit={(values) => onSubmit(values, parameterWithInfo)}
      componentsObject={componentsObject}
    />
  );
}

export function FormComponentRequestParameterAdd({
  value,
  onChange,
  parameterPosition,
}: EditorInputProps & { parameterPosition: ParameterPosition }) {
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { addComponentParameter } = useComponentParameters({ value, onChange });

  const [, actionBarDispatch] = useActionBarContext();

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    addComponentParameter({
      name: values.name,
      parameter: {
        name: values.name,
        description: values.description,
        in: values.in,
        schema: values.schema,
        explode: values.explode,
        style: values.style,
        allowReserved: values.allowReserved,
        allowEmptyValue: values.required,
      },
    });
    actionBarDispatch({ type: "CLOSE" });
  };

  const defaultValues = parameterPositionDefaultValueMap[parameterPosition];

  return (
    <RawForm
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      title="Add req. parameter component"
      btnTitle="Add"
      componentsObject={componentsObject}
    />
  );
}

export function FormEditComponentRequestParameter({
  value,
  onChange,
  parameter,
  label,
}: { parameter: OASParameterObject; label: string } & EditorInputProps) {
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { editComponentParameter } = useComponentParameters({
    value,
    onChange,
  });

  const [, actionBarDispatch] = useActionBarContext();

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    editComponentParameter({
      oldName: label,
      newName: values.name,
      parameterObject: {
        name: values.name,
        in: values.in,
        description: values.description,
        schema: values.schema,
        explode: values.explode,
        style: values.style,
        allowReserved: values.allowReserved,
        allowEmptyValue: values.required,
      },
    });
    actionBarDispatch({ type: "CLOSE" });
  };

  const defaultValues = merge(
    parameterPositionDefaultValueMap[parameter.in as ParameterPosition],
    {
      ...parameter,
    }
  );

  return (
    <RawForm
      componentsObject={componentsObject}
      btnTitle="Edit"
      title="Edit req. parameter component"
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    />
  );
}
