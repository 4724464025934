import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { ActionBarReferenceMessage } from "@/components/module-action-bar";
import {
  PathParameterForm,
  PathParameterFormValues,
} from "@/components/module-action-bar/path-parameter-form";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { ParameterWithInfo, useParameters } from "@/hooks/use-parameters";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { OASParameterObject } from "@/lib/types";
import { toast } from "sonner";

export function PathParameterEdit({
  operationId,
  parameterWithInfo,
  value,
  onChange,
}: {
  operationId: string;
  parameterWithInfo: ParameterWithInfo;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { componentsObject, setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });
  const { update, dereferenceParameter } = useParameters({ value, onChange });

  const onSubmit = (
    values: PathParameterFormValues,
    parameterWithInfo: ParameterWithInfo
  ) => {
    if (isReference(parameterWithInfo.parameter))
      throw new Error("Cannot change reference");
    try {
      update({
        parameterIdx: parameterWithInfo.idx,
        operationId,
        oldParameter: parameterWithInfo.parameter,
        newParameter: {
          ...parameterWithInfo.parameter,
          ...values,
          schema: values.schema as OASParameterObject["schema"],
        },
      });
      actionBarDispatch({
        type: "CLOSE",
      });
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  const handleVisitParameter = (label: string) => {
    setActiveElement({ type: "component-parameter", label });
    actionBarDispatch({ type: "CLOSE" });
  };

  const handleDereference = () => {
    dereferenceParameter({ operationId, parameterIdx: parameterWithInfo.idx });
    actionBarDispatch({
      type: "CLOSE",
    });
  };

  if (isReference(parameterWithInfo.parameter)) {
    const label = deref(parameterWithInfo.parameter.$ref);
    return (
      <ActionBarReferenceMessage
        title="Reference"
        description={
          <>
            This parameter points to{" "}
            <span
              role="button"
              onClick={() => handleVisitParameter(label)}
              className="text-brand underline cursor-pointer"
            >
              {label}
            </span>
            . Edit the component or dereference this response.
          </>
        }
        onVisitReference={() => handleVisitParameter(label)}
        onDereference={handleDereference}
      />
    );
  }

  return (
    <PathParameterForm
      btnTitle="Edit"
      title="Edit parameter"
      defaultValues={{
        schema: parameterWithInfo.parameter.schema,
        description: parameterWithInfo.parameter.description,
      }}
      onSubmit={(values) => onSubmit(values, parameterWithInfo)}
      componentsObject={componentsObject}
    />
  );
}
