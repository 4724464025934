import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/components/_shadui/alert";
import { Avatar } from "@/components/_shadui/avatar";
import { Button } from "@/components/_shadui/button";
import { Input } from "@/components/_shadui/input";
import { Label } from "@/components/_shadui/label";
import { cn, setClipboard } from "@/lib/utils";
import { AvatarImage } from "@radix-ui/react-avatar";
import { CopyIcon, ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { ComponentProps, PropsWithChildren, useState } from "react";
import { toast } from "sonner";

export function Logo({ width }: { width: number }) {
  return (
    <img
      src={"/img/header-logo.svg"}
      style={{ width }}
      width={196}
      height={72}
    />
  );
}

export function UserAvatar({ children }: PropsWithChildren) {
  return (
    <Avatar className="border-2 border-brand w-8 h-8">
      {children}
      <AvatarImage src="/img/avatar-background.png" />
    </Avatar>
  );
}

export function CopyInput({ value, ...rest }: ComponentProps<"input">) {
  const [inputValue] = useState(value || "");
  const handleClick = () => {
    setClipboard(inputValue as string);
    toast.success("Copied to clipboard");
  };
  return (
    <div className="flex items-center space-x-2">
      <div className="grid flex-1 gap-2">
        <Label htmlFor="link" className="sr-only">
          Link
        </Label>
        <Input id="link" value={value} readOnly {...rest} />
      </div>
      <Button
        type="submit"
        size="sm"
        className="px-3"
        variant="primary"
        onClick={handleClick}
      >
        <span className="sr-only">Copy</span>
        <CopyIcon className="h-4 w-4" />
      </Button>
    </div>
  );
}

export type ComboboxOptions = {
  value: string;
  label: string;
};

export function ApiError({ error }: { error: Error }) {
  const msg = error.message;
  return (
    <Alert variant="destructive">
      <ExclamationTriangleIcon className="h-4 w-4" />
      <AlertTitle>Error</AlertTitle>
      <AlertDescription>
        The API returned the following error: ${msg}
      </AlertDescription>
    </Alert>
  );
}

export function SmallText({ children, className }: ComponentProps<"small">) {
  return (
    <small className={cn("text-muted-foreground", className)}>{children}</small>
  );
}
