import { Button } from "@/components/_shadui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/_shadui/tooltip";
import { globalVariables } from "@/lib/const";
import { addPlaceholderToSchema } from "@/lib/oas-schema-mutations";
import {
  generateOASSchemaRows,
  OASSchemaRowType,
  type OASSchemaRow,
  type PadProperty,
} from "@/lib/oas-tools/generate-schema-rows";
import {
  addPlaceholderToPath,
  addRequiredToProperty,
  changePropertyName,
  getPathProperty,
  isNonArraySchemaObject,
  moveUpLevel,
  removePathFromSchema,
  removeRequiredForProperty,
  TypeOptions,
} from "@/lib/oas-tools/oas-schema-utils";
import {
  deref,
  findComposedKey,
  getMixedTagStringValue,
  isArraySchema,
  isComposedSchema,
  isMixedSchema,
  isObjectSchema,
  isReferenceSchema,
  isSimpleSchema,
} from "@/lib/oas-tools/oas-tag-helpers";
import { appRegex } from "@/lib/regex";
import {
  OASArraySchemaObject,
  OASComponentsObject,
  OASMixedSchemaObject,
  OASNonArraySchemaObject,
  OASReferenceObject,
  OASSchema,
} from "@/lib/types";
import {
  cn,
  DEFAULT_ICON_SIZE_SM,
  NormIcons,
  PickRequired,
  toastError,
} from "@/lib/utils";
import includes from "lodash/includes";
import { ChevronDown, Ellipsis, X } from "lucide-react";
import {
  ComponentProps,
  forwardRef,
  InputHTMLAttributes,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z, ZodError } from "zod";
import {
  ChangeTypeOption,
  useChangeType,
} from "../hooks/use-schema-editor-change-type";
import {
  SchemaEditorContextProvider,
  State,
  useSchemaEditorContext,
} from "./contexts/schema-editor-context";
import { SelectTypeModal } from "./schema-editor-select-type-modal";
import { SchemaEditorAdvancedTypeDialog } from "@/components/schema-editor-advanced-type-dialog";

const ICON_SIZE = 10;
const PAD_AMOUNT = 22;

export type OnSchemaChange = (s: OASSchema) => unknown;
type TagProps<T extends OASSchema = OASSchema> = {
  schema: T;
  path: string;
  onSelect?: (e: ChangeTypeOption) => unknown;
  typeOptions: TypeOptions;
  componentsObject: OASComponentsObject | undefined;
  isDisabled: boolean;
  isReadOnly: boolean;
  rootSchema: OASSchema;
  onChange: OnSchemaChange;
  isModelsDisabled: boolean;
};
type TagTypes =
  | "string"
  | "object"
  | "oneOf"
  | "anyOf"
  | "allOf"
  | "array"
  | "integer"
  | "number"
  | "boolean"
  | "mixed"
  | "$ref"
  | "null";

function renderTag(
  rowType: OASSchemaRowType,
  { schema, ...props }: TagProps
): ReactElement {
  switch (rowType) {
    case "simple-row":
      return (
        <SimpleTag schema={schema as OASNonArraySchemaObject} {...props} />
      );
    case "reference-row":
      return <ReferenceTag schema={schema as OASReferenceObject} {...props} />;
    case "composed-row":
      return (
        <ComposedTag schema={schema as OASNonArraySchemaObject} {...props} />
      );
    case "array-row":
      return <ArrayTag schema={schema as OASArraySchemaObject} {...props} />;
    case "mixed-row":
      return <MixedTag schema={schema as OASMixedSchemaObject} {...props} />;
    case "object-row":
      return <ObjectTag schema={schema as OASSchema} {...props} />;
    default:
      throw new Error("Unknown row type");
  }
}

function AddButton(props: ComponentProps<typeof Button>) {
  return (
    <EditorIconButton {...props}>
      <NormIcons.Add size={ICON_SIZE} />
    </EditorIconButton>
  );
}

const EditorIconButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>(({ children, className, ...rest }, ref) => {
  return (
    <Button
      type="button"
      size="icon-sm"
      className={cn("w-[16px] h-[16px] rounded-sm", className)}
      variant="secondary"
      {...rest}
      ref={ref}
    >
      {children}
    </Button>
  );
});

function RequiredTag({
  row,
  onChange,
}: {
  row: OASSchemaRow;
  onChange: OnSchemaChange;
}) {
  const handleClick = () => {
    const newSchema = removeRequiredForProperty(row.rootSchema, row.path);
    onChange(newSchema);
    toast.success("Marked as optional");
  };
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <EditorIconButton onClick={handleClick} variant="ghost">
          <NormIcons.Required size={ICON_SIZE} />
        </EditorIconButton>
      </TooltipTrigger>
      <TooltipContent>
        <p>Required</p>
      </TooltipContent>
    </Tooltip>
  );
}

function RequiredToggle({
  row,
  onChange,
}: {
  row: OASSchemaRow;
  onChange: OnSchemaChange;
}) {
  const handleClick = () => {
    const newSchema = addRequiredToProperty(row.rootSchema, row.path);
    onChange(newSchema);
    toast.success("Marked as required");
  };
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="ghost" size="link" className="" onClick={handleClick}>
          or null
        </Button>
      </TooltipTrigger>
      <TooltipContent>Optional</TooltipContent>
    </Tooltip>
  );
}

const RemoveButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>(({ children, ...rest }, ref) => {
  return (
    <EditorIconButton variant="destructive" ref={ref} type="button" {...rest}>
      <X size={10} />
      {children}
    </EditorIconButton>
  );
});

function ExpandButton({ isDisabled }: { isDisabled: boolean }) {
  if (isDisabled) return null;
  return <ChevronDown size={11} />;
}

function RootTag({
  schema,
  path,
  onSelect,
  typeOptions,
  isDisabled,
  isReadOnly,
  rootSchema,
  onChange,
  componentsObject,
  isModelsDisabled,
}: TagProps<OASSchema | OASReferenceObject>) {
  if (isReferenceSchema(schema)) {
    return (
      <ReferenceTag
        schema={schema}
        path={path}
        onSelect={onSelect}
        typeOptions={typeOptions}
        onChange={onChange}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        rootSchema={rootSchema}
        componentsObject={componentsObject}
        isModelsDisabled={isModelsDisabled}
      />
    );
  }
  if (isArraySchema(schema)) {
    return (
      <ArrayTag
        schema={schema}
        path={path}
        onSelect={onSelect}
        typeOptions={typeOptions}
        onChange={onChange}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        rootSchema={rootSchema}
        componentsObject={componentsObject}
        isModelsDisabled={isModelsDisabled}
      />
    );
  }
  if (isMixedSchema(schema)) {
    return (
      <MixedTag
        schema={schema}
        path={path}
        onSelect={onSelect}
        typeOptions={typeOptions}
        onChange={onChange}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        rootSchema={rootSchema}
        componentsObject={componentsObject}
        isModelsDisabled={isModelsDisabled}
      />
    );
  }
  if (isSimpleSchema(schema))
    return (
      <SimpleTag
        schema={schema}
        path={path}
        onSelect={onSelect}
        typeOptions={typeOptions}
        onChange={onChange}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        rootSchema={rootSchema}
        componentsObject={componentsObject}
        isModelsDisabled={isModelsDisabled}
      />
    );
  if (isObjectSchema(schema))
    return (
      <ObjectTag
        schema={schema}
        path={path}
        onSelect={onSelect}
        typeOptions={typeOptions}
        onChange={onChange}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        rootSchema={rootSchema}
        componentsObject={componentsObject}
        isModelsDisabled={isModelsDisabled}
      />
    );
  if (isComposedSchema(schema)) {
    return (
      <ComposedTag
        schema={schema}
        path={path}
        onSelect={onSelect}
        typeOptions={typeOptions}
        onChange={onChange}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        rootSchema={rootSchema}
        componentsObject={componentsObject}
        isModelsDisabled={isModelsDisabled}
      />
    );
  }
}

function InnerArrayTag({
  path,
  schema,
  typeOptions,
  isDisabled,
  isReadOnly,
  onChange,
  rootSchema,
  componentsObject,
  isModelsDisabled,
}: TagProps<OASArraySchemaObject>) {
  if (isComposedSchema(schema)) {
    throw new Error(
      "Defined a composed type on the items of an array not on the array type itself"
    );
  }
  if (!schema.items) throw new Error("Array schema has no items");
  const childPath = path === "" ? "items" : path + ".items";
  return (
    <RootTag
      schema={schema.items}
      path={childPath}
      typeOptions={typeOptions}
      onChange={onChange}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      rootSchema={rootSchema}
      componentsObject={componentsObject}
      isModelsDisabled={isModelsDisabled}
    />
  );
}

function ArrayTag({
  path,
  schema,
  typeOptions,
  isDisabled,
  isReadOnly,
  onChange,
  rootSchema,
  componentsObject,
  isModelsDisabled,
}: TagProps<OASArraySchemaObject>) {
  const changeTypeValues = useChangeType(path, rootSchema, onChange);
  const innerTypeOptions = typeOptions.innerArrayTypeOptions;
  if (!innerTypeOptions) {
    throw new Error("ArrayTag has no innerArrayTypeOptions defined");
  }
  return (
    <SelectTypeModal
      open={changeTypeValues.isOpen}
      onOpenChange={changeTypeValues.onOpenChange}
      typeOptions={typeOptions}
      onSelect={changeTypeValues.onSelect}
      isDisabled={isDisabled}
      isModelsDisabled={isModelsDisabled}
    >
      <Tag
        onClick={changeTypeValues.onOpen}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        type="array"
        innerTag={
          <InnerArrayTag
            schema={schema}
            path={path}
            typeOptions={{
              ...innerTypeOptions,
              innerArrayTypeOptions: innerTypeOptions,
            }}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            onChange={onChange}
            rootSchema={rootSchema}
            componentsObject={componentsObject}
            isModelsDisabled={isModelsDisabled}
          />
        }
      >
        array
        {!isReadOnly && <ExpandButton isDisabled={isDisabled} />}
      </Tag>
    </SelectTypeModal>
  );
}

function ComposedTag({
  schema,
  onSelect,
  path,
  typeOptions,
  isDisabled,
  isReadOnly,
  rootSchema,
  onChange,
  isModelsDisabled,
}: TagProps<OASNonArraySchemaObject>) {
  const value = useMemo(() => {
    return findComposedKey(schema);
  }, [schema]);
  const changeTypeValues = useChangeType(path, rootSchema, onChange);
  const handleAdd = () => {
    const [newSchema] = addPlaceholderToPath(rootSchema, path);
    onChange(newSchema);
  };
  return (
    <SelectTypeModal
      open={changeTypeValues.isOpen}
      onOpenChange={changeTypeValues.onOpenChange}
      typeOptions={typeOptions}
      onSelect={onSelect || changeTypeValues.onSelect}
      isDisabled={isDisabled}
      isModelsDisabled={isModelsDisabled}
    >
      <Tag
        onClick={changeTypeValues.onOpen}
        onAdd={handleAdd}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        type={value}
      >
        <span>{value}</span>
        {!isReadOnly && <ExpandButton isDisabled={isDisabled} />}
      </Tag>
    </SelectTypeModal>
  );
}

function ObjectTag({
  path,
  onSelect,
  typeOptions,
  isDisabled,
  isReadOnly,
  rootSchema,
  onChange,
  isModelsDisabled,
}: TagProps<OASSchema>) {
  const [, dispatch] = useSchemaEditorContext();
  const changeTypeValues = useChangeType(path, rootSchema, onChange);

  const isAnyObject = useMemo(() => {
    const property = getPathProperty(rootSchema, path);
    if (!isObjectSchema(property)) return false;
    if (!property.properties) return true;
    return false;
  }, [path, rootSchema]);

  const handleAdd = () => {
    const property = getPathProperty(rootSchema, path);
    if (!isNonArraySchemaObject(property)) return;
    const existingKyes = Object.keys(property.properties || {});
    if (existingKyes.some((k) => k.startsWith("%%%"))) {
      toast.error("Use the existing placeholder");
    } else {
      const [newSchema, placeholderId] = addPlaceholderToPath(rootSchema, path);
      // we store the id of the placeholder so we can focus the element after rerender
      if (placeholderId) {
        dispatch({
          type: "SET_LATEST_ADDED_PLACEHOLDER",
          payload: placeholderId,
        });
      }
      onChange(newSchema);
    }
  };

  return (
    <SelectTypeModal
      open={changeTypeValues.isOpen}
      isDisabled={isDisabled || isReadOnly}
      onOpenChange={changeTypeValues.onOpenChange}
      typeOptions={typeOptions}
      onSelect={onSelect || changeTypeValues.onSelect}
      isModelsDisabled={isModelsDisabled}
    >
      <Tag
        onClick={changeTypeValues.onOpen}
        onAdd={handleAdd}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        type="object"
      >
        <span>{isAnyObject ? "AnyObject" : "object"}</span>
        {!isReadOnly && <ExpandButton isDisabled={isDisabled} />}
      </Tag>
    </SelectTypeModal>
  );
}

function SimpleTag({
  schema,
  path,
  onSelect,
  typeOptions,
  isDisabled,
  onChange,
  rootSchema,
  isModelsDisabled,
  isReadOnly,
}: TagProps<OASNonArraySchemaObject>) {
  const changeTypeValues = useChangeType(path, rootSchema, onChange);
  if (typeof schema.type !== "string")
    throw new Error("Schema type is not of type string");

  const readableName = useMemo(() => {
    if (schema.type === "string" && schema.enum?.length) {
      return (
        <Tooltip>
          <TooltipTrigger>{`enum(${schema.enum
            .slice(0, 2)
            .map((e) => `"${e}"`)
            .join(
              " | "
            )}${schema.enum.length > 2 ? " | +" + String(schema.enum.length - 2) + " more" : ""})`}</TooltipTrigger>
          <TooltipContent>
            String enum values:
            {schema.enum.map((e) => `"${e}"`).join(" | ")}
          </TooltipContent>
        </Tooltip>
      );
    }
    return schema.type;
  }, [schema.type, schema.enum]);

  return (
    <SelectTypeModal
      open={changeTypeValues.isOpen}
      typeOptions={typeOptions}
      onOpenChange={changeTypeValues.onOpenChange}
      onSelect={onSelect || changeTypeValues.onSelect}
      isDisabled={isDisabled || isReadOnly}
      isModelsDisabled={isModelsDisabled}
    >
      <Tag
        onClick={changeTypeValues.onOpen}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        type={schema.type as TagTypes}
      >
        <span>{readableName}</span>
        {!isReadOnly && <ExpandButton isDisabled={isDisabled} />}
      </Tag>
    </SelectTypeModal>
  );
}

const Tag = forwardRef<
  HTMLDivElement,
  PropsWithChildren<
    ComponentProps<"div"> & {
      onAdd?: () => unknown;
      isDisabled: boolean;
      isReadOnly: boolean;
      type: TagTypes;
      innerTag?: ReactElement;
    }
  >
>(
  (
    {
      children,
      className,
      onAdd,
      isDisabled,
      isReadOnly,
      type,
      innerTag,
      ...rest
    },
    ref
  ) => {
    const fontColor = {
      "text-green-300": type === "string",
      "text-cyan-300": type === "object",
      "text-green-500": type === "oneOf",
      "text-teal-500": type === "anyOf",
      "text-indigo-500": type === "allOf",
      "text-yellow-400": type === "array",
      "text-orange-400": type === "integer",
      "text-pink-300": type === "number",
      "text-emerald-500": type === "boolean",
      "text-violet-300": type === "mixed",
      "text-red-300": type === "$ref",
      "text-blue-600": type === "null",
    };
    return (
      <div className="inline-flex items-center">
        <div
          ref={ref}
          className={cn(
            className,
            "inline-flex items-center text-muted-foreground gap-[1px] rounded-md cursor-default",
            {
              "hover:bg-gray-800": !isDisabled,
              "cursor-default": isDisabled,
              "hover:underline cursor-pointer": !isDisabled && !isReadOnly,
            },
            !isDisabled && fontColor
          )}
          {...rest}
        >
          {children} {innerTag && "["}
        </div>
        {innerTag && innerTag}
        {innerTag && <span className={cn(fontColor)}>{"]"}</span>}
        {onAdd && !isDisabled && !isReadOnly && (
          <AddButton onClick={onAdd} type="button" />
        )}
      </div>
    );
  }
);

function MixedTag({
  schema,
  path,
  onSelect,
  typeOptions,
  isDisabled,
  isReadOnly,
  rootSchema,
  onChange,
  isModelsDisabled,
}: TagProps<OASMixedSchemaObject>) {
  const value = useMemo(() => {
    return getMixedTagStringValue(schema);
  }, [schema]);
  const changeTypeValues = useChangeType(path, rootSchema, onChange);

  return (
    <SelectTypeModal
      open={changeTypeValues.isOpen}
      typeOptions={typeOptions}
      onOpenChange={changeTypeValues.onOpenChange}
      onSelect={onSelect || changeTypeValues.onSelect}
      isDisabled={isDisabled || isReadOnly}
      isModelsDisabled={isModelsDisabled}
    >
      <Tag
        onClick={changeTypeValues.onOpen}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        type="mixed"
      >
        <span>{value}</span>
        {!isReadOnly && <ExpandButton isDisabled={isDisabled} />}
      </Tag>
    </SelectTypeModal>
  );
}

function ReferenceTag({
  schema,
  path,
  rootSchema,
  onChange,
  typeOptions,
  onSelect,
  isDisabled,
  isReadOnly,
  isModelsDisabled,
}: TagProps<OASReferenceObject>) {
  const name = deref(schema.$ref);
  const changeTypeValues = useChangeType(path, rootSchema, onChange);
  return (
    <SelectTypeModal
      open={changeTypeValues.isOpen}
      typeOptions={typeOptions}
      onOpenChange={changeTypeValues.onOpenChange}
      onSelect={onSelect || changeTypeValues.onSelect}
      isDisabled={isDisabled || isReadOnly}
      isModelsDisabled={isModelsDisabled}
    >
      <Tag
        onClick={changeTypeValues.onOpen}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        type="$ref"
      >
        <span>{name}</span>
        {!isReadOnly && <ExpandButton isDisabled={isDisabled} />}
      </Tag>
    </SelectTypeModal>
  );
}

const PropertyNameInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        "flex placeholder:text-muted-foreground bg-background w-20 focus:ring-0 outline-0 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});

type PropertyNameFormValues = {
  propertyName: string;
};

function RowPropertyName({
  row,
  onChange,
  handleRemove,
  isReadOnly,
  isDisabled,
}: {
  row: PickRequired<OASSchemaRow, "propertyName">;
  onChange: OnSchemaChange;
  isReadOnly: boolean;
  isDisabled: boolean;
  handleRemove: (skipToast?: boolean) => unknown;
}) {
  const [isManuallyEditing, setIsManuallyEditing] = useState(() => {
    if (row.propertyName?.startsWith("%%%")) return true;
    return false;
  });
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [, dispatch] = useSchemaEditorContext();

  const { register, handleSubmit } = useForm<PropertyNameFormValues>({
    defaultValues: {
      propertyName:
        row.propertyName &&
        !row.propertyName.startsWith(globalVariables.editorPlaceholderToken)
          ? row.propertyName
          : "",
    },
  });

  const onSubmit = (data: PropertyNameFormValues) => {
    try {
      if (data.propertyName === "") return handleRemove(true);
      let newSchema = changePropertyName(
        row.rootSchema,
        row.path,
        row.propertyName,
        data.propertyName
      );
      newSchema = addRequiredToProperty(
        newSchema,
        moveUpLevel(row.path) + `.${data.propertyName}`
      );
      setIsManuallyEditing(false);
      const { schema, placeholderId } = addPlaceholderToSchema({
        schema: newSchema,
        path: moveUpLevel(moveUpLevel(row.path)),
      });
      newSchema = schema;
      if (placeholderId) {
        dispatch({
          type: "SET_LATEST_ADDED_PLACEHOLDER",
          payload: placeholderId,
        });
      }
      onChange(newSchema);
    } catch (err) {
      toastError(err);
    }
  };

  const handleEditButtonClick = () => {
    setIsManuallyEditing(true);
    if (!inputRef.current) return;
    inputRef.current.focus();
  };

  const onError = (errors: FieldErrors<PropertyNameFormValues>) => {
    for (const [, key] of Object.entries(errors)) {
      toast.error(key.message);
    }
  };

  const handleOnBlur = () => {
    if (!submitButtonRef.current) return;
    submitButtonRef.current.click();
  };

  const { ref, ...registerValues } = register("propertyName", {
    pattern: {
      value: appRegex.propertyName.expression,
      message: appRegex.propertyName.message,
    },
    validate: (value: string) => {
      // Cannot have same property name as existing key
      const parentProperty = getPathProperty(
        row.rootSchema,
        moveUpLevel(row.path)
      );
      const parentKeys = Object.keys(parentProperty).filter(
        (e) => e != (row.propertyName || "")
      );
      if (includes(parentKeys, value))
        return "This property name already exists";
      // Cannot have spaces or special characters
      const schema = z.string();
      try {
        schema.parse(value);
      } catch (err) {
        if (err instanceof ZodError) {
          return err.issues[0].message;
        }
      }
      return true;
    },
  });

  const curriedSubmit = handleSubmit(onSubmit, onError);
  const showInput = isManuallyEditing;
  const showTextPropertyName = row.propertyName && !isManuallyEditing;
  return (
    <>
      <span
        aria-hidden={!showInput}
        className={cn("inline-flex", "sr-only", {
          "not-sr-only": showInput,
        })}
      >
        <div
          role="form"
          onKeyDown={(e) => {
            if (
              e.currentTarget.contains(document.activeElement) &&
              e.code === "Enter"
            ) {
              e.preventDefault();
              e.stopPropagation();
              curriedSubmit();
            }
          }}
        >
          <PropertyNameInput
            id={"id-" + row.propertyName.slice(3)}
            autoComplete="off"
            placeholder="name"
            {...registerValues}
            ref={(e) => {
              ref(e);
              (inputRef as any).current = e;
            }}
            onBlur={handleOnBlur}
          />
          <button
            type="button"
            onClick={() => {
              curriedSubmit();
            }}
            className="sr-only"
            ref={submitButtonRef}
          >
            Submit
          </button>
        </div>
        :&nbsp;&nbsp;&nbsp;
      </span>
      <div
        aria-hidden={!showTextPropertyName}
        className={cn("flex gap-1", "sr-only", {
          "not-sr-only": showTextPropertyName,
        })}
      >
        <p
          className={cn(" text-gray-100 text-nowrap", {
            "text-muted-foreground": isDisabled,
          })}
        >
          {row.propertyName}
        </p>
        {!isReadOnly && !isDisabled && (
          <Tooltip>
            <TooltipTrigger asChild>
              <EditorIconButton
                variant="secondary"
                onClick={handleEditButtonClick}
              >
                <NormIcons.Edit size={ICON_SIZE} />
                <span className="sr-only">Edit property name</span>
              </EditorIconButton>
            </TooltipTrigger>
            <TooltipContent>Edit property name</TooltipContent>
          </Tooltip>
        )}
        <span>:&nbsp;</span>
      </div>
    </>
  );
}

function Pad({
  level,
  padProperty,
}: {
  level: number;
  padProperty: PadProperty;
}) {
  const ROOT_PAD_AMOUNT = 12;
  const shouldPad = level > 0;
  const pad = level * PAD_AMOUNT;
  const parentPad = Math.max((level - 1) * PAD_AMOUNT, ROOT_PAD_AMOUNT);
  return (
    <div
      className={cn("relative self-stretch")}
      style={{ flexBasis: `${pad}px`, flexShrink: "0", flexGrow: "0" }}
    >
      {shouldPad ? (
        <>
          {level > 1 && padProperty !== "last" && (
            <span className="absolute left-[11px] h-full w-[1px] bg-border -top-1/2" />
          )}
          <span
            className="h-[1px] bg-border right-0 top-1/2 inline-block absolute pr-1"
            style={{ width: `${pad - parentPad}px` }}
          />
          <span
            className="h-full w-[1px] bg-border inline-block absolute top-[-52%]"
            style={{ right: `${pad - parentPad}px` }}
          />
        </>
      ) : null}
    </div>
  );
}

function Row({
  row,
  onChange,
  onRemoveRootSchema,
  isRootRow,
  componentsObject,
  disableRootSchemaRemove,
  isModelsDisabled,
  isReadOnly,
}: {
  row: OASSchemaRow;
  isReadOnly: boolean;
  onChange: SchemaEditorProps["onChange"];
  onRemoveRootSchema: OnRemoveRootSchema | undefined;
  disableRootSchemaRemove?: boolean;
  isRootRow: boolean;
  componentsObject: OASComponentsObject;
  isModelsDisabled: boolean;
}) {
  const isRootSchema = row.path === "";

  const handleRemove = useCallback(
    (skipToast?: boolean) => {
      if (isRootSchema) {
        onRemoveRootSchema?.();
      } else {
        const newSchema = removeRequiredForProperty(
          removePathFromSchema(row.rootSchema, row.path),
          row.path
        );
        onChange(newSchema);
      }
      if (!skipToast) {
        toast.success("Property removed");
      }
    },
    [onChange, onRemoveRootSchema, row.path, row.rootSchema, isRootSchema]
  );

  const isDisabled = row.isDisabled;
  const isPlaceholder = row.propertyName?.startsWith("%%%");

  const showRequiredToggle =
    !row.isRequired &&
    !row.hideRequiredToggle &&
    !isDisabled &&
    !isPlaceholder &&
    !isReadOnly;
  const showRequiredTag =
    row.isRequired && !row.hideRequiredToggle && !isDisabled && !isReadOnly;
  const showRemoveRootRowButton =
    isRootRow &&
    onRemoveRootSchema &&
    !disableRootSchemaRemove &&
    !isReadOnly &&
    !isDisabled;

  return (
    <div className="flex items-center gap-1">
      <Pad level={row.level} padProperty={row.padProperty} />
      <div className="flex">
        {row.propertyName && (
          <RowPropertyName
            row={{ ...row, propertyName: row.propertyName }}
            onChange={onChange}
            handleRemove={handleRemove}
            isReadOnly={isReadOnly}
            isDisabled={isDisabled}
          />
        )}
        {renderTag(row.rowType, {
          schema: row.schema,
          isDisabled: row.isDisabled,
          isReadOnly: row.isReadOnly,
          path: row.path,
          typeOptions: row.typeOptions,
          onChange,
          rootSchema: row.rootSchema,
          componentsObject: componentsObject,
          isModelsDisabled,
        })}
      </div>
      {showRequiredToggle && <RequiredToggle row={row} onChange={onChange} />}
      {showRequiredTag && !isReadOnly && (
        <RequiredTag row={row} onChange={onChange} />
      )}
      <SchemaEditorAdvancedTypeDialog onChange={onChange} schemaRow={row}>
        <Button size="icon-sm" variant="secondary">
          <Ellipsis size={DEFAULT_ICON_SIZE_SM} />
        </Button>
      </SchemaEditorAdvancedTypeDialog>
      {row.isRemovable && !isDisabled && !isReadOnly && (
        <Tooltip>
          <TooltipTrigger asChild>
            <RemoveButton onClick={() => handleRemove()}>
              <span className="sr-only">Delete property</span>
            </RemoveButton>
          </TooltipTrigger>
          <TooltipContent>Delete property</TooltipContent>
        </Tooltip>
      )}
      {showRemoveRootRowButton && (
        <Tooltip>
          <TooltipTrigger asChild>
            <RemoveButton onClick={onRemoveRootSchema}>
              <span className="sr-only">Delete root property</span>
            </RemoveButton>
          </TooltipTrigger>
          <TooltipContent>Remove root</TooltipContent>
        </Tooltip>
      )}
    </div>
  );
}

export type OnRemoveRootSchema = () => unknown;

interface SchemaEditorProps {
  value: OASSchema;
  onChange: OnSchemaChange;
  isDisabled?: boolean; // editor should be grayed out and signal that this shema cannot be edited
  isReadOnly?: boolean; // editor should be locked but can be unlocked
  isModelsDisabled?: boolean;
  title?: string;
  description?: string;
  initialState?: Partial<State>;
  // This cannot be optional (!)
  // When the schema root points to a Model and the Model is deleted
  // We try to automatically clean this up. This cleanup will be stuck in a
  // Loop if we can't clean this up correctly
  onRemoveRootSchema: OnRemoveRootSchema;
  componentsObject: OASComponentsObject;
  disableRootSchemaRemove?: boolean;
  allowTopLevelReferences: boolean;
}

function SchemaEditorWrapper({
  value: schema,
  onChange,
  isDisabled = false,
  isReadOnly = false,
  onRemoveRootSchema,
  componentsObject,
  disableRootSchemaRemove,
  allowTopLevelReferences,
  isModelsDisabled,
}: SchemaEditorProps) {
  isReadOnly = !!isReadOnly;
  isDisabled = !!isDisabled;
  const [state, dispatch] = useSchemaEditorContext();

  const rows = useMemo(() => {
    const rows = generateOASSchemaRows({
      schema,
      isDisabled: isDisabled,
      isReadOnly: isReadOnly,
      initialLevel: 0,
      componentsObject: componentsObject,
      options: {
        allowTopLevelReferences,
      },
    });
    return rows;
  }, [
    schema,
    isDisabled,
    componentsObject,
    allowTopLevelReferences,
    isReadOnly,
  ]);

  // If we add a placeholder to the editor, we want to focus
  // the input after it is added to the document
  useEffect(() => {
    if (!state.latestPlaceholderId) return;
    const input = document.querySelector<HTMLInputElement>(
      `input[id=${"id-" + state.latestPlaceholderId.slice(3)}]`
    );
    if (input == null || document.activeElement === input) return;
    input.focus();
    dispatch({
      type: "SET_LATEST_ADDED_PLACEHOLDER",
      payload: "",
    });
  }, [state.latestPlaceholderId, dispatch]);

  return (
    <div className="text-sm flex flex-col items-stretch gap-0.5">
      {rows.map((r, i) => (
        <Row
          key={`${r.level}-${r.propertyName}-${r.path}`}
          row={r}
          isReadOnly={isReadOnly}
          onChange={onChange}
          onRemoveRootSchema={onRemoveRootSchema}
          disableRootSchemaRemove={disableRootSchemaRemove}
          isRootRow={i === 0}
          componentsObject={componentsObject}
          isModelsDisabled={!!isModelsDisabled}
        />
      ))}
    </div>
  );
}

export function SchemaEditor({
  allowTopLevelReferences = false,
  ...props
}: SchemaEditorProps) {
  return (
    <SchemaEditorContextProvider initialState={props.initialState}>
      <SchemaEditorWrapper
        {...props}
        allowTopLevelReferences={allowTopLevelReferences}
      />
    </SchemaEditorContextProvider>
  );
}
