import { Button } from "@/components/_shadui/button";
import {
  cn,
  DEFAULT_ICON_SIZE,
  DEFAULT_ICON_SIZE_SM,
  NormIcons,
} from "@/lib/utils";
import { MoveRight } from "lucide-react";
import React, { ComponentProps, forwardRef } from "react";

export function AddButton({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <Button size="sm" variant="link" {...props}>
      <span>{children}</span>{" "}
      <NormIcons.Add size={DEFAULT_ICON_SIZE} className="ml-2" />
    </Button>
  );
}

export const EditButton = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  return (
    <Button size="icon-sm" variant={"primary"} {...props} ref={ref}>
      <span className="sr-only">{children}</span>
      <NormIcons.Edit size={DEFAULT_ICON_SIZE} />
    </Button>
  );
});

export const CopyButton = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  return (
    <Button size="icon-sm" variant="ghost" {...props} ref={ref}>
      <span className="sr-only">{children}</span>
      <NormIcons.Copy size={DEFAULT_ICON_SIZE} />
    </Button>
  );
});

export const LinkButton = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  return (
    <Button size="icon-sm" variant="secondary" ref={ref} {...props}>
      <NormIcons.Link size={DEFAULT_ICON_SIZE_SM} />
      {children}
    </Button>
  );
});

export const ComponentButton = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  return (
    <Button size="icon-sm" variant="secondary" ref={ref} {...props}>
      <NormIcons.Component size={DEFAULT_ICON_SIZE} />
      {children}
    </Button>
  );
});

export const DeleteButton = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  return (
    <Button size="icon-sm" variant={"destructive"} {...props} ref={ref}>
      <span className="sr-only">{children}</span>
      <NormIcons.Delete size={DEFAULT_ICON_SIZE} />
    </Button>
  );
});

export const AddLabelButton = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children: _, ...props }, ref) => {
  return (
    <Button
      size="xs"
      variant="secondary"
      className="font-mono"
      {...props}
      ref={ref}
    >
      Add new
    </Button>
  );
});

export const MinimizeButton = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  return (
    <Button size="icon-sm" variant="outline" {...props} ref={ref}>
      <span className="sr-only">{children}</span>
      <NormIcons.Minimize size={DEFAULT_ICON_SIZE} />
    </Button>
  );
});

export const SubmitButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<"button"> & { isLoading?: boolean }
>(({ children, isLoading, disabled, ...props }, ref) => {
  return (
    <Button
      size="sm"
      variant="primary"
      type="submit"
      disabled={disabled || isLoading}
      {...props}
      ref={ref}
    >
      {isLoading ? (
        "Loading"
      ) : (
        <>
          <span>{children}</span>
          <MoveRight className="ml-2" />
        </>
      )}
    </Button>
  );
});

export function BtnGroup({
  children,
  className,
  ...rest
}: ComponentProps<"div">) {
  return (
    <div className={cn("flex gap-2", className)} {...rest}>
      {children}
    </div>
  );
}
