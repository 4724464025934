import { Button } from "@/components/_shadui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/_shadui/command";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/_shadui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/_shadui/select";
import { Textarea } from "@/components/_shadui/textarea";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { FormLabelRequired } from "@/components/form-label-required";
import { ActionBarContent } from "@/components/module-action-bar/action-bar-content";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { useDisclosure } from "@/hooks/use-disclosure";
import { useOperation } from "@/hooks/use-operation";
import { useTags } from "@/hooks/use-tags";
import { supportedHttpVerbs } from "@/lib/helpers";
import { URL_PATH_REGEX } from "@/lib/regex";
import {
  OASDefinition,
  OASOperation,
  OASParameter,
  SupportedHTTPVerbs,
} from "@/lib/types";
import {
  getParametersByType,
  getParametersFromUrlPath,
  INVALID_PATH_MESSAGE,
} from "@/lib/url-path";
import {
  cn,
  DEFAULT_ICON_SIZE,
  NormIcons,
  toastError,
  truncateText,
} from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { camelCase } from "lodash";
import merge from "lodash/merge";
import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "./elements";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { isReference } from "@/lib/oas-tools/oas-tag-helpers";

const pathInputFormSchema = z.object({
  urlPath: z
    .string()
    .min(1, "Required")
    .max(500)
    .regex(URL_PATH_REGEX, INVALID_PATH_MESSAGE),
  description: z.string().optional(),
  summary: z.string().min(1, "Required").max(50),
  method: z.enum(supportedHttpVerbs, { message: "Select method" }),
  tag: z.string().min(1, "Select or create a tag"),
});
export type OperationFormValues = z.infer<typeof pathInputFormSchema>;

export function OperationForm({
  defaultValues = {},
  onSubmit,
  title,
  btnTitle,
  value,
  onChange,
}: {
  defaultValues?: Partial<OperationFormValues>;
  onSubmit: SubmitHandler<OperationFormValues>;
  title: string;
  btnTitle: string;
} & EditorInputProps) {
  const { getAll, get, create } = useTags({ value, onChange });

  const commandDisclosure = useDisclosure();

  const allTags = useMemo(() => getAll(), [getAll]);

  const form = useForm<OperationFormValues>({
    resolver: zodResolver(pathInputFormSchema),
    defaultValues: merge(
      {
        urlPath: "",
        method: "",
        operationId: "",
        summary: "",
        tag: "",
      },
      defaultValues
    ),
  });

  const onCreateTag = (tagName: string) => {
    const existing = get(tagName);
    if (existing) toast.error(`Tag with the name or ${tagName} already exists`);
    try {
      create({ name: tagName });
      form.setValue("tag", tagName.toLowerCase());
      commandDisclosure.onClose();
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  const [query, setQuery] = useState("");

  const onSubmitWrapper: SubmitHandler<OperationFormValues> = (values) => {
    onSubmit({ ...values });
  };

  return (
    <Form {...form}>
      <ActionBarForm onSubmit={form.handleSubmit(onSubmitWrapper)}>
        <ActionBarFormTitle>{title}</ActionBarFormTitle>
        <ActionBarFieldGroup>
          <div className="flex gap-4">
            <FormField
              control={form.control}
              name="method"
              render={({ field }) => {
                return (
                  <FormItem className="grow">
                    <FormLabel>
                      <FormLabelRequired />
                      Method
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="w-[180px]">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {supportedHttpVerbs.map((v) => (
                          <SelectItem key={v} value={v}>
                            {v.toUpperCase()}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="tag"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Tag
                  </FormLabel>
                  <Popover
                    open={commandDisclosure.isOpen}
                    onOpenChange={commandDisclosure.onOpenChange}
                  >
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "w-[200px] justify-between flex",
                            !field.value && "text-muted-foreground"
                          )}
                        >
                          {field.value
                            ? allTags.find((tag) => {
                                return tag.name === field.value;
                              })?.name || "Tag not found"
                            : "Select tag"}
                          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-[200px] p-0">
                      <Command>
                        <CommandInput
                          placeholder="Search tag..."
                          className="h-9"
                          value={query}
                          onValueChange={(v) => setQuery(v)}
                        />
                        <CommandList>
                          {!query && (
                            <CommandEmpty className="py-3 text-muted-foreground text-xs text-center">
                              Type to create a tag
                            </CommandEmpty>
                          )}
                          <CommandGroup>
                            {allTags.map((tag) => (
                              <CommandItem
                                value={tag.name}
                                key={tag.name}
                                onSelect={() => {
                                  form.setValue("tag", tag.name);
                                  commandDisclosure.onClose();
                                }}
                              >
                                {tag.name}
                                <CheckIcon
                                  className={cn(
                                    "ml-auto h-4 w-4",
                                    tag.name === field.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                              </CommandItem>
                            ))}
                          </CommandGroup>
                          {query &&
                            !allTags.some(
                              (t) => t.name === query.toLowerCase()
                            ) && (
                              <div className="flex justify-end py-1 px-1">
                                <Button
                                  size="xs"
                                  className="w-full mt-1 mx-1"
                                  variant="primary"
                                  onClick={() => onCreateTag(query)}
                                >
                                  <NormIcons.Add
                                    size={DEFAULT_ICON_SIZE}
                                    className="mr-2"
                                  />
                                  {`Create tag "${truncateText(query, 10)}"`}
                                </Button>
                              </div>
                            )}
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="urlPath"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Path name
                </FormLabel>
                <FormControl>
                  <Input placeholder="/cats/{cat_id}/foods" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="summary"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Summary
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormDescription>
                  Use a short description like "Get book" or "Create author"
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </ActionBarFieldGroup>
        <BtnGroup className="justify-end">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </ActionBarForm>
    </Form>
  );
}

export function OperationAdd({ value, onChange }: EditorInputProps) {
  const [, dispatch] = useActionBarContext();
  const { addOperationAndParameters } = useOperation({
    value,
    onChange,
  });
  const { setActiveElement } = useAPIEditorTools({ value, onChange });

  const onSubmit = ({ method, urlPath, summary, tag }: OperationFormValues) => {
    const operationId = camelCase(summary);

    const operation: OASOperation = {
      operationId,
      tags: tag ? [tag] : undefined,
      summary,
    };

    try {
      addOperationAndParameters({ urlPath, method, operation });
      dispatch({
        type: "SET_PAGE",
        payload: {
          name: "suggested-responses",
          context: {
            urlPath,
            method,
            operationId,
          },
        },
      });
      setActiveElement({
        type: "operation",
        operationId: operationId,
      });
    } catch (err) {
      toastError(err);
    }
  };
  return (
    <ActionBarContent>
      <OperationForm
        title="Create operation"
        btnTitle="Create"
        onSubmit={onSubmit}
        value={value}
        onChange={onChange}
      />
    </ActionBarContent>
  );
}

// The intent here is to keep parameters that are NOT references but
// STILL exist in the urlPath
function mergeParameters({
  newUrlPath,
  operation,
  definition,
}: {
  operation: OASOperation;
  newUrlPath: string;
  definition: OASDefinition;
}) {
  const res: OASParameter[] = [
    ...getParametersByType(operation, "query", definition),
  ];

  const existingPathParameters = getParametersByType(
    operation,
    "path",
    definition
  );

  const pathParams = getParametersFromUrlPath(newUrlPath);

  existingPathParameters.forEach((existingPathParam) => {
    if (isReference(existingPathParam)) return;
    if (
      pathParams.some((pathParam) => pathParam.name === existingPathParam.name)
    ) {
      res.push(existingPathParam);
    }
  });

  return res;
}

export function OperationEdit({
  urlPath: oldUrlPath,
  method: oldMethod,
  value,
  onChange,
}: {
  urlPath: string;
  method: SupportedHTTPVerbs;
} & EditorInputProps) {
  const [, dispatch] = useActionBarContext();
  const { setActiveElement } = useAPIEditorTools({ value, onChange });
  const { editOperationPathAndMethod, getOperation } = useOperation({
    value,
    onChange,
  });
  const operation = getOperation(oldUrlPath, oldMethod);
  if (!operation) throw new Error("Trying to edit non-existant operation");

  const onSubmit = ({
    method,
    urlPath,
    description,
    summary,
    tag,
  }: OperationFormValues) => {
    if (urlPath.endsWith("/")) urlPath = urlPath.slice(0, -1);
    try {
      const operationId = camelCase(summary);
      editOperationPathAndMethod({
        oldUrlPath,
        oldMethod,
        newUrlPath: urlPath,
        newMethod: method,
        operation: {
          ...operation,
          parameters: mergeParameters({
            newUrlPath: urlPath,
            operation,
            definition: value.data,
          }),
          operationId,
          description,
          summary,
          tags: tag ? [tag] : undefined,
        },
      });
      setActiveElement({ type: "operation", operationId });
      dispatch({
        type: "CLOSE",
      });
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };
  return (
    <ActionBarContent>
      <OperationForm
        onSubmit={onSubmit}
        defaultValues={{
          urlPath: oldUrlPath,
          method: oldMethod,
          description: operation.description,
          summary: operation.summary,
          tag: operation?.tags?.length ? operation.tags[0] : undefined,
        }}
        title="Edit operation"
        btnTitle="Edit"
        value={value}
        onChange={onChange}
      />
    </ActionBarContent>
  );
}
