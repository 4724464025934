import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import { Textarea } from "@/components/_shadui/textarea";
import { FormLabelRequired } from "@/components/form-label-required";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { zodResolver } from "@hookform/resolvers/zod";
import merge from "lodash/merge";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ActionBarContent } from "./action-bar-content";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "./elements";

const formValues = z.object({
  title: z.string().min(1, "Required"),
  description: z.string().min(1, "Required"),
});
export type MetadataFormValues = z.infer<typeof formValues>;

export function MetadataForm({
  defaultValues,
  onSubmit,
  title,
  btnTitle,
}: {
  defaultValues: Partial<MetadataFormValues>;
  onSubmit: (values: MetadataFormValues) => unknown;
  title: string;
  btnTitle: "Create" | "Edit";
}) {
  const form = useForm<MetadataFormValues>({
    resolver: zodResolver(formValues),
    defaultValues: merge(
      {
        title: "",
        description: "",
      },
      defaultValues
    ),
  });

  return (
    <ActionBarContent>
      <Form {...form}>
        <ActionBarForm
          onSubmit={(e) => {
            form.handleSubmit(onSubmit)(e);
          }}
        >
          <ActionBarFormTitle>{title}</ActionBarFormTitle>
          <ActionBarFieldGroup>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Title
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex gap-2 items-center">
                    <FormLabelRequired />
                    Description
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </ActionBarFieldGroup>
          <BtnGroup className="justify-end">
            <SubmitButton>{btnTitle}</SubmitButton>
          </BtnGroup>
        </ActionBarForm>
      </Form>
    </ActionBarContent>
  );
}
