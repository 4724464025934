import { findOperationByIdOrErrorFromDefinition } from "@/lib/editor-mutations/oas-operations";
import { NoRefsHereError } from "@/lib/errors";
import { isReference } from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASDefinition,
  OASRequestBodyObject,
  SupportedContentFormats,
} from "@/lib/types";

export function getRequestBodyFromDefinition({
  operationId,
  definition,
}: {
  operationId: string;
  definition: OASDefinition;
}) {
  const operation = findOperationByIdOrErrorFromDefinition(
    definition,
    operationId
  );
  return operation.requestBody;
}

export function getRequestBodyOrErrorFromDefinition({
  definition,
  operationId,
}: {
  definition: OASDefinition;
  operationId: string;
}) {
  const found = getRequestBodyFromDefinition({ definition, operationId });
  if (!found) throw new Error("RequestBody not found");
  return found;
}

export function getRequestBodySchemaOrErrorFromDefinition({
  operationId,
  definition,
  format,
}: {
  operationId: string;
  definition: OASDefinition;
  format: SupportedContentFormats;
}) {
  const requestBody = getRequestBodyOrErrorFromDefinition({
    definition,
    operationId,
  });

  if (isReference(requestBody)) throw new NoRefsHereError();

  const schema = requestBody.content?.[format].schema;
  if (!schema) throw new Error("RequestBody schema not found");

  return schema;
}

export function addRequestBodyToDefinition({
  operationId,
  requestBody,
  definition,
}: {
  operationId: string;
  requestBody: OASRequestBodyObject;
  definition: OASDefinition;
}) {
  const cp = structuredClone(definition);
  const operation = findOperationByIdOrErrorFromDefinition(cp, operationId);
  operation.requestBody = requestBody;
  return cp;
}

export function removeRequestBodyFromDefinition({
  operationId,
  definition,
}: {
  operationId: string;
  definition: OASDefinition;
}) {
  const cp = structuredClone(definition);
  const operation = findOperationByIdOrErrorFromDefinition(cp, operationId);
  delete operation.requestBody;
  return cp;
}
