import { OASDefinition } from "@/lib/types";
import { typedIncludes } from "@/lib/utils";

export function capitalize(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

// Order of the list is important for display
export const supportedHttpVerbs = [
  "get",
  "post",
  "put",
  "patch",
  "delete",
  "options",
  "head",
  "trace",
] as const;
export type SupportedHttpVerbs = (typeof supportedHttpVerbs)[number];

export const defaultResponseCodeMap: Record<SupportedHttpVerbs, HttpStatus> = {
  get: "200",
  post: "201",
  put: "200",
  patch: "200",
  delete: "204",
  options: "204",
  head: "200",
  trace: "200",
};

export const supportedHttpStatusCodes = [
  "100",
  "200",
  "201",
  "202",
  "203",
  "204",
  "206",
  "207",
  "208",
  "226",
  "300",
  "301",
  "302",
  "303",
  "304",
  "305",
  "306",
  "307",
  "308",
  "400",
  "401",
  "402",
  "403",
  "404",
  "405",
  "406",
  "407",
  "408",
  "409",
  "410",
  "411",
  "412",
  "413",
  "414",
  "416",
  "417",
  "418",
  "420",
  "422",
  "423",
  "424",
  "425",
  "426",
  "429",
  "431",
  "444",
  "450",
  "451",
  "494",
  "500",
  "501",
  "502",
  "503",
  "504",
  "506",
  "507",
  "508",
  "509",
  "510",
  "default",
] as const;
export type HttpStatus = (typeof supportedHttpStatusCodes)[number];

export const httpStatusMap = {
  "100": { message: "Continue", color: "yellow" },
  "200": { message: "Ok", color: "yellow" },
  "201": { message: "Created", color: "yellow" },
  "202": { message: "Accepted", color: "yellow" },
  "203": { message: "Non-Authoritative Information", color: "yellow" },
  "204": { message: "No Content", color: "yellow" },
  "206": { message: "Partial Content", color: "yellow" },
  "207": { message: "Multi-Status", color: "yellow" },
  "208": { message: "Already Reported", color: "yellow" },
  "226": { message: "IM Used", color: "yellow" },
  "300": { message: "Multiple Choices", color: "yellow" },
  "301": { message: "Moved Permanently", color: "yellow" },
  "302": { message: "Found", color: "yellow" },
  "303": { message: "See Other", color: "yellow" },
  "304": { message: "Not Modified", color: "yellow" },
  "305": { message: "Use Proxy", color: "yellow" },
  "306": { message: "Swith Proxy", color: "yellow" },
  "307": { message: "Temporary Redirect", color: "yellow" },
  "308": { message: "Permanent Redirect", color: "yellow" },
  "400": { message: "Bad Request", color: "yellow" },
  "401": { message: "Unauthorized", color: "yellow" },
  "402": { message: "Payment Required", color: "yellow" },
  "403": { message: "Forbidden", color: "yellow" },
  "404": { message: "Not Found", color: "yellow" },
  "405": { message: "Method Not Allowed", color: "yellow" },
  "406": { message: "Not Acceptable", color: "yellow" },
  "407": { message: "Proxy Authentication Required", color: "yellow" },
  "408": { message: "Requrest Timeout", color: "yellow" },
  "409": { message: "Conflict", color: "yellow" },
  "410": { message: "Gone", color: "yellow" },
  "411": { message: "Length Required", color: "yellow" },
  "412": { message: "Precondidation Failed", color: "yellow" },
  "413": { message: "Request Entity Too Large", color: "yellow" },
  "414": { message: "Request-URI Too Long", color: "yellow" },
  "416": { message: "Requested Range Not Satisfiable", color: "yellow" },
  "417": { message: "Expectation Failed", color: "yellow" },
  "418": { message: "I'm a teapot", color: "yellow" },
  "420": { message: "Enhance Your Calm", color: "yellow" },
  "422": { message: "Unprocessable Entity", color: "yellow" },
  "423": { message: "Locked", color: "yellow" },
  "424": { message: "Failed Dependency", color: "yellow" },
  "425": { message: "Unordered Collection", color: "yellow" },
  "426": { message: "Upgrade Required", color: "yellow" },
  "429": { message: "Too Many Requests", color: "yellow" },
  "431": { message: "Request Header Fields Too Large", color: "yellow" },
  "444": { message: "No Response", color: "yellow" },
  "450": { message: "Blocked by Windows Parental Controls", color: "yellow" },
  "451": { message: "Unavailable For Legal Reasons", color: "yellow" },
  "494": { message: "Request Header Too Large", color: "yellow" },
  "500": { message: "Internal Server Error", color: "yellow" },
  "501": { message: "Not Implemented", color: "yellow" },
  "502": { message: "Bad Gateway", color: "yellow" },
  "503": { message: "Service Unavailable", color: "yellow" },
  "504": { message: "Gateway Timeout", color: "yellow" },
  "506": { message: "Variant Also Negotiates", color: "yellow" },
  "507": { message: "Insufficient Storage", color: "yellow" },
  "508": { message: "Loop Detected", color: "yellow" },
  "509": { message: "Bandwidth Limit Exceeded", color: "yellow" },
  "510": { message: "Not Extended", color: "yellow" },
  default: {
    message: "Generic Error",
    color: "yellow",
  },
};

export function isSupportedHttpStatus(input: string): input is HttpStatus {
  return typedIncludes(supportedHttpStatusCodes, input);
}

export const httpStatusArr = Object.keys(
  httpStatusMap
) as unknown as HttpStatus[];

export function toReadableDate(date: Date) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
}

export function sleep(ms: number) {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(undefined);
    }, ms)
  );
}

const openApiKeyOrder: (keyof OASDefinition)[] = [
  "openapi",
  "info",
  "servers",
  "paths",
  "components",
  "security",
  "tags",
  "externalDocs",
];

export function sortOASDefinition(doc: OASDefinition): OASDefinition {
  const orderedDoc: Partial<OASDefinition> = {};

  // Add keys in the specified order
  for (const key of openApiKeyOrder) {
    if (key in doc) {
      (orderedDoc as any)[key] = doc[key];
    }
  }

  // Add any remaining keys not specified in the order
  Object.keys(doc).forEach((key) => {
    if (!orderedDoc.hasOwnProperty!(key)) {
      (orderedDoc as any)[key as keyof OASDefinition] =
        doc[key as keyof OASDefinition];
    }
  });

  return orderedDoc as OASDefinition;
}
