import { Button } from "@/components/_shadui/button";
import { ColorBadge } from "@/components/_shadui/color-badge";
import { MultiSelect } from "@/components/_shadui/multi-select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/_shadui/tabs";
import { ActiveElement } from "@/components/contexts/api-editor-context";
import {
  DocumentationCard,
  DocumentationCardContent,
  DocumentationCardDescription,
  DocumentationCardHeader,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import {
  HeaderGrid,
  HeaderGridTitle,
  HeaderGridValue,
} from "@/components/header-grid";
import {
  EditorItemDescription,
  PreviewContainer,
  PreviewHeading,
  PreviewToolbarContainer,
} from "@/components/module-api-editor";
import {
  ActiveOperationItem,
  EditorPreviewOperationActiveItem,
} from "@/components/module-api-editor/editor-preview-operation-active-item";
import { Toolbar } from "@/components/module-api-editor/toolbar";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { OperationPreview } from "@/components/module-preview-pane/operation-preview";
import { PathParametersCard } from "@/components/module-visual-editor/path-parameters-card";
import { RequestParameterCard } from "@/components/module-visual-editor/request-parameters-card";
import { RequestBodyCard } from "@/components/module-visual-editor/request-body-card";
import { ResponsesCard } from "@/components/module-visual-editor/responses-card";
import {
  BtnGroup,
  CopyButton,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { useOperation } from "@/hooks/use-operation";
import { useSecuritySchemes } from "@/hooks/use-security-schemes";
import { httpColorMap } from "@/lib/oas-tools/style-helpers";
import { OASComponentsObject, SupportedHTTPVerbs } from "@/lib/types";
import {
  canEdit,
  DEFAULT_ICON_SIZE,
  NormIcons,
  setClipboard,
} from "@/lib/utils";
import { ReactNode, useMemo, useState } from "react";
import { toast } from "sonner";
import { useActionBarContext } from "../contexts/action-bar-context";
import { HttpMethodBadge } from "@/components/special-badges";
import { AuthParameterCard } from "@/components/module-visual-editor/auth-parameters.card";

type Option = {
  value: string;
  label: string;
  icon?: React.ComponentType<{ className?: string }>;
};

const templateOptions = [
  { value: "BearerJwt", label: "BearerJwt" },
  { value: "ApiKey", label: "ApiKey" },
  { value: "BearerBasic", label: "BearerBasic" },
  { value: "BearerCustom", label: "BearerCustom" },
];

function AddAuthSelect({
  operationId,
  value,
  onChange,
  workspaceRole,
}: {
  operationId: string;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const {
    getSecuritySchemes,
    addSecuritySchemesToOperation,
    findOperationSecuritySchemeNames,
  } = useSecuritySchemes({ value, onChange });

  const defaultValues = useMemo(() => {
    return findOperationSecuritySchemeNames({ operationId });
  }, [findOperationSecuritySchemeNames, operationId]);

  const options = useMemo(() => {
    const securitySchemes = getSecuritySchemes();

    const optionArr: Option[] = [];

    Object.keys(securitySchemes).forEach((e) =>
      optionArr.push({ value: e, label: e })
    );

    templateOptions.forEach((templateOption) => {
      if (!optionArr.some((e) => e.value === templateOption.value)) {
        optionArr.push(templateOption);
      }
    });

    return optionArr;
  }, [getSecuritySchemes]);

  const handleValueChange = (schemeNames: string[]) => {
    addSecuritySchemesToOperation({ operationId, schemeNames });
  };

  const isEditor = canEdit(workspaceRole);
  return !isEditor && defaultValues.length === 0 ? (
    <ColorBadge color="gray" className="my-1">
      None
    </ColorBadge>
  ) : (
    <MultiSelect
      defaultValue={defaultValues}
      options={options}
      onValueChange={handleValueChange}
      className="border-0"
      badgeColor="yellow"
      maxCount={5}
      disabled={!isEditor}
      addButton={
        <Button size="xs" variant="secondary" className="mx-1 h-[22px]" asChild>
          <span role="button">
            <NormIcons.Add size={DEFAULT_ICON_SIZE} className="" />
          </span>
        </Button>
      }
    />
  );
}

export function EditorPreviewOperation({
  value,
  onChange,
  isAuthed,
  hasOperations,
  workspaceSlug,
  organizationSlug,
  activeElement,
  componentsObject,
  workspaceRole,
  extraToolbarItems,
}: {
  activeElement: ActiveElement;
  isAuthed: boolean;
  hasOperations: boolean;
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  componentsObject: OASComponentsObject;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode | undefined;
} & EditorInputProps) {
  const [activeItem, setActiveItem] = useState<ActiveOperationItem | undefined>(
    undefined
  );
  const [, actionBarDispatch] = useActionBarContext();
  const { getOperationWithInfo, removeOperation } = useOperation({
    value,
    onChange,
  });

  if (activeElement.type !== "operation")
    throw new Error("Active element needs to be operation");

  const operationWithInfo = useMemo(
    () => getOperationWithInfo(activeElement.operationId),
    [activeElement.operationId, getOperationWithInfo]
  );

  const operation = operationWithInfo.operation;
  const method = operationWithInfo.method;
  const urlPath = operationWithInfo.urlPath;
  const path = `paths.${urlPath}.${method}`;

  if (!operation.operationId) throw new Error();

  const handleEditClick = (urlPath: string, method: SupportedHTTPVerbs) => {
    if (!operation.operationId) throw new Error("Operation does not have id");
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-operation",
        context: {
          urlPath,
          method,
          operationId: operation.operationId,
        },
      },
    });
  };

  const handleDeleteClick = (urlPath: string, method: SupportedHTTPVerbs) => {
    removeOperation(urlPath, method);
  };

  const handleCopyUrlToClipboard = () => {
    setClipboard(operationWithInfo.urlPath);
    toast.success("Copied to clipboard");
  };

  const color = httpColorMap[method];
  if (!color) throw new Error("Unable to find color for method");

  const isEditor = canEdit(workspaceRole);
  return (
    <PreviewContainer>
      <Tabs defaultValue="editor" className="h-full w-full flex flex-col">
        <PreviewToolbarContainer>
          <TabsList>
            <TabsTrigger value="editor">Editor</TabsTrigger>
            <TabsTrigger value="plain">Document</TabsTrigger>
          </TabsList>
          <Toolbar
            key={JSON.stringify(activeElement)}
            value={value}
            onChange={onChange}
            isAuthed={isAuthed}
            hasOperations={hasOperations}
            organizationSlug={organizationSlug}
            workspaceSlug={workspaceSlug}
            workspaceRole={workspaceRole}
            className="pb-3"
            extraToolbarItems={extraToolbarItems}
          />
        </PreviewToolbarContainer>
        <TabsContent
          value="editor"
          className="grow mt-0 overflow-auto rounded-md shadow-lg relative pb-10"
        >
          <div className="flex items-stretch">
            <div className="flex flex-col items-stretch gap-10 pt-2 grow">
              <div className="flex justify-between px-4 items-center">
                <div className="flex items-stretch gap-2">
                  <HttpMethodBadge method={operationWithInfo.method} />
                  <PreviewHeading preHeading="Operation">
                    {operationWithInfo.operation.summary}
                  </PreviewHeading>
                </div>
                {isEditor && (
                  <BtnGroup>
                    <EditButton
                      onClick={() => handleEditClick(urlPath, method)}
                    />
                    <DeleteButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDeleteClick(urlPath, method);
                      }}
                    />
                  </BtnGroup>
                )}
              </div>
              <HeaderGrid hoverLayout>
                <HeaderGridTitle>Url</HeaderGridTitle>
                <HeaderGridValue>
                  <div className="grid grid-cols-[min-content,min-content,1fr] items-center gap-x-2">
                    <p className="text-sm font-mono whitespace-nowrap">
                      {urlPath}
                    </p>
                    <CopyButton onClick={handleCopyUrlToClipboard}>
                      Copy url path
                    </CopyButton>
                  </div>
                </HeaderGridValue>
                <HeaderGridTitle>Tag</HeaderGridTitle>
                <HeaderGridValue>
                  <ColorBadge color="gray">
                    <NormIcons.Tag size={DEFAULT_ICON_SIZE} className="mr-2" />
                    {operation.tags?.[0] || "Default"}
                  </ColorBadge>
                </HeaderGridValue>
                <HeaderGridTitle>Auth</HeaderGridTitle>
                <BtnGroup>
                  <AddAuthSelect
                    operationId={operation.operationId}
                    value={value}
                    onChange={onChange}
                    workspaceRole={workspaceRole}
                  />
                </BtnGroup>
              </HeaderGrid>

              <DocumentationCard>
                <DocumentationCardHeader hoverLayout>
                  <DocumentationCardTitle>Description</DocumentationCardTitle>
                </DocumentationCardHeader>
                <DocumentationCardContent className="px-4 pt-2">
                  <DocumentationCardDescription className="text-primary">
                    <EditorItemDescription
                      item={operation}
                      onAddDescriptionClick={() =>
                        handleEditClick(urlPath, method)
                      }
                      workspaceRole={workspaceRole}
                    />
                  </DocumentationCardDescription>
                </DocumentationCardContent>
              </DocumentationCard>

              <PathParametersCard
                operationId={operation.operationId}
                value={value}
                onChange={onChange}
                workspaceRole={workspaceRole}
                setActiveItem={setActiveItem}
                activeItem={activeItem}
              />
              <AuthParameterCard
                onChange={onChange}
                value={value}
                operationWithInfo={operationWithInfo}
                workspaceRole={workspaceRole}
                setActiveItem={setActiveItem}
                activeItem={activeItem}
              />
              <RequestParameterCard
                operationWithInfo={operationWithInfo}
                value={value}
                onChange={onChange}
                workspaceRole={workspaceRole}
                setActiveItem={setActiveItem}
                activeItem={activeItem}
              />
              <ResponsesCard
                operationId={operation.operationId}
                activeItem={activeItem}
                method={method}
                urlPath={urlPath}
                path={`${path}.responses`}
                value={value}
                onChange={onChange}
                workspaceRole={workspaceRole}
                setActiveItem={setActiveItem}
              />
              <RequestBodyCard
                operationId={operation.operationId}
                method={method}
                value={value}
                onChange={onChange}
                workspaceRole={workspaceRole}
                setActiveItem={setActiveItem}
              />
            </div>
            {activeItem && (
              <DocumentationCard className="shrink-0 w-[350px] mr-2">
                <DocumentationCardContent className="fixed w-[330px] right-[15px]">
                  <EditorPreviewOperationActiveItem
                    value={value}
                    onChange={onChange}
                    activeItem={activeItem}
                    operationId={operation.operationId}
                    workspaceRole={workspaceRole}
                    setActiveItem={setActiveItem}
                  />
                </DocumentationCardContent>
              </DocumentationCard>
            )}
          </div>
        </TabsContent>
        <TabsContent
          value="plain"
          className="grow mt-0 overflow-auto bg-preview-background rounded-md shadow-lg relative"
        >
          {activeElement?.type === "operation" && (
            <OperationPreview
              activeElement={activeElement}
              componentsObject={componentsObject}
              value={value}
              onChange={onChange}
            />
          )}
        </TabsContent>
      </Tabs>
    </PreviewContainer>
  );
}
