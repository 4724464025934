import * as React from "react";

import { ColorBadeColors, ColorBadge } from "@/components/_shadui/color-badge";
import { getSchemaTypeName } from "@/lib/oas-tools/oas-schema-utils";
import { deref, isReferenceSchema } from "@/lib/oas-tools/oas-tag-helpers";
import { typeColorMap } from "@/lib/oas-tools/style-helpers";
import {
  OASReferenceObject,
  OASSchema,
  ParameterPosition,
  SupportedHTTPVerbs,
} from "@/lib/types";
import { capitalize } from "lodash";

export const TypeBadge = ({
  className,
  schema,
  isRequired = true,
  forceColor,
  ...props
}: React.ComponentProps<typeof ColorBadge> & {
  schema: OASSchema | OASReferenceObject | undefined;
  isRequired?: boolean;
  forceColor?: ColorBadeColors;
}) => {
  const badge = !schema ? (
    <ColorBadge className={className} color={forceColor || "gray"} {...props}>
      null
    </ColorBadge>
  ) : isReferenceSchema(schema) ? (
    <ColorBadge {...props} className={className} color={forceColor || "blue"}>
      {deref(schema.$ref)}
    </ColorBadge>
  ) : (
    <ColorBadge
      {...props}
      className={className}
      color={forceColor || typeColorMap[getSchemaTypeName(schema)] || "gray"}
    >
      {getSchemaTypeName(schema)}
    </ColorBadge>
  );
  return (
    <span className="inline-flex gap-1">
      {badge}
      {!isRequired && <ColorBadge color="gray">or null</ColorBadge>}
    </span>
  );
};

const colorMap: Record<SupportedHTTPVerbs, ColorBadeColors> = {
  delete: "red",
  get: "green",
  head: "yellow",
  options: "gray",
  patch: "indigo",
  post: "blue",
  put: "teal",
  trace: "emerald",
};

export function HttpMethodBadge({ method }: { method: SupportedHTTPVerbs }) {
  const methodStr = method.toUpperCase();
  return (
    <ColorBadge
      color={colorMap[method] || "blue"}
      className="w-[70px] text-center justify-center"
    >
      {methodStr}
    </ColorBadge>
  );
}

const parameterPositionColorMap: Record<ParameterPosition, ColorBadeColors> = {
  cookie: "cyan",
  header: "green",
  path: "yellow",
  query: "fuchsia",
};

export function ParameterPositioinBadge({
  parameterPosition,
}: {
  parameterPosition: ParameterPosition;
}) {
  return (
    <ColorBadge
      color={parameterPositionColorMap[parameterPosition] || "blue"}
      className="w-[70px] text-center justify-center"
    >
      {capitalize(parameterPosition)}
    </ColorBadge>
  );
}
