import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/_shadui/breadcrumb";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/_shadui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/_shadui/tabs";
import { DashboardHeader } from "@/components/dashboard/dashboard-header";
import { WorkspaceSettingsCloneCard } from "@/components/workspace-settings-clone-card";
import { WorkspaceSettingsRepositoryAdapterCard } from "@/components/workspace-settings-repository-adapter-card";
import { WorkspaceSettingsMemberList } from "@/components/workspace_settings_member_list";
import { FormPatchWorkspace } from "@/forms/form-workspace";
import { apiClient } from "@/lib/http-utils";
import { canAdmin } from "@/lib/utils";
import {
  organizationRepositoryAdaptersQueryOptions,
  useOrganizationRepositoryAdapters,
} from "@/queries/repository-adapters";
import { useMe, userMeQuery } from "@/queries/users";
import { createFileRoute, Link } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/dash/$organizationSlug/$workspaceSlug/edit"
)({
  component: WorkspaceSettings,
  loader: async ({ params: { workspaceSlug }, context: { queryClient } }) => {
    const workspaceDto = (
      await apiClient.findWorkspaceByIdOrSlug({
        workspaceSlugOrId: workspaceSlug,
      })
    ).data;
    await queryClient.ensureQueryData(userMeQuery);
    await queryClient.ensureQueryData(
      organizationRepositoryAdaptersQueryOptions()
    );
    return { workspaceDto };
  },
});

function WorkspaceSettings() {
  const { organizationSlug } = Route.useParams();
  const workspaceDto = Route.useLoaderData().workspaceDto;
  const repositoryAdaptersQuery = useOrganizationRepositoryAdapters();

  const meQuery = useMe();

  if (!workspaceDto)
    return <span>There was an error when trying to get your workspace</span>;

  if (!meQuery.data) return null;
  if (!repositoryAdaptersQuery.data) return null;

  const isOrgMember =
    meQuery.data?.organization_id === workspaceDto.organization_id;

  return (
    <div>
      <div className="max-w-screen-xl mx-auto px-5">
        <DashboardHeader organizationSlug={organizationSlug} />
        <Breadcrumb className="py-norm px-3">
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link
                  to="/dash/$organizationSlug/home"
                  params={{ organizationSlug }}
                >
                  Home
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Workspace settings</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <Tabs defaultValue="general">
          <TabsList>
            <TabsTrigger value="general">Workspace settings</TabsTrigger>
          </TabsList>
          <TabsContent
            value="general"
            className="flex flex-col items-stretch gap-4"
          >
            <Card>
              <CardHeader>
                <CardTitle>Workspace properties</CardTitle>
              </CardHeader>
              <CardContent>
                <FormPatchWorkspace
                  isDisabled={!canAdmin(workspaceDto.role)}
                  organizationSlug={organizationSlug}
                  formValues={{
                    name: workspaceDto.name,
                    slug: workspaceDto.slug,
                    accessLevel: workspaceDto.access_level,
                  }}
                  workspaceId={workspaceDto.id}
                />
              </CardContent>
            </Card>
            <WorkspaceSettingsMemberList
              userWorkspaceDto={workspaceDto}
              meDto={meQuery.data}
            />
            <WorkspaceSettingsCloneCard userWorkspace={workspaceDto} />
            {isOrgMember && (
              <WorkspaceSettingsRepositoryAdapterCard
                workspace={workspaceDto}
                organizationRepositoryAdapters={repositoryAdaptersQuery.data}
              />
            )}
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
