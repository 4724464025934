import { H3 } from "@/components/headings";
import { cn } from "@/lib/utils";
import { ComponentProps, PropsWithChildren } from "react";

export function FormBox({
  children,
  title,
  className,
  ...rest
}: ComponentProps<"div"> & { title: string }) {
  return (
    <div className={cn("rounded-lg px-6 py-6 border", className)} {...rest}>
      <H3 className="font-mono pb-4">{title}</H3>
      <div>{children}</div>
    </div>
  );
}

export function FieldVStack({ className, ...rest }: ComponentProps<"div">) {
  return (
    <div
      className={cn("flex flex-col items-stretch gap-4 py-2", className)}
      {...rest}
    />
  );
}

export function FieldHStack({ className, ...rest }: ComponentProps<"div">) {
  return (
    <div className={cn("flex justify-stretch gap-3", className)} {...rest} />
  );
}

export function FormTitle({ children }: PropsWithChildren) {
  return (
    <H3 className="text-muted-foreground font-mono text-sm">
      <span className="inline-block pb-0.5 pr-1 mb-2">{children}</span>
    </H3>
  );
}

export function ButtonGroup({
  className,
  children,
  ...rest
}: ComponentProps<"div">) {
  return (
    <div className={cn("pt-4 flex gap-2", className)} {...rest}>
      {children}
    </div>
  );
}
