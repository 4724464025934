import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  addOperationAndParamsToDefinition,
  editOperationAndParamsInDefinition,
  findOperationByIdOrErrorFromDefinition,
  getOperationsWithInfoFromDefinition,
  getOperationWithInfoFromDefinition,
  removeOperationFromDefinition,
} from "@/lib/editor-mutations/oas-operations";
import { OASOperation, SupportedHTTPVerbs } from "@/lib/types";
import get from "lodash/get";
import { useCallback } from "react";

export function useOperation(props: EditorInputProps) {
  const { oas, setNewEditorState, setActiveElement } = useAPIEditorTools(props);
  const definition = oas;
  if (!definition) throw new Error("Definition not defined");

  const getOperation = useCallback(
    (urlPath: string, method: SupportedHTTPVerbs) => {
      const op = get(definition, `paths.${urlPath}.${method}`) as
        | OASOperation
        | undefined;
      return op;
    },
    [definition]
  );

  const getOperationById = useCallback(
    (operationId: string) => {
      return findOperationByIdOrErrorFromDefinition(definition, operationId);
    },
    [definition]
  );

  const getOperationsWithInfo = useCallback(() => {
    return getOperationsWithInfoFromDefinition(definition);
  }, [definition]);

  const addOperationAndParameters = useCallback(
    ({
      urlPath,
      method,
      operation,
    }: {
      urlPath: string;
      method: SupportedHTTPVerbs;
      operation: OASOperation;
    }) => {
      if (urlPath.endsWith("/")) urlPath = urlPath.slice(0, -1);

      const newDefinition = addOperationAndParamsToDefinition({
        definition,
        urlPath,
        method,
        newOperation: operation,
      });

      setNewEditorState({
        document_type: props.value.document_type,
        data: newDefinition,
      });
    },
    [definition, setNewEditorState, props.value.document_type]
  );

  const editOperationAndParams = useCallback(
    ({
      oldMethod,
      oldUrlPath,
      newMethod,
      newUrlPath,
      newOperation,
    }: {
      oldUrlPath: string;
      newUrlPath: string;
      oldMethod: SupportedHTTPVerbs;
      newMethod: SupportedHTTPVerbs;
      newOperation: OASOperation;
    }) => {
      if (newUrlPath.endsWith("/")) newUrlPath = newUrlPath.slice(0, -1);

      const newDefinition = editOperationAndParamsInDefinition({
        oldMethod,
        oldUrlPath,
        newMethod,
        newOperation,
        newUrlPath,
        definition,
      });
      setNewEditorState({
        document_type: props.value.document_type,
        data: newDefinition,
      });
      setActiveElement(
        newOperation.operationId
          ? {
              operationId: newOperation.operationId,
              type: "operation",
            }
          : undefined
      );
    },
    [definition, setActiveElement, setNewEditorState, props.value.document_type]
  );

  const getOperationWithInfo = useCallback(
    (operationId: string) => {
      return getOperationWithInfoFromDefinition({ definition, operationId });
    },
    [definition]
  );

  const removeOperation = useCallback(
    (urlPath: string, method: SupportedHTTPVerbs) => {
      const newDefinition = removeOperationFromDefinition({
        definition,
        urlPath,
        method,
      });
      setActiveElement(undefined);
      setNewEditorState({
        document_type: props.value.document_type,
        data: newDefinition,
      });
    },
    [definition, setNewEditorState, setActiveElement, props.value.document_type]
  );

  const editOperationPathAndMethod = useCallback(
    ({
      oldMethod,
      oldUrlPath,
      newMethod,
      newUrlPath,
      operation,
    }: {
      oldUrlPath: string;
      oldMethod: SupportedHTTPVerbs;
      newUrlPath: string;
      newMethod: SupportedHTTPVerbs;
      operation: OASOperation;
    }) => {
      const newDefinition = editOperationAndParamsInDefinition({
        definition: props.value.data,
        newMethod,
        oldMethod,
        oldUrlPath,
        newUrlPath,
        newOperation: operation,
      });

      setActiveElement(undefined);
      setNewEditorState({
        data: newDefinition,
        document_type: props.value.document_type,
      });
    },
    [
      setNewEditorState,
      props.value.document_type,
      setActiveElement,
      props.value.data,
    ]
  );

  return {
    getOperation,
    addOperationAndParameters,
    removeOperation,
    editOperationAndParams,
    getOperationById,
    getOperationWithInfo,
    getOperationsWithInfo,
    editOperationPathAndMethod,
  };
}
