import { Button } from "@/components/_shadui/button";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { GeneralInformationSecuritySchemes } from "@/components/module-api-editor/general-information-security-schemes";
import { Toolbar } from "@/components/module-api-editor/toolbar";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { canEdit } from "@/lib/utils";
import { ReactNode } from "@tanstack/react-router";

export function EditorPreviewGeneralInformation({
  value,
  onChange,
  organizationSlug,
  workspaceSlug,
  isAuthed,
  hasOperations,
  workspaceRole,
  extraToolbarItems,
}: {
  organizationSlug?: string;
  workspaceSlug?: string;
  isAuthed: boolean;
  hasOperations: boolean;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode | undefined;
} & EditorInputProps) {
  const { oas } = useAPIEditorTools({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();
  const onEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    actionBarDispatch({
      type: "SET_PAGE",
      payload: { name: "edit-oas-metadata" },
    });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <div className="h-full w-full flex flex-col px-8 items-stretch gap-4">
      <div className="flex justify-end pb-3">
        <Toolbar
          hasOperations={hasOperations}
          isAuthed={isAuthed}
          onChange={onChange}
          value={value}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          workspaceRole={workspaceRole}
          extraToolbarItems={extraToolbarItems}
        />
      </div>
      <div className="flex items-start justify-between">
        <div className="">
          <span className="text-muted-foreground text-sm">Api title</span>
          <h3 className="font-mono font-semibold">
            {oas?.info.title || "No title yet"}
          </h3>
        </div>
        {isEditor && (
          <BtnGroup>
            <EditButton onClick={onEditClick} />
          </BtnGroup>
        )}
      </div>
      <div>
        <span className="text-muted-foreground text-sm">Description</span>
        <p className=" text-sm lg:max-w-[70%]">
          {oas?.info.description ? (
            oas.info.description
          ) : (
            <>
              Add a description{" "}
              <Button variant="link" size="link" onClick={onEditClick}>
                here
              </Button>
              .
            </>
          )}
        </p>
      </div>
      <GeneralInformationSecuritySchemes
        value={value}
        onChange={onChange}
        workspaceRole={workspaceRole}
      />
    </div>
  );
}
