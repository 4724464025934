import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import { Textarea } from "@/components/_shadui/textarea";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { FormLabelRequired } from "@/components/form-label-required";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaContentEditor } from "@/components/schema-content-editor";
import { SchemaEditorPreviewContainer } from "@/components/schema-quick-editor";
import { useComponents } from "@/hooks/use-components";
import { NoRefsHereError } from "@/lib/errors";
import {
  getPristineContentObject,
  zodContentObject,
} from "@/lib/oas-tools/oas-schema-utils";
import { isReference } from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASComponentsObject,
  OASResponse,
  supportedContentFormats,
} from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  name: z.string().min(1, "Required"),
  description: z.string().min(1, "Required"),
  contentObject: zodContentObject,
  format: z.enum(supportedContentFormats),
});
type FormValues = z.infer<typeof formSchema>;

function FormComponentResponse({
  defaultValues,
  onSubmit,
  title,
  btnTitle,
  componentsObject,
}: {
  defaultValues?: Partial<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
  title: string;
  btnTitle: string;
  componentsObject: OASComponentsObject;
}) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      contentObject: getPristineContentObject(),
      name: "",
      description: "",
      format: "application/json",
      ...defaultValues,
    },
  });

  return (
    <Form {...form}>
      <ActionBarForm onSubmit={form.handleSubmit(onSubmit)}>
        <ActionBarFormTitle>{title}</ActionBarFormTitle>
        <ActionBarFieldGroup>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Name
                </FormLabel>
                <FormControl>
                  <Input disabled {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Description
                </FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <SchemaEditorPreviewContainer
            title="Schema"
            className="min-h-[150px]"
          >
            <Controller
              name="contentObject"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <SchemaContentEditor
                  componentsObject={componentsObject}
                  allowTopLevelReferences={false}
                  value={value}
                  onChange={(schema) => {
                    onChange(schema);
                  }}
                />
              )}
            />
          </SchemaEditorPreviewContainer>
        </ActionBarFieldGroup>
        <BtnGroup className="justify-end">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </ActionBarForm>
    </Form>
  );
}

export function FormAddComponentResponse({
  value,
  onChange,
}: EditorInputProps) {
  const { addComponentResponse } = useComponents({ value, onChange });
  const { componentsObject } = useAPIEditorTools({ value, onChange });

  const [, actionBarDispatch] = useActionBarContext();

  const handleSubmit: SubmitHandler<FormValues> = (values) => {
    addComponentResponse({
      name: values.name,
      responseObject: {
        description: values.description,
        content: values.contentObject,
      },
    });
    actionBarDispatch({ type: "CLOSE" });
  };

  return (
    <FormComponentResponse
      componentsObject={componentsObject}
      onSubmit={handleSubmit}
      title="Add response"
      btnTitle="Add"
    />
  );
}

export function FormEditComponentResponse({
  value,
  onChange,
  responseObject,
  responseObjectName,
}: {
  responseObject: OASResponse;
  responseObjectName: string;
} & EditorInputProps) {
  const { editComponentResponse } = useComponents({ value, onChange });
  const { componentsObject } = useAPIEditorTools({ value, onChange });

  const [, actionBarDispatch] = useActionBarContext();

  const handleSubmit: SubmitHandler<FormValues> = (values) => {
    editComponentResponse({
      oldName: responseObjectName,
      newName: values.name,
      responseObject: {
        description: values.description,
        content: values.contentObject,
      },
    });
    actionBarDispatch({ type: "CLOSE" });
  };

  if (isReference(responseObject)) throw new NoRefsHereError();

  return (
    <FormComponentResponse
      componentsObject={componentsObject}
      defaultValues={{
        contentObject: responseObject.content || getPristineContentObject(),
        description: responseObject.description,
        name: responseObjectName,
      }}
      onSubmit={handleSubmit}
      title="Edit response component"
      btnTitle="Edit"
    />
  );
}
