import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/_shadui/accordion";
import { Button } from "@/components/_shadui/button";
import { ColorBadge } from "@/components/_shadui/color-badge";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { ActiveElement } from "@/components/contexts/api-editor-context";
import {
  DocumentationCard,
  DocumentationCardContent,
  DocumentationCardDescription,
  DocumentationCardHeader,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import {
  HeaderGrid,
  HeaderGridTitle,
  HeaderGridValue,
} from "@/components/header-grid";
import {
  EditorItemDescription,
  PreviewContainer,
  PreviewHeading,
  PreviewToolbarContainer,
} from "@/components/module-api-editor";
import { Toolbar } from "@/components/module-api-editor/toolbar";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaQuickEditor } from "@/components/schema-quick-editor";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import {
  AllPropertyTypes,
  getComponentParameterTypeName,
} from "@/lib/oas-tools/oas-schema-utils";
import { typeColorMap } from "@/lib/oas-tools/style-helpers";
import { canEdit } from "@/lib/utils";
import { ReactNode, useMemo } from "react";

export function EditorPreviewComponentParameter({
  value,
  onChange,
  activeElement,
  hasOperations,
  workspaceSlug,
  organizationSlug,
  isAuthed,
  workspaceRole,
  extraToolbarItems,
}: {
  isAuthed: boolean;
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  activeElement: Extract<ActiveElement, { type: "component-parameter" }>;
  hasOperations: boolean;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode | undefined;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();

  const { getComponentParameterByLabel, removeComponentParameter } =
    useComponentParameters({
      value,
      onChange,
    });

  const componentParameter = useMemo(() => {
    const found = getComponentParameterByLabel(activeElement.label);
    if (!found) throw new Error("Component response not found");
    return found;
  }, [activeElement.label, getComponentParameterByLabel]);

  const typeName = getComponentParameterTypeName(componentParameter);

  const color = typeColorMap[typeName as AllPropertyTypes] || "blue";

  const handleEditClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-parameter",
        context: {
          label: activeElement.label,
          parameter: componentParameter,
        },
      },
    });
  };

  const handleRemoveClick = () => {
    removeComponentParameter(activeElement.label);
  };

  const isEditor = canEdit(workspaceRole);

  return (
    <PreviewContainer>
      <div className="h-full flex flex-col items-stretch">
        <PreviewToolbarContainer>
          <span />
          <Toolbar
            hasOperations={hasOperations}
            isAuthed={isAuthed}
            onChange={onChange}
            value={value}
            workspaceSlug={workspaceSlug}
            organizationSlug={organizationSlug}
            workspaceRole={workspaceRole}
            extraToolbarItems={extraToolbarItems}
          />
        </PreviewToolbarContainer>

        {/* Scroll area */}
        <div className="grow overflow-y-auto">
          <div className="flex flex-col items-stretch gap-10 pb-10">
            <div className="flex justify-between px-4 items-center">
              <PreviewHeading preHeading="Parameter">
                {activeElement.label}
              </PreviewHeading>
              {isEditor && (
                <BtnGroup>
                  <EditButton onClick={handleEditClick} />
                  <DeleteButton onClick={handleRemoveClick} />
                </BtnGroup>
              )}
            </div>

            <HeaderGrid
              hoverLayout
              className="items-start auto-rows-auto gap-y-2"
            >
              <HeaderGridTitle className="">Type</HeaderGridTitle>
              <HeaderGridValue>
                <ColorBadge color={color}>{typeName}</ColorBadge>{" "}
              </HeaderGridValue>
              <HeaderGridTitle className="">Location</HeaderGridTitle>
              <HeaderGridValue>
                <ColorBadge
                  color={componentParameter.in === "path" ? "cyan" : "purple"}
                >
                  {componentParameter.in}
                </ColorBadge>
              </HeaderGridValue>
              <HeaderGridTitle>Schema</HeaderGridTitle>
              <HeaderGridValue>
                <Accordion
                  type="single"
                  collapsible
                  className="max-w-screen-sm"
                >
                  <AccordionItem value="item-1" className="border-0">
                    <AccordionTrigger className="text-sm pt-0 max-w-32 pb-0">
                      <Button variant="secondary" size="xs" asChild>
                        <span>Toggle schema</span>
                      </Button>
                    </AccordionTrigger>
                    <AccordionContent>
                      <SchemaQuickEditor
                        onChange={onChange}
                        workspaceRole={workspaceRole}
                        value={value}
                        title={`${activeElement.label} schema`}
                        className=" min-h-[80px] w-full mt-norm"
                        path={`components.parameters.${activeElement.label}.schema`}
                        allowTopLevelReferences={false}
                      />
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </HeaderGridValue>
            </HeaderGrid>

            <DocumentationCard>
              <DocumentationCardHeader hoverLayout>
                <DocumentationCardTitle>Description</DocumentationCardTitle>
              </DocumentationCardHeader>
              <DocumentationCardContent className="pt-2 px-4">
                <DocumentationCardDescription className="text-primary">
                  <EditorItemDescription
                    item={componentParameter}
                    onAddDescriptionClick={handleEditClick}
                    workspaceRole={workspaceRole}
                  />
                </DocumentationCardDescription>
              </DocumentationCardContent>
            </DocumentationCard>
          </div>
        </div>
      </div>
    </PreviewContainer>
  );
}
