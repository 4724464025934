import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  SchemaContentEditor,
  SchemaContentEditorPreviewContainer,
} from "@/components/schema-content-editor";
import { OASContentObject } from "@/lib/types";
import { canEdit } from "@/lib/utils";
import get from "lodash/get";
import set from "lodash/set";
import { ComponentPropsWithoutRef, useMemo, useState } from "react";

export function SchemaContentQuickEditor({
  path, // needs to point to the content object e.g. `paths./books.get.responses.400.content`
  onClose,
  workspaceRole,
  title,
  allowTopLevelReferences,
  value: editorValue,
  onChange: editorOnChange,
  ...rest
}: {
  onClose?: () => unknown;
  workspaceRole: WorkspaceRole;
  path: string;
  title: string;
  allowTopLevelReferences: boolean;
} & Omit<ComponentPropsWithoutRef<"div">, "onChange"> &
  EditorInputProps) {
  const [isLocked, setIsLocked] = useState(true);
  const { setNewEditorState } = useAPIEditorTools({
    value: editorValue,
    onChange: editorOnChange,
  });

  const { componentsObject } = useAPIEditorTools({
    value: editorValue,
    onChange: editorOnChange,
  });

  const value = useMemo(() => {
    return get(editorValue.data, path);
  }, [editorValue.data, path]);

  const handleChange = (value: OASContentObject) => {
    if (!path.endsWith("content"))
      throw new Error("Invalid path to content object");

    const cp = structuredClone(editorValue.data);
    const newEditorState = set(cp, path, value);

    setNewEditorState({
      document_type: editorValue.document_type,
      data: newEditorState,
    });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <SchemaContentEditorPreviewContainer
      title={title}
      onClose={onClose}
      isLocked={isLocked}
      onLockChange={isEditor ? () => setIsLocked(!isLocked) : undefined}
      {...rest}
    >
      <SchemaContentEditor
        value={value}
        componentsObject={componentsObject}
        onChange={handleChange}
        allowTopLevelReferences={allowTopLevelReferences}
        title={title}
        isReadOnly={isLocked}
      />
    </SchemaContentEditorPreviewContainer>
  );
}
