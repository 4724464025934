import { Button } from "@/components/_shadui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_shadui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/_shadui/tooltip";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  DocumentationCard,
  DocumentationCardButtonGroup,
  DocumentationCardContent,
  DocumentationCardDescription,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemContent,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardMoreButton,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import {
  EditorItemDescription,
  HideEmptyListWhenNonEditor,
} from "@/components/module-api-editor";
import {
  type ActiveOperationItem,
  type SetActiveItem,
} from "@/components/module-api-editor/editor-preview-operation-active-item";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  AddLabelButton,
  BtnGroup,
  ComponentButton,
  DeleteButton,
  EditButton,
  LinkButton,
} from "@/components/module-visual-editor/shared-components";
import {
  ParameterPositioinBadge,
  TypeBadge,
} from "@/components/special-badges";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import { ParameterWithInfo, useParameters } from "@/hooks/use-parameters";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { ParameterPosition } from "@/lib/types";
import { canEdit, DEFAULT_ICON_SIZE_SM, NormIcons } from "@/lib/utils";
import { useMemo } from "react";

function ParameterItem({
  parameter,
  activeItem,
  setActiveItem,
  operationWithInfo,
  value,
  onChange,
  workspaceRole,
  isLast,
}: {
  parameter: ParameterWithInfo;
  setActiveItem: SetActiveItem;
  activeItem: ActiveOperationItem | undefined;
  operationWithInfo: OperationWithInfo;
  workspaceRole: WorkspaceRole;
  isLast: boolean;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { setActiveElement } = useAPIEditorTools({ value, onChange });
  const { addOrAttatchExistingParameterAsComponent } = useComponentParameters({
    value,
    onChange,
  });
  const { resolveParameterObject, remove } = useParameters({
    value,
    onChange,
  });
  const refName = isReference(parameter.parameter)
    ? deref(parameter.parameter.$ref)
    : undefined;
  const resolved = resolveParameterObject(parameter.parameter);

  const operationId = operationWithInfo.operation.operationId || "";

  const handleRemoveParameter = (parameter: ParameterWithInfo) => {
    // IMPORTANT: Reset active item so path in `QuickEditor`
    // does not become invalid
    setActiveItem(undefined);
    remove({
      parameterIdx: parameter.idx,
      operationId,
    });
  };

  const handleEditClick = (parameterWithInfo: ParameterWithInfo) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-request-parameter",
        onInteractOutside: (e) => {
          e.preventDefault();
        },
        context: {
          operationId,
          parameterWithInfo,
        },
      },
    });
  };

  const handleReferenceClick = (refName: string) => {
    setActiveElement({ type: "component-parameter", label: refName });
  };

  const handleAddOrAttachToComponentClick = (parameterIdx: number) => {
    addOrAttatchExistingParameterAsComponent({ operationId, parameterIdx });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <DocumentationCardItem
      key={resolved.name + "-" + resolved.in}
      isSelected={
        activeItem?.type === "parameter" && activeItem.idx === parameter.idx
      }
      onClick={() => setActiveItem({ type: "parameter", idx: parameter.idx })}
      isLast={isLast}
    >
      <DocumentationCardItemHeader>
        <div className="flex gap-2 items-center">
          <ParameterPositioinBadge
            parameterPosition={resolved.in as ParameterPosition}
          />
          <TypeBadge schema={resolved.schema} isRequired={resolved.required} />
          {refName && (
            <Tooltip>
              <TooltipTrigger asChild>
                <LinkButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleReferenceClick(refName);
                  }}
                />
              </TooltipTrigger>
              <TooltipContent>Go to &lt;{refName}&gt;</TooltipContent>
            </Tooltip>
          )}
          <DocumentationCardItemTitle>
            {resolved.name}
          </DocumentationCardItemTitle>
        </div>
        {isEditor && (
          <BtnGroup>
            {!refName && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <ComponentButton
                    onClick={(evt) => {
                      evt.stopPropagation();
                      evt.preventDefault();
                      handleAddOrAttachToComponentClick(parameter.idx);
                    }}
                  >
                    <span className="sr-only">Turn into component</span>
                  </ComponentButton>
                </TooltipTrigger>
                <TooltipContent>
                  Attach to existing component with the same name or turn into
                  new component.
                </TooltipContent>
              </Tooltip>
            )}
            <EditButton
              onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();
                handleEditClick(parameter);
              }}
            >
              Edit {resolved.in} parameter
            </EditButton>
            <DeleteButton
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                handleRemoveParameter(parameter);
              }}
            >
              Delte parameter
            </DeleteButton>
          </BtnGroup>
        )}
      </DocumentationCardItemHeader>
      <DocumentationCardItemContent>
        <DocumentationCardDescription>
          <EditorItemDescription
            item={resolved}
            workspaceRole={workspaceRole}
            isRef={!!refName}
            onAddDescriptionClick={() => handleEditClick(parameter)}
          />
        </DocumentationCardDescription>
      </DocumentationCardItemContent>
    </DocumentationCardItem>
  );
}

export function RequestParameterCard({
  value,
  onChange,
  operationWithInfo,
  workspaceRole,
  setActiveItem,
  activeItem,
}: {
  operationWithInfo: OperationWithInfo;
  workspaceRole: WorkspaceRole;
  setActiveItem: SetActiveItem;
  activeItem: ActiveOperationItem | undefined;
} & EditorInputProps) {
  const operation = operationWithInfo.operation;
  const operationId = operation.operationId || "";

  const { getParametersByPosition: getParameters, resolveParameterObject } =
    useParameters({
      value,
      onChange,
    });
  const [, actionBarDispatch] = useActionBarContext();

  const handleAdd = (parameterPosition: ParameterPosition) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-request-parameter",
        onInteractOutside: (e) => {
          e.preventDefault();
        },
        context: {
          operationId,
          urlPath: operationWithInfo.urlPath,
          parameterPosition,
        },
      },
    });
  };

  const queryParameters = useMemo(
    () => getParameters(operationId, "query"),
    [getParameters, operationId]
  );

  const cookieParameters = useMemo(
    () => getParameters(operationId, "cookie"),
    [getParameters, operationId]
  );

  const headersParameters = useMemo(
    () => getParameters(operationId, "header"),
    [getParameters, operationId]
  );

  const handleComponentAddClick = (parameterPosition: ParameterPosition) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-component-parameter-to-operation",
        context: {
          urlPath: operationWithInfo.urlPath,
          operationId,
          parameterPosition,
        },
      },
    });
  };

  const parameterList = [
    ...queryParameters,
    ...cookieParameters,
    ...headersParameters,
  ];

  const isEditor = canEdit(workspaceRole);
  return (
    <HideEmptyListWhenNonEditor
      list={parameterList}
      workspaceRole={workspaceRole}
    >
      <DocumentationCard>
        <DocumentationCardHeader
          btnGroup={
            isEditor ? (
              <BtnGroup>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <AddLabelButton />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuGroup>
                      <DropdownMenuItem onClick={() => handleAdd("query")}>
                        Query parameter
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => handleAdd("header")}>
                        Request header
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => handleAdd("cookie")}>
                        Cookie
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button role="button" size="xs" variant="secondary">
                      <NormIcons.Component
                        className="mr-2"
                        size={DEFAULT_ICON_SIZE_SM}
                      />
                      Add component
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        onClick={() => handleComponentAddClick("query")}
                      >
                        Query parameter
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => handleComponentAddClick("header")}
                      >
                        Request header
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => handleComponentAddClick("cookie")}
                      >
                        Cookie
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </BtnGroup>
            ) : null
          }
          hoverLayout
        >
          <DocumentationCardTitle>Request parameters</DocumentationCardTitle>
        </DocumentationCardHeader>
        <DocumentationCardContent>
          {parameterList.map((parameter, i) => {
            const resolved = resolveParameterObject(parameter.parameter);
            return (
              <ParameterItem
                key={resolved.name}
                parameter={parameter}
                isLast={i === parameterList.length - 1}
                workspaceRole={workspaceRole}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                value={value}
                onChange={onChange}
                operationWithInfo={operationWithInfo}
              />
            );
          })}
          {isEditor && queryParameters.length === 0 && (
            <DocumentationCardButtonGroup hoverLauout>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <DocumentationCardMoreButton btnTitle="Add new" />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem onClick={() => handleAdd("query")}>
                      Query parameter
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleAdd("header")}>
                      Request header
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleAdd("cookie")}>
                      Cookie
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </DocumentationCardButtonGroup>
          )}
        </DocumentationCardContent>
      </DocumentationCard>
    </HideEmptyListWhenNonEditor>
  );
}
