import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/components/_shadui/alert";
import { companyInfo } from "@/lib/const";
import { Terminal } from "lucide-react";

export function EditorTreeFooter() {
  return (
    <div className="sticky">
      <Alert className="w-[90%] mx-auto mb-2">
        <Terminal className="h-4 w-4" />
        <AlertTitle>We need your help!</AlertTitle>
        <AlertDescription>
          Tell us what to work on next by{" "}
          <a
            className="underline text-brand"
            target="_blank"
            href={companyInfo.surveys.generalFeedback}
          >
            answering three questions.
          </a>
          .
        </AlertDescription>
      </Alert>
    </div>
  );
}
