import { WorkspaceRole } from "@/components/module-api-editor/types";
import { localStorageKeys } from "@/lib/const";
import { getApiBaseUrl } from "@/lib/http-utils";
import { bookStoreExample } from "@/lib/oas-examples/bookstore";
import { emptyWorkspaceExample } from "@/lib/oas-examples/empty-workspace";
import { faker } from "@faker-js/faker/locale/en";
import { DashboardIcon } from "@radix-ui/react-icons";
import { AxiosError } from "axios";
import { type ClassValue, clsx } from "clsx";
import { truncate } from "lodash";
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Clipboard,
  Component,
  Copy,
  CopyCheck,
  Download,
  DownloadIcon,
  FileJson,
  FlagOffIcon,
  Github,
  Image,
  Import,
  Info,
  InfoIcon,
  KeyRound,
  Link,
  Link2,
  Lock,
  LockIcon,
  LockOpen,
  LogIn,
  LogOut,
  Mail,
  MailCheck,
  MailIcon,
  Menu,
  Minus,
  Newspaper,
  PenLine,
  Plus,
  RouteOff,
  Save,
  Scan,
  ScanFace,
  Settings,
  Share,
  Sparkle,
  Tag,
  TrashIcon,
  TriangleAlert,
  Undo,
  Unlink2,
  User,
  WandSparklesIcon,
  X,
  Zap,
} from "lucide-react";
import { nanoid } from "nanoid";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";

const EDIT_ROLES: WorkspaceRole[] = ["admin", "editor"];
const ADMIN_ROLES: WorkspaceRole[] = ["admin"];

export function canEdit(workspaceRole: WorkspaceRole) {
  return EDIT_ROLES.includes(workspaceRole);
}

export function canAdmin(workspaceRole: WorkspaceRole) {
  return ADMIN_ROLES.includes(workspaceRole);
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function typedIncludes<T extends U, U>(
  coll: ReadonlyArray<T>,
  el: U
): el is T {
  return coll.includes(el as T);
}

export function safeGet<T extends object, K extends keyof T>(
  obj: T,
  key: string | number | symbol
): T[K] | undefined {
  return key in obj ? obj[key as K] : undefined;
}

export function generatePlaceholderId() {
  return `%%%${nanoid().substring(0, 4)}`;
}

export function toastError(err: unknown) {
  console.error(err);
  if (err instanceof AxiosError && "message" in (err.response?.data || {})) {
    toast.error(err.response?.data.message);
    return;
  }
  if (err instanceof Error) {
    toast.error(err.message);
    return;
  }
}

// Function to escape a string for use as a CSS identifier
export function escapeCssIdentifier(str: string) {
  return str.replace(/[^a-zA-Z0-9-_]/g, (match) => `\\${match}`);
}

export type PickRequired<T extends object, Keys extends keyof T> = T &
  Required<Pick<T, Keys>>;

export const DEFAULT_ICON_SIZE = 14;
export const DEFAULT_ICON_SIZE_SM = 10;

export const NormIcons = {
  Auth: KeyRound,
  Tag: Tag,
  Url: Link2,
  Component: Component,
  Expand: ChevronDown,
  Download: DownloadIcon,
  Clone: CopyCheck,
  Copy: Copy,
  Optional: FlagOffIcon,
  Required: LockIcon,
  Dereference: Unlink2,
  Delete: X,
  Check: Scan,
  Add: Plus,
  Remove: Minus,
  Minimize: Minus,
  Invite: MailIcon,
  Import: Import,
  Active: Zap,
  Lock: Lock,
  LockOpen: LockOpen,
  Clipboard: Clipboard,
  TrashCan: TrashIcon,
  JSON: FileJson,
  Edit: PenLine,
  Menu: Menu,
  Save: Save,
  Feedback: User,
  Link: Link,
  Email: Mail,
  Reset: RouteOff,
  Undo: Undo,
  Export: Download,
  Error: TriangleAlert,
  Magic: WandSparklesIcon,
  Image: Image,
  About: Info,
  Done: Check,
  Recommended: Sparkle,
  QuickEdit: PenLine,
  Github: Github,
  Back: ArrowLeft,
  Share: Share,
  Dashboard: DashboardIcon,
  Login: LogIn,
  Logout: LogOut,
  Signup: ScanFace,
  Info: InfoIcon,
  Blog: Newspaper,
  Subscribe: MailCheck,
  Settings: Settings,
};

export type OASExamples = keyof typeof oasExampleMap;

export const oasExampleMap = {
  bookStore: bookStoreExample,
  empty: emptyWorkspaceExample,
};

export async function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function truncateText(str: string, length: number) {
  return truncate(str, { length, omission: "..." });
}

export const slugify = (text: string) => {
  return text
    .toString() // Cast to string (optional)
    .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/_/g, "-") // Replace _ with -
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/-$/g, ""); // Remove trailing -
};

export function generateRandumSlug() {
  return faker.lorem.slug();
}

export const getIsAuthed = () => {
  const tokens = localStorage.getItem(localStorageKeys.authTokens);
  return Boolean(tokens);
};

export async function setClipboard(text: string) {
  const type = "text/plain";
  const blob = new Blob([text], { type });
  const data = [new ClipboardItem({ [type]: blob })];
  await navigator.clipboard.write(data);
  return;
}

export function getUserCallName(username: string) {
  const first = username.split(" ")[0];
  if (first.length <= 10) return first;
  return "Friend";
}

export function isNanoid(id: string): boolean {
  // Default nanoid uses 21 characters
  if (id.length !== 21) return false;

  // nanoid uses A-Za-z0-9_- (URL-safe characters)
  const validChars = /^[A-Za-z0-9_-]+$/;
  return validChars.test(id);
}

export function generateWorkspaceName(): string {
  return `${faker.word.adjective()}-${faker.word.noun()}`;
}

export function buildWorkspaceUrl({
  workspaceSlug,
  organizationSlug,
}: {
  workspaceSlug: string;
  organizationSlug: string;
}) {
  const appUrl = getApiBaseUrl();

  return `${appUrl}/${organizationSlug}/${workspaceSlug}`;
}

export function buildLiveOpenApiFileUrl({
  workspaceSlug,
  organizationSlug,
  draftId,
}: {
  workspaceSlug: string;
  organizationSlug: string;
  draftId?: string;
}) {
  const apiRoute = getApiBaseUrl();
  const url = new URL(
    `${apiRoute}/v1/public/resources/oas_api_3_1/${organizationSlug}/${workspaceSlug}`
  );
  if (draftId) {
    url.search = new URLSearchParams({ draft_id: draftId }).toString();
  }
  return url.toString();
}

export function getWorkspaceUrl({
  workspaceSlug,
  organizationSlug,
}: {
  workspaceSlug: string;
  organizationSlug: string;
}) {
  const url = new URL(window.location.href);
  return `${url.protocol}//${url.host}/editor/${organizationSlug}/${workspaceSlug}`;
}
