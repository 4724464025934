import { Button } from "@/components/_shadui/button";
import { EditorState } from "@/components/module-api-editor/types";
import { WorkspaceDocumentEditor } from "@/components/module-api-editor/workspace-document-editor";
import { SelectDraftModal } from "@/components/select-draft-modal";
import { WorkspaceAlert } from "@/components/workspace-alert";
import { useDisclosure } from "@/hooks/use-disclosure";
import { apiClient } from "@/lib/http-utils";
import { validateEditorStateBeforeUse } from "@/lib/oas-tools/oas-schema-utils";
import { canEdit, getIsAuthed } from "@/lib/utils";
import { useMe } from "@/queries/users";
import {
  useUpdateWorkspaceDocument,
  useUserWorkspace,
} from "@/queries/workspaces";
import { createFileRoute } from "@tanstack/react-router";
import { useCallback, useMemo, useState } from "react";

export const Route = createFileRoute(
  "/editor/$organizationSlug/$workspaceSlug"
)({
  loader: ({ params: { workspaceSlug, organizationSlug } }) => {
    return apiClient.findFullWorkspace({ organizationSlug, workspaceSlug });
  },
  component: WorkspaceEditor,
});

// Editing a document that is persisted in the database
// Editing this object will update the database record
export function WorkspaceEditor() {
  const { organizationSlug, workspaceSlug } = Route.useParams();
  const publicWorkspace = Route.useLoaderData().data;
  const patchWorkspaceDocumentMutation = useUpdateWorkspaceDocument();
  const draftDisclosure = useDisclosure();

  const isAuthed = useMemo(() => getIsAuthed(), []);

  const userQuery = useMe({ enabled: isAuthed });

  const userWorkspace = useUserWorkspace(
    { workspaceSlugOrId: workspaceSlug },
    { enabled: isAuthed }
  );

  const workspaceItem = publicWorkspace.workspace_items[0];

  const workspaceDocument = workspaceItem.workspace_documents[0];

  const [value, setValue] = useState<EditorState>(() => {
    const editorState = validateEditorStateBeforeUse({
      document_type: workspaceDocument.document_type,
      data: workspaceDocument.data,
    });
    return editorState;
  });

  const role = useMemo(() => {
    const role = userWorkspace.data?.role;
    if (role === "admin") return role;
    if (publicWorkspace.access_level === "public_write") return "editor";
    if (userWorkspace.data?.role) return userWorkspace.data.role;
    return "reader";
  }, [publicWorkspace.access_level, userWorkspace.data?.role]);

  const onChange = useCallback(
    (editorState: EditorState) => {
      setValue(editorState);
      if (!canEdit(role)) return;
      patchWorkspaceDocumentMutation.mutate({
        workspaceDocumentId: workspaceDocument.id,
        data: editorState.data,
        workspace_id: workspaceDocument.workspace_id,
      });
    },
    [
      workspaceDocument.id,
      workspaceDocument.workspace_id,
      patchWorkspaceDocumentMutation,
      role,
    ]
  );

  const isOrgMember = useMemo(() => {
    if (
      userQuery.data &&
      publicWorkspace.organization_id === userQuery.data.organization_id
    )
      return true;
    return false;
  }, [userQuery.data, publicWorkspace.organization_id]);

  const handleSelect = () => {
    draftDisclosure.onOpen();
  };

  return (
    <>
      <WorkspaceDocumentEditor
        value={value}
        onChange={onChange}
        organizationSlug={organizationSlug}
        workspaceSlug={workspaceSlug}
        isAuthed={isAuthed}
        workspaceRole={
          workspaceItem.is_connected_to_remote_repo ? "reader" : role
        }
        workspaceAlert={
          workspaceItem.is_connected_to_remote_repo === true &&
          isAuthed &&
          canEdit(role) && (
            <WorkspaceAlert variant="info">
              <div className="flex justify-between items-center">
                <div>
                  <span className="inline-block pr-2">🔑</span>
                  <span className="font-bold">
                    This workspace is connected to a repository. You can make
                    changes to it by creating a draft.
                  </span>
                </div>
                {canEdit(role) && (
                  <Button size="xs" onClick={handleSelect}>
                    Select or create draft
                  </Button>
                )}
              </div>
            </WorkspaceAlert>
          )
        }
      />
      {workspaceItem.is_connected_to_remote_repo && isAuthed && (
        <SelectDraftModal
          isOpen={draftDisclosure.isOpen}
          onOpenChange={draftDisclosure.onOpenChange}
          workspaceDocument={workspaceDocument}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          isOrgMember={isOrgMember}
        />
      )}
    </>
  );
}
