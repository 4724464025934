export const URL_PATH_REGEX =
  /^(?=.+)(\/([a-z0-9_.-]+|(\{[a-zA-Z0-9_.-]+\})))*\/?$/;
export const PROPERTY_NAME_REGEX = /^[a-zA-Z0-9_-]+$/;

export const appRegex = {
  parameterName: {
    expression: /^[a-zA-Z0-9_\-$()]+$/,
    message: "Parameter names cannot contain special characters or spaces",
  },
  propertyName: {
    expression: /^[a-zA-Z0-9_-]+$/,
    message: "Property names cannot contain special characters or spaces",
  },
};
