import { Avatar, AvatarFallback } from "@/components/_shadui/avatar";
import { Button } from "@/components/_shadui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/_shadui/card";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/_shadui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/_shadui/select";
import { Skeleton } from "@/components/_shadui/skeleton";
import { WorkspaceRole } from "@/components/module-api-editor/types";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { CreateInvitationForm } from "@/forms/form-invitation";
import { useDisclosure } from "@/hooks/use-disclosure";
import {
  UserDto,
  UserWorkspaceDto,
  WorkspaceInvitationResultDto,
} from "@/lib/main-rest-client/definitions";
import { DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import {
  usePatchWorkspaceMember,
  useRemoveWorkspaceMember,
  useWorkspaceMembers,
} from "@/queries/workspaces";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/_shadui/alert-dialog";
import { useState } from "react";
import WorkspaceInvitationLinkModal from "@/components/workspace-invitation-link-modal";

export function WorkspaceSettingsMemberList({
  userWorkspaceDto,
  meDto,
}: {
  userWorkspaceDto: UserWorkspaceDto;
  meDto: UserDto;
}) {
  const patchWorkspaceMemberQuery = usePatchWorkspaceMember();
  const removeWorkspaceMemberQuery = useRemoveWorkspaceMember();
  const invitePopoverDisclosure = useDisclosure();

  const [invitationState, setInvitationState] = useState<
    WorkspaceInvitationResultDto | undefined
  >(undefined);

  const workspaceMembersQuery = useWorkspaceMembers({
    workspaceId: userWorkspaceDto.id,
  });

  if (userWorkspaceDto.role !== "admin") return null;

  if (workspaceMembersQuery.isFetching) {
    return <Skeleton className="h-20" />;
  }

  if (workspaceMembersQuery.isError) {
    return <div>Error</div>;
  }

  const handleSelectChange = (value: string, userId: string) => {
    const newRole = value as WorkspaceRole;
    patchWorkspaceMemberQuery.mutate({
      role: newRole,
      userId,
      workspaceId: userWorkspaceDto.id,
    });
  };

  const handleRemoveWorkspaceMember = (userId: string) => {
    removeWorkspaceMemberQuery.mutate({
      workspaceId: userWorkspaceDto.id,
      userId,
    });
  };

  if (!workspaceMembersQuery.isSuccess) return null;

  return (
    <Card className="">
      <CardHeader className="">
        <div className="flex justify-between">
          <div>
            <CardTitle>Workspace members</CardTitle>
            <CardDescription>
              Change permissions for workspace members or invite new ones.
            </CardDescription>
          </div>
          <Popover
            open={invitePopoverDisclosure.isOpen}
            onOpenChange={invitePopoverDisclosure.onOpenChange}
          >
            <PopoverTrigger asChild>
              <Button variant="outline">
                <NormIcons.Invite size={DEFAULT_ICON_SIZE} className="mr-2" />
                Invite new member
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80">
              <CreateInvitationForm
                workspaceId={userWorkspaceDto.id}
                onSuccess={(data) => {
                  setInvitationState(data);
                  invitePopoverDisclosure.onClose();
                }}
              />
            </PopoverContent>
          </Popover>
          {invitationState && (
            <WorkspaceInvitationLinkModal
              inviteLink={invitationState.link}
              inviteeEmail={invitationState.invitee_email}
              isOpen={!!invitationState}
              onClose={() => setInvitationState(undefined)}
            />
          )}
        </div>
      </CardHeader>
      <CardContent className="flex flex-col items-stretch gap-3 pt-1">
        {workspaceMembersQuery.data.map((member) => (
          <div className="flex items-center justify-between" key={member.id}>
            <div className="flex items-center gap-3">
              <Avatar className="border-2 border-brand">
                <AvatarFallback>
                  {member.username.substring(0, 1)}
                </AvatarFallback>
              </Avatar>
              {member.username}
            </div>
            <BtnGroup>
              <Select
                defaultValue={member.role}
                onValueChange={(value) => handleSelectChange(value, member.id)}
                disabled={meDto.id === member.id}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Theme" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="admin">Admin</SelectItem>
                  <SelectItem value="editor">Editor</SelectItem>
                  <SelectItem value="reader">Reader</SelectItem>
                </SelectContent>
              </Select>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    variant="destructive"
                    disabled={meDto.id === member.id}
                  >
                    Remove
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Remove member?</AlertDialogTitle>
                    <AlertDialogDescription>
                      This action cannot be undone. This will permanently remove
                      the member
                      <b>{member.username}</b>
                      from the workspace <b>{userWorkspaceDto.name}</b>.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => handleRemoveWorkspaceMember(member.id)}
                    >
                      Remove
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </BtnGroup>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}
