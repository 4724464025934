import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps } from "react";
import { Badge } from "./badge";
import { cn } from "@/lib/utils";

export type ColorBadeColors = NonNullable<
  VariantProps<typeof colorBadge>["color"]
>;

const colorBadge = cva(
  "inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors border-transparent focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 shadow hover:[&[role='button']]:opacity-80",
  {
    variants: {
      color: {
        blue: "bg-blue-700/40 text-blue-500",
        cyan: "bg-cyan-700/40 text-cyan-500",
        yellow: "bg-yellow-700/40 text-yellow-500",
        orange: "bg-orange-700/40 text-orange-500",
        red: "bg-red-700/40 text-red-500",
        green: "bg-green-700/40 text-green-500",
        purple: "bg-purple-700/40 text-purple-500",
        gray: "bg-gray-700/40 text-gray-300",
        amber: "bg-amber-700/40 text-amber-500",
        lime: "bg-lime-700/40 text-lime-500",
        emerald: "bg-emerald-700/40 text-emerald-500",
        violet: "bg-violet-700/40 text-violet-500",
        rose: "bg-rose-700/40 text-rose-500",
        pink: "bg-pink-700/40 text-pink-500",
        teal: "bg-teal-700/40 text-teal-500",
        sky: "bg-sky-700/40 text-sky-500",
        indigo: "bg-indigo-700/40 text-indigo-500",
        fuchsia: "bg-fuchsia-700/40 text-fuchsia-500",
      },
      state: {
        active: "ring-2",
        inactive: "ring-0",
      },
    },
    defaultVariants: {
      state: "inactive",
      color: "blue",
    },
  }
);

export function ColorBadge({
  className,
  color,
  onClick,
  ...rest
}: ComponentProps<typeof Badge> & VariantProps<typeof colorBadge>) {
  return (
    <div
      onClick={onClick}
      className={cn(
        { "pointer-events-none": !onClick },
        colorBadge({ color: color }),
        className
      )}
      {...rest}
    />
  );
}
