import { WorkspaceRole } from "@/components/module-api-editor/types";
import { ParameterWithInfo } from "@/hooks/use-parameters";
import {
  OASParameterObject,
  OASRequestBodyObject,
  OASResponse,
  OASSchema,
  OASSecurityScheme,
  OASTag,
  ParameterPosition,
  SupportedHTTPVerbs,
} from "@/lib/types";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
} from "react";

type BasePages =
  | { name: "add-operation" }
  | {
      name: "suggested-responses";
      context: {
        urlPath: string;
        method: SupportedHTTPVerbs;
        operationId: string;
      };
    }
  | {
      name: "edit-operation";
      context: {
        urlPath: string;
        method: SupportedHTTPVerbs;
        operationId: string;
      };
    }
  | {
      name: "edit-title";
    }
  | {
      name: "edit-tag";
      context: {
        tagObject: OASTag;
      };
    }
  | {
      name: "edit-description";
    }
  | {
      name: "response-add";
      context: {
        operationId: string;
        method: SupportedHTTPVerbs;
      };
    }
  | {
      name: "response-edit";
      context: {
        operationId: string;
        response: OASResponse;
        method: SupportedHTTPVerbs;
        responseCode: string;
      };
    }
  | {
      name: "add-request-parameter";
      context: {
        operationId: string;
        urlPath: string;
        parameterPosition: ParameterPosition;
      };
    }
  | {
      name: "add-component-parameter-to-operation";
      context: {
        operationId: string;
        urlPath: string;
        parameterPosition: ParameterPosition;
      };
    }
  | {
      name: "edit-request-parameter";
      context: {
        operationId: string;
        parameterWithInfo: ParameterWithInfo;
      };
    }
  | {
      name: "edit-path-parameter";
      context: {
        operationId: string;
        parameterWithInfo: ParameterWithInfo;
      };
    }
  | {
      name: "request-body-edit";
      context: {
        operationId: string;
        requestBody: OASRequestBodyObject;
      };
    }
  | {
      name: "request-body-add";
      context: {
        operationId: string;
      };
    }
  | {
      name: "dto-add";
      context?: {
        baseSchemaName?: string;
      };
    }
  | {
      name: "dto-edit";
      context: {
        dtoName: string;
      };
    }
  | {
      name: "select-example";
    }
  | {
      name: "workspace-actions";
    }
  | {
      name: "workspace-settings";
      context: {
        organizationSlug: string | undefined;
        isAuthed: boolean;
        handleSubscribeToNewsClick: () => void;
        handleGiveFeedbackClick: () => void;
        handleShareWorkspaceClick: () => void;
        handleAboutClick: () => void;
        workspaceRole: WorkspaceRole;
      };
    }
  | {
      name: "add-component-schema";
    }
  | {
      name: "edit-component-schema";
      context: {
        schemaName: string;
        componentSchemaObject: OASSchema;
      };
    }
  | {
      name: "add-component-response";
    }
  | {
      name: "edit-component-response";
      context: {
        responseObject: OASResponse;
        responseName: string;
      };
    }
  | {
      name: "add-component-parameter";
      context: {
        parameterPosition: ParameterPosition;
      };
    }
  | {
      name: "edit-component-parameter";
      context: {
        parameter: OASParameterObject;
        label: string;
      };
    }
  | {
      name: "add-component-suggest-dtos";
      context: {
        schemaName: string;
      };
    }
  | {
      name: "edit-oas-metadata";
    }
  | {
      name: "add-security-scheme";
    }
  | {
      name: "edit-security-scheme";
      context: {
        schemeName: string;
        securityScheme: OASSecurityScheme;
      };
    }
  | {
      name: "import-open-api-file";
    }
  | {
      name: "debug";
    };

type Page = (BasePages & SharedPageContext) | undefined;

type SharedPageContext = {
  onInteractOutside?: (e: Event) => unknown;
};

type Actions =
  | {
      type: "SET_PAGE";
      payload: Page;
    }
  | {
      type: "CLOSE";
    };

type State = {
  page: Page;
};

const _initialState: State = {
  page: undefined,
};

function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case "SET_PAGE":
      return { ...state, page: action.payload };
    case "CLOSE":
      return { ...state, page: undefined };
    default:
      throw new Error(`Unknown action of type ${(action as Actions).type}`);
  }
}

const ActionBarContext = createContext<
  readonly [State, Dispatch<Actions>] | undefined
>(undefined);

export function ActionBarProvider({
  children,
  initialState = _initialState,
}: PropsWithChildren<{ initialState?: State }>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [state, dispatch] as const, [state]);

  return (
    <ActionBarContext.Provider value={value}>
      {children}
    </ActionBarContext.Provider>
  );
}

export function useActionBarContext() {
  const value = useContext(ActionBarContext);

  if (!value)
    throw new Error(
      "useActionBarContext was called outside of ActionBarProvider provider"
    );

  return value;
}
